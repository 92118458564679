import { Box, Fade, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import type { ReactNode } from "react";
import { useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper } from "swiper/react";

const VISIBLE_ITEMS = 3;

export function SearchCarousel({
  title,
  totalItems,
  children,
}: {
  title: string;
  totalItems: number;
  children: ReactNode;
}) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  return (
    <Stack spacing={2}>
      <Typography
        component="span"
        variant="b4"
        sx={{
          fontWeight: 600,
          color: "neutral.5",
          letterSpacing: "1.2px",
          lineHeight: 2.5,
          textTransform: "uppercase",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Swiper
          slidesPerView="auto"
          spaceBetween={8}
          modules={[Navigation]}
          navigation={{ nextEl: `.arrow-right-${title}`, prevEl: `.arrow-left-${title}` }}
          style={{ marginLeft: 0 }}
          onSlideChange={(swiper) => {
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
        >
          {children}
        </Swiper>
        <Fade in={!isMobileScreen && !isBeginning}>
          <IconButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            className={`arrow-left-${title} arrow`}
            sx={{
              position: "absolute",
              backgroundColor: "neutral.0",
              fill: "neutral.0",
              strokeWidth: 1,
              stroke: "neutral.2",
              filter:
                "drop-shadow(0px 4px 24px rgba(23, 35, 64, 0.15)) drop-shadow(0px 0px 10px rgba(25, 42, 83, 0.30))",
              left: 0,
              top: 30,
              zIndex: 1,
            }}
          >
            <IconChevronLeft size={24} />
          </IconButton>
        </Fade>
        <Fade in={!isMobileScreen && !isEnd && totalItems > VISIBLE_ITEMS}>
          <IconButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            className={`arrow-right-${title} arrow`}
            sx={{
              position: "absolute",
              backgroundColor: "neutral.0",
              fill: "neutral.0",
              strokeWidth: 1,
              stroke: "neutral.2",
              filter:
                "drop-shadow(0px 4px 24px rgba(23, 35, 64, 0.15)) drop-shadow(0px 0px 10px rgba(25, 42, 83, 0.30))",
              right: 0,
              top: 30,
              zIndex: 1,
            }}
          >
            <IconChevronRight />
          </IconButton>
        </Fade>
      </Box>
    </Stack>
  );
}
