import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function ManyvidsIcon(props: SvgIconProps) {
  return (
    <SvgIcon version="1.1" x="0px" y="0px" viewBox="0 0 133 172.6" {...props}>
      <path
        fill="currentColor"
        d="M66.7,80.7c0.2-0.4,0.4-0.7,0.5-1c2.6-5.4,6-10.2,10.8-13.9c5.9-4.5,12.6-6.7,20.1-6.3c8.3,0.4,15.3,3.6,21.2,9.3
    c6.6,6.4,10.3,14.3,11.6,23.4c1.5,10.7-1.1,20.4-6.7,29.5c-5.9,9.7-13.4,17.9-21.7,25.4c-10.5,9.6-22,17.8-34.2,25.1
    c-1.2,0.7-2.4,0.7-3.6,0c-10.4-6.4-20.5-13.2-29.8-21.2c-9-7.8-17.5-16.1-24.1-26c-4-5.9-7-12.3-8.4-19.3
    c-1.5-7.6-0.7-15,1.9-22.2c2.3-6.4,6-12,11.4-16.3c5-4,10.7-6.8,17.1-7.5c10.6-1.2,19.4,2.5,26.6,10.3c2.6,2.8,4.5,6.1,6.3,9.5
    c0.2,0.4,0.4,0.8,0.6,1.1C66.4,80.5,66.5,80.5,66.7,80.7z M66.7,137.8L66.7,137.8c3.2,0,6.4,0,9.6,0c2.2,0,2.2,0,1.6-2.1
    c-1.7-6.4-3.4-12.8-5.1-19.2c-0.5-1.9-0.2-3.5,1.2-4.9c1.9-1.8,2.6-4.1,2.7-6.7c0.1-5.7-5.7-11.3-12.1-9.9
    c-6.9,1.5-9.2,8.4-7.9,12.7c0.6,1.9,1.7,3.5,3.2,4.8c1,0.9,1.4,1.9,0.9,3.2c-0.3,0.8-0.5,1.6-0.7,2.4c-1.6,6.1-3.2,12.2-4.7,18.3
    c-0.3,1.4-0.2,1.5,1.2,1.5C60,137.8,63.4,137.8,66.7,137.8z"
      />
      <path
        fill="currentColor"
        d="M66.5,46.7c-7.1,0-14.3,0-21.4,0c-2.4,0-3.4-0.8-4-3.2c-1.7-6.8-3.5-13.6-5.2-20.4c-0.4-1.5-1.2-2.5-2.7-3.2
    c-3.2-1.5-4.4-4.6-3.8-8c0.6-3.2,3.5-5.4,6.8-5.4c4.4,0,7.5,3.1,7.3,7.4c-0.1,1.8-1,3.3-2.1,4.8c-0.4,0.5-0.3,0.8,0.1,1.3
    c2.7,2.5,5.3,5.1,8.1,7.5c2.8,2.4,4.1,2.2,6-1c2.2-3.8,4.2-7.7,6.2-11.6c0.6-1.1,0-2-0.6-2.8c-1.3-1.6-1.9-3.4-1.9-5.5
    C59.6,3,62.9-0.1,66.8,0c3.6,0.1,6.8,3.3,6.9,6.8c0,2.1-0.6,3.9-2.1,5.4c-1,1-1.1,1.8-0.4,3.1c2.1,3.8,4.1,7.6,6.3,11.3
    c1.7,3,2.9,3.4,5.6,1.2c2.6-2.1,4.9-4.5,7.3-6.9c1.5-1.5,1.4-1.5,0.4-3.4c-0.9-1.7-1.5-3.5-1.1-5.4c0.6-3.2,3.3-5.4,6.6-5.6
    c3.6-0.2,6.5,2.3,7.2,5.3c0.8,3.4-0.6,6.5-3.8,8c-1.6,0.8-2.4,2-2.8,3.6c-1.7,6.8-3.4,13.6-5.2,20.3c-0.5,2.1-1.5,2.9-3.7,2.9
    C81,46.7,73.8,46.7,66.5,46.7z"
      />
    </SvgIcon>
  );
}
