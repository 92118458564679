import { SvgIcon, type SvgIconProps } from "@mui/material";
import { useId } from "react";

export function InfoSquareTwoTonesIcon(props: SvgIconProps) {
  const clipPathId = useId();

  return (
    <SvgIcon
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 7.96667C0.5 5.35309 0.5 4.0463 1.00864 3.04804C1.45605 2.16995 2.16995 1.45605 3.04804 1.00864C4.0463 0.5 5.35309 0.5 7.96667 0.5H14.0333C16.6469 0.5 17.9537 0.5 18.952 1.00864C19.83 1.45605 20.544 2.16995 20.9914 3.04804C21.5 4.0463 21.5 5.35309 21.5 7.96667V14.0333C21.5 16.6469 21.5 17.9537 20.9914 18.952C20.544 19.83 19.83 20.544 18.952 20.9914C17.9537 21.5 16.6469 21.5 14.0333 21.5H7.96667C5.35309 21.5 4.0463 21.5 3.04804 20.9914C2.16995 20.544 1.45605 19.83 1.00864 18.952C0.5 17.9537 0.5 16.6469 0.5 14.0333V7.96667ZM11 9.83333C11.6443 9.83333 12.1667 10.3557 12.1667 11V15.6667C12.1667 16.311 11.6443 16.8333 11 16.8333C10.3557 16.8333 9.83333 16.311 9.83333 15.6667V11C9.83333 10.3557 10.3557 9.83333 11 9.83333ZM11 5.16667C10.3557 5.16667 9.83333 5.689 9.83333 6.33333C9.83333 6.97767 10.3557 7.5 11 7.5C11.6443 7.5 12.1667 6.97767 12.1667 6.33333C12.1667 5.689 11.6443 5.16667 11 5.16667Z"
        fill={`url(#${clipPathId})`}
      />
      <defs>
        <radialGradient
          id={clipPathId}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11 22) rotate(-93.3665) scale(25.5441 51.2593)"
        >
          <stop offset="0.177083" stopColor="#005CE6" />
          <stop offset="1" stopColor="#00C8F3" />
        </radialGradient>
      </defs>
    </SvgIcon>
  );
}
