import ArrowRight from "@mui/icons-material/ArrowRight";
import { LoadingButton } from "@mui/lab";
import { ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { useState } from "react";

import { Dialog } from "~/components/dialog";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

export function ResetGifterPasswordItem() {
  const authData = useAuth();

  const [shouldOpenResetPasswordDialog, setShouldOpenResetPasswordDialog] = useState(false);

  const [submit, submitState] = useWtFetcher({
    onError: (response) => alert(response.error),
    onSuccess: () => setShouldOpenResetPasswordDialog(false),
  });

  return (
    <>
      <MenuItem sx={{ py: 1 }} onClick={() => setShouldOpenResetPasswordDialog(true)}>
        <ListItemText>Password</ListItemText>
        <ListItemIcon>
          <ArrowRight />
        </ListItemIcon>
      </MenuItem>
      <Dialog.Root
        maxWidth="xs"
        fullWidth
        variant="alert"
        open={shouldOpenResetPasswordDialog}
        onClose={() => setShouldOpenResetPasswordDialog(false)}
      >
        <Dialog.Title sx={{ textAlign: "center" }}>Reset Password</Dialog.Title>
        <Dialog.Content>
          <Stack
            spacing={5}
            component={submit.Form}
            method="post"
            action={Routes.actions.user.resetPassword()}
          >
            <Dialog.ContentText sx={{ textAlign: "center" }}>
              {`Click send to have password reset instructions sent to ${authData.user?.email}`}
            </Dialog.ContentText>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={submitState.isFetching}
              disabled={submitState.isFetching}
            >
              <span>Send</span>
            </LoadingButton>
          </Stack>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
