import { useAuth } from "~/hooks/use-auth";

import { DesktopGeneralHeader } from "./general-header.desktop";
import { MobileGeneralHeader } from "./general-header.mobile";
import { TabletGeneralHeader } from "./general-header.tablet";

export function GeneralHeader() {
  const auth = useAuth();

  return (
    <header>
      <MobileGeneralHeader auth={auth} />
      <TabletGeneralHeader auth={auth} />
      <DesktopGeneralHeader auth={auth} />
    </header>
  );
}
