import ArrowRight from "@mui/icons-material/ArrowRight";
import PlayArrow from "@mui/icons-material/PlayArrow";
import Science from "@mui/icons-material/Science";
import { LoadingButton } from "@mui/lab";
import { Box, Link, ListItemIcon, ListItemText, MenuItem, Stack, Typography } from "@mui/material";
import { useRouteLoaderData } from "@remix-run/react";
import { useState } from "react";

import { Dialog } from "~/components/dialog";
import { RouteIds, Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import type { RequestSuccessResult } from "~/utils/request-helpers.server";

import { TestStreamOverlay } from "./stream-management/test-stream-overlay";

const TUTORIAL_URL = "https://www.youtube.com/watch?v=wCpQsQ11JT4";

export function StreamManagementMenuItem() {
  const data = useRouteLoaderData(RouteIds.wisher.settings()) as { hasStreamUrl: boolean };

  const [shouldOpenStreamDialog, setShouldOpenStreamDialog] = useState(false);
  const [shouldOpenTestOverlay, setShouldOpenTestOverlay] = useState(false);

  const [submit, submitState] = useWtFetcher<RequestSuccessResult<{ url: string }>>({
    onError: (response) => alert(response.error),
  });

  return (
    <>
      <MenuItem sx={{ py: 1 }} onClick={() => setShouldOpenStreamDialog(true)}>
        <ListItemText sx={{ whiteSpace: "normal" }}>
          <Typography component="span" variant="b5">
            Wishtender Stream Overlay{" "}
            {data.hasStreamUrl && (
              <Link
                component="button"
                type="button"
                onClick={(event) => {
                  event.stopPropagation();
                  setShouldOpenTestOverlay(true);
                }}
              >
                <Stack direction="row" gap={1}>
                  <Typography component="span">Test Panel</Typography>
                  <Science />
                </Stack>
              </Link>
            )}
          </Typography>
        </ListItemText>
        <ListItemIcon>
          <ArrowRight />
        </ListItemIcon>
      </MenuItem>
      <Dialog.Root
        fullWidth
        variant="normal"
        open={shouldOpenStreamDialog}
        onClose={() => setShouldOpenStreamDialog(false)}
      >
        <Dialog.Title sx={{ textAlign: "center" }}>Wishtender Stream Overlay</Dialog.Title>
        <Dialog.Content>
          <Stack
            component={submit.Form}
            spacing={3}
            method="post"
            action={Routes.actions.settings.generateStreamUrl()}
          >
            <Link
              href={TUTORIAL_URL}
              target="_blank"
              rel="noreferer noopener"
              underline="none"
              sx={{
                typography: "h7",
                display: "inline-flex",
                alignItems: "center",
                color: "blue.4",
              }}
            >
              Watch the tutorial
              <PlayArrow />
            </Link>

            <Stack spacing={2}>
              <Typography component="span" variant="h7" fontWeight={500}>
                {data.hasStreamUrl ? (
                  <span>You previously generated a stream URL.</span>
                ) : (
                  <span>Create your streaming overlay url.</span>
                )}
              </Typography>

              <Typography component="p" variant="b4">
                {data.hasStreamUrl ? (
                  <span>
                    {" "}
                    If you generate a new stream URL, your old link will not work anymore.
                  </span>
                ) : (
                  <span>
                    Click the button below to create a browser overlay to stream your WishTender
                    gifts.
                  </span>
                )}
              </Typography>

              {!data.hasStreamUrl && (
                <Box
                  component="img"
                  src="/images/test_stream_overlay.png"
                  alt="Test stream overlay"
                  width="100%"
                  height="100%"
                />
              )}

              <LoadingButton
                type="submit"
                variant="contained"
                loading={submitState.isFetching}
                sx={{ alignSelf: "center" }}
              >
                {data.hasStreamUrl ? (
                  <span>Generate New Url</span>
                ) : (
                  <span>Generate Stream Url</span>
                )}
              </LoadingButton>
            </Stack>

            {(submitState.isSuccess || (data.hasStreamUrl && !submitState.isFetching)) && (
              <TestStreamOverlay streamUrl={submit.data?.data.url} />
            )}
          </Stack>
        </Dialog.Content>
      </Dialog.Root>
      <Dialog.Root
        fullWidth
        variant="normal"
        open={shouldOpenTestOverlay}
        onClose={() => setShouldOpenTestOverlay(false)}
      >
        <Dialog.Content sx={{ bgcolor: "blue.4" }}>
          <TestStreamOverlay />
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
