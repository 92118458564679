import { Avatar, Box, Link, Stack, Typography } from "@mui/material";
import { Link as RLink } from "@remix-run/react";

import { GiftTwoTonesIcon } from "~/components/icons";
import { MoneyIcon } from "~/components/icons/money-icon";
import { Routes } from "~/constants/routes";
import { useFormatCurrency } from "~/modules/currency";
import { CartRequestStatus, type Currency } from "~/types";
import { formatTime } from "~/utils/time";

import { useNotifications } from "../../context/notification-context";
import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.ORDER_PAID>;
}) {
  const { toggleNotificationHub } = useNotifications();
  const formatCurrency = useFormatCurrency(
    notification.data.request?.tipAmount.currency as Currency,
  );
  const urlSearchParams = new URLSearchParams({
    status: CartRequestStatus.PAID,
  });

  return (
    <NotificationContentContainer
      to={
        notification.data.request
          ? `${Routes.wisher.cartRequestList()}?${urlSearchParams.toString()}`
          : Routes.wisher.orderList()
      }
      notification={notification}
    >
      <Box sx={{ width: 40, height: 40, position: "relative" }}>
        <Avatar
          sx={{
            width: 40,
            height: 40,
          }}
          alt={notification.data.gifter.handle || notification.data.gifter.pseudonym || "Anonymous"}
          src={notification.data.gifter.avatarUrl}
        />
        <Avatar
          sx={{
            position: "absolute",
            width: 24,
            height: 24,
            bgcolor: "neutral.0",
            boxShadow:
              "0px 1px 1px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05)",
            bottom: -10,
            right: 0,
          }}
        >
          {notification.data.request ? (
            <MoneyIcon sx={{ fontSize: 16, color: "teal.4" }} />
          ) : (
            <GiftTwoTonesIcon sx={{ fontSize: 20, color: "teal.4" }} />
          )}
        </Avatar>
      </Box>

      <Stack spacing={0.5}>
        {notification.data.request ? (
          <Typography>
            {notification.data.gifter.handle ? (
              <Link
                component={RLink}
                to={Routes.public.wisher({ username: notification.data.gifter.handle! })}
                underline="none"
                sx={{ zIndex: 1, color: "neutral.7" }}
                onClick={() => toggleNotificationHub(false)}
              >
                <Typography
                  component="span"
                  className="notranslate"
                  variant="h5"
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  @{notification.data.gifter.handle}
                </Typography>
              </Link>
            ) : (
              <Typography component="span" variant="h5" className="notranslate">
                {notification.data.gifter.pseudonym}
              </Typography>
            )}
            <Typography component="span" variant="b5">
              <span> granted your request: </span>
              <span>{formatCurrency(notification.data.request.tipAmount.amount)}</span>
              {notification.data.request.tipNote && (
                <>
                  <span> for </span>
                  <span>&quot;{notification.data.request.tipNote}&quot;</span>
                </>
              )}
            </Typography>
          </Typography>
        ) : (
          <Typography component="span" variant="b5">
            <strong>Granted Wish</strong>
            <span> from </span>
            {notification.data.gifter.handle ? (
              <Link
                className="notranslate"
                component={RLink}
                to={Routes.public.gifter({ username: notification.data.gifter.handle })}
                variant="b5"
                sx={{ textDecoration: "none", position: "relative", zIndex: 2 }}
                onClick={(event) => event.stopPropagation()}
              >
                @{notification.data.gifter.handle}
              </Link>
            ) : (
              <span className="notranslate">{notification.data.gifter.pseudonym}</span>
            )}
          </Typography>
        )}
        <Typography component="span" variant="b5" sx={{ color: "neutral.5" }}>
          {formatTime(notification.createdAt, "dynamic")}
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
