import SearchIcon from "@mui/icons-material/Search";
import { Badge, BottomNavigation, BottomNavigationAction, Typography } from "@mui/material";
import { Link, useMatches } from "@remix-run/react";
import { useState } from "react";

import { RouteIds, Routes } from "~/constants/routes";
import { useCartItemCount } from "~/hooks/use-cart-item-count";
import { SearchDialogMobile } from "~/modules/search/components/search-bar-mobile";
import type { Auth } from "~/types";

import { LeaderboardIcon, ShoppingIcon } from "./icons";

const GUEST_NAVIGATION_STATES = {
  SEARCH: "Search",
  LEADERBOARD: "Leaderboard",
  CART: "Cart",
};

function useGuestNavigationState(): string {
  const matches = useMatches();

  /* Leaderboard */
  if (matches.some((match) => match.id === RouteIds.leaderboard.layout()))
    return GUEST_NAVIGATION_STATES.LEADERBOARD;

  /* Cart */
  if (matches.some((match) => match.id === RouteIds.cart.layout()))
    return GUEST_NAVIGATION_STATES.CART;

  return "";
}

export function GuestMobileNavigation({ user }: { user: Auth["user"] }) {
  const cartItemCount = useCartItemCount();
  const defaultNavigationValue = useGuestNavigationState();
  const [value, setValue] = useState(defaultNavigationValue);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);

  return (
    <>
      <BottomNavigation
        showLabels={true}
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          width: "100%",
          borderTop: 1,
          borderColor: "gray.2",
          "& .MuiBottomNavigationAction-root": {
            px: 0,
            color: "neutral.5",
            "&.Mui-selected": { color: "teal.4" },
          },
        }}
      >
        {user?.featureFlags.includes("search") && (
          <BottomNavigationAction
            value={GUEST_NAVIGATION_STATES.SEARCH}
            label={<Typography variant="b3">Search</Typography>}
            icon={<SearchIcon />}
            onClick={() => setOpenSearchDialog(true)}
          />
        )}
        <BottomNavigationAction
          value={GUEST_NAVIGATION_STATES.LEADERBOARD}
          component={Link}
          to={Routes.leaderboard.main()}
          label={<Typography variant="b3">Leaderboard</Typography>}
          icon={<LeaderboardIcon />}
        />
        <BottomNavigationAction
          value={GUEST_NAVIGATION_STATES.CART}
          component={Link}
          to={Routes.cart.default()}
          label={<Typography variant="b3">Cart</Typography>}
          icon={
            <Badge
              badgeContent={cartItemCount}
              sx={{
                "& .MuiBadge-badge": {
                  top: 4,
                  color: "neutral.0",
                  fontSize: 10,
                  bgcolor: "red.4",
                },
              }}
            >
              <ShoppingIcon />
            </Badge>
          }
          sx={{ px: 0 }}
        />
      </BottomNavigation>
      <SearchDialogMobile open={openSearchDialog} setOpen={setOpenSearchDialog} />
    </>
  );
}
