import { Box, CircularProgress, Typography } from "@mui/material";
import { useLocation, useNavigation } from "@remix-run/react";

import { Pagination } from "~/components/pagination";
import { Routes } from "~/constants/routes";
import { ReferralTable } from "~/modules/referrals";

import { useWisherReferrals } from "../../hooks/use-wisher-referrals";

export function ReferralsSection() {
  const { data, total } = useWisherReferrals();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigation = useNavigation();

  const page = Number(params.get("page")) || 1;
  const limit = 5;

  const totalPage = Math.ceil(total / limit) || 1;

  const nextParams = new URLSearchParams(navigation.location?.search);
  const isLoading =
    navigation.state === "loading" &&
    navigation.location?.pathname.startsWith(Routes.wisher.referrals.main()) &&
    params.get("page") !== nextParams.get("page");

  return (
    <Box>
      <Typography variant="h8" color="neutral.7">
        My Referrals
      </Typography>
      <Box sx={{ mt: "20px" }} />
      <Box sx={{ position: "relative" }}>
        <Box sx={{ opacity: isLoading ? 0.5 : 1 }}>
          <ReferralTable referrals={data} />
        </Box>
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              inset: 0,
              display: "grid",
              placeContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 2 }} />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          url={`${location.pathname}${location.search}`}
          count={totalPage}
          page={page}
          shape="rounded"
          preventScrollReset
        />
      </Box>
    </Box>
  );
}
