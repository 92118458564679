import { ThemeProvider } from "@emotion/react";
import { useRouteLoaderData } from "@remix-run/react";
import { type ReactNode } from "react";

import { useAuth } from "~/hooks/use-auth";
import { getOnboardingStatus } from "~/modules/auth";
import { getBannerUI } from "~/modules/banner/components";
import type { Banner as TBanner, BannerType } from "~/modules/banner/types";
import { GifterOnboard } from "~/modules/gifter";
import { WisherOnboard } from "~/modules/wisher";
import { themeV2 } from "~/utils/theme";

import { Footer } from "./footer";
import { GeneralHeader } from "./general-headers";
import { MobileNavigation } from "./mobile-navigation";
import { SimpleHeader } from "./simple-header";

export function GeneralLayout({ children }: { children?: ReactNode }) {
  const auth = useAuth();
  const onboarding = getOnboardingStatus(auth);
  const { banners } = useRouteLoaderData("root") as {
    banners: TBanner<BannerType>[];
  };

  if (!onboarding.isCompleted) {
    return (
      <>
        <SimpleHeader auth={auth} />
        {onboarding.isGifter && <GifterOnboard auth={auth} />}
        {onboarding.isWisher && <WisherOnboard auth={auth} />}
        <Footer />
      </>
    );
  }
  return (
    <>
      <GeneralHeader />
      <ThemeProvider theme={themeV2}>
        <Banner banners={banners} />
      </ThemeProvider>
      {children}
      <ThemeProvider theme={themeV2}>
        <Footer />
        <MobileNavigation />
      </ThemeProvider>
    </>
  );
}

function Banner({ banners }: { banners: TBanner<BannerType>[] }) {
  if (banners.length === 0) {
    return <></>;
  }

  // currently we just handle for 1 banner
  const banner = banners[0];
  const bannerUI = getBannerUI(banner.type);

  return <bannerUI.BannerContent banner={banner} />;
}
