import { zodResolver } from "@hookform/resolvers/zod";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { LoadingButton } from "@mui/lab";
import { ListItemIcon, ListItemText, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { Dialog } from "~/components/dialog";
import { TextField } from "~/components/form/text-field";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import { UpdateUserEmailSchema } from "~/modules/user/schemas";

export function UpdateGifterEmailItem({ email }: { email?: string }) {
  const [shouldOpenUpdateEmailDialog, setShouldOpenUpdateEmailDialog] = useState(false);

  const [updateEmail, updateEmailState] = useWtFetcher({
    onError: (response) => alert(response.error),
    onSuccess: () => setShouldOpenUpdateEmailDialog(false),
  });

  const form = useForm<z.infer<typeof UpdateUserEmailSchema>>({
    resolver: zodResolver(UpdateUserEmailSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function handleUpdateEmail(payload: z.infer<typeof UpdateUserEmailSchema>) {
    updateEmail.submit(payload, { method: "patch", action: Routes.actions.user.update() });
  }

  return (
    <>
      <MenuItem sx={{ py: 1 }} onClick={() => setShouldOpenUpdateEmailDialog(true)}>
        <ListItemText>Email</ListItemText>
        <Typography component="span" variant="b4" color="text.secondary">
          {email}
        </Typography>
        <ListItemIcon>
          <ArrowRight />
        </ListItemIcon>
      </MenuItem>
      <Dialog.Root
        maxWidth="xs"
        fullWidth
        variant="normal"
        open={shouldOpenUpdateEmailDialog}
        onClose={() => setShouldOpenUpdateEmailDialog(false)}
      >
        <Dialog.Title sx={{ textAlign: "center" }}>Update Email</Dialog.Title>
        <Dialog.Content>
          <Stack spacing={5} component="form" onSubmit={form.handleSubmit(handleUpdateEmail)}>
            <Stack spacing={2}>
              <Controller
                name="email"
                control={form.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextField.Root error={!!error} fullWidth>
                      <TextField.Label>New Email</TextField.Label>
                      <TextField.Input
                        {...field}
                        placeholder="Type new email"
                        autoComplete="email"
                      />
                      {error && <TextField.Helper error>{error.message}</TextField.Helper>}
                    </TextField.Root>
                  );
                }}
              />
              <Controller
                name="password"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <TextField.Root error={!!error} fullWidth>
                    <TextField.Label>Password</TextField.Label>
                    <TextField.Input
                      {...field}
                      type="password"
                      placeholder="Type your password"
                      autoComplete="current-password"
                    />
                    {error && <TextField.Helper error>{error.message}</TextField.Helper>}
                  </TextField.Root>
                )}
              />
            </Stack>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={updateEmailState.isFetching}
              disabled={updateEmailState.isFetching}
            >
              <span>Save</span>
            </LoadingButton>
          </Stack>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
