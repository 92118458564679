import type { IconButtonProps } from "@mui/material";
import {
  Badge,
  Box,
  Fade,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { IconChecks, IconInbox } from "@tabler/icons-react";
import type { ReactNode, SyntheticEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { useIntersectionObserver } from "usehooks-ts";

import { LightBulbIcon } from "~/components/icons/light-bulb-icon";

import { useNotifications } from "../context/notification-context";
import { NotificationItem } from "./notification-item";
import { NoticeSkeleton } from "./skeleton/notice-skeleton";
import { NotificationSkeleton } from "./skeleton/notification-skeleton";

export function NotificationHub() {
  const {
    markSeenAllNotificationsMutation,
    markSeenAllNoticesMutation,
    totalNewNotices,
    totalNewNotifications,
  } = useNotifications();

  const [tabIndex, setTabIndex] = useState(0);

  function handleChangeTab(_event: SyntheticEvent, newValue: number) {
    setTabIndex(newValue);
  }

  return (
    <Stack
      sx={{
        bgcolor: "neutral.0",
      }}
    >
      <Stack
        direction="row"
        sx={{
          px: 2,
          alignItems: "center",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          borderBottomWidth: 1,
          borderBottomColor: "neutral.2",
          borderBottomStyle: "solid",
          bgcolor: "neutral.0",
          zIndex: 2,
        }}
      >
        <Tabs
          variant="fullWidth"
          value={tabIndex}
          onChange={handleChangeTab}
          aria-label="Notification tabs"
          TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
          sx={{
            "& .MuiTabs-indicator": {
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              bgcolor: "transparent",
              height: 4,
            },
            "& .MuiTabs-indicatorSpan": {
              maxWidth: 70,
              width: "100%",
              height: 4,
              bgcolor: "teal.4",
              borderRadius: 2,
            },
            typography: "b4",
          }}
        >
          <Tab
            label="Inbox"
            id="notification-tab-inbox"
            aria-controls="notification-tabpanel-inbox"
            sx={{
              pt: 1,
              pb: 0,
              height: 48,
              typography: "b5",
            }}
          />
          <Tab
            label={
              <Badge badgeContent={totalNewNotices} color="primary" variant="dot">
                Notices
              </Badge>
            }
            id="notification-tab-notices"
            aria-controls="notification-tabpanel-notices"
            sx={{
              pt: 1,
              pb: 0,
              height: 48,
              typography: "b5",
            }}
            onClick={() => totalNewNotices > 0 && markSeenAllNoticesMutation.mutate()}
          />
        </Tabs>
        <Fade in={tabIndex === 0}>
          <Stack spacing={2} direction="row" sx={{ pt: 0.25 }}>
            <TabIconButton
              tooltip="Mark all as read"
              onClick={() => totalNewNotifications > 0 && markSeenAllNotificationsMutation.mutate()}
            >
              <IconChecks />
            </TabIconButton>
            {/* <TabIconButton tooltip="Select">
              <IconDotsVertical />
            </TabIconButton> */}
          </Stack>
        </Fade>
      </Stack>
      <TabInbox hidden={tabIndex !== 0} />
      <TabNotices hidden={tabIndex !== 1} />
    </Stack>
  );
}

function TabInbox({ hidden }: { hidden: boolean }) {
  const { notifications, loadMoreNotificationsQuery } = useNotifications();
  const triggerRef = useRef<Element | null>(null);
  const entry = useIntersectionObserver(triggerRef, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      loadMoreNotificationsQuery.loadMore();
    }
    // we only want to run this effect when isVisible changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={hidden}
      id="notification-tabpanel-inbox"
      aria-labelledby="notification-tab-inbox"
      sx={{ pt: 3 }}
    >
      {loadMoreNotificationsQuery.isFetching && notifications.length === 0 ? (
        <NotificationSkeleton />
      ) : (
        <Stack spacing={0.25}>
          {notifications.length > 0 ? (
            <>
              {notifications.map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
              {loadMoreNotificationsQuery.isFetching && <NotificationSkeleton items={2} />}
              <Box ref={triggerRef} mt={2} />
            </>
          ) : (
            <TabInboxEmptyState />
          )}
        </Stack>
      )}
    </Box>
  );
}

function TabNotices({ hidden }: { hidden: boolean }) {
  const { notices, loadMoreNoticesQuery } = useNotifications();
  const triggerRef = useRef<Element | null>(null);
  const entry = useIntersectionObserver(triggerRef, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      loadMoreNoticesQuery.loadMore();
    }
    // we only want to run this effect when isVisible changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={hidden}
      id="notification-tabpanel-notices"
      aria-labelledby="notification-tab-notices"
      sx={{ pt: 3 }}
    >
      {loadMoreNoticesQuery.isFetching && notices.length === 0 ? (
        <NoticeSkeleton items={3} />
      ) : (
        <Stack spacing={0.25} overflow="hidden">
          {notices.length > 0 ? (
            <>
              {notices.map((notice) => (
                <NotificationItem key={notice.id} notification={notice} />
              ))}
              {loadMoreNoticesQuery.isFetching && <NoticeSkeleton items={2} />}
              <Box ref={triggerRef} mt={2} />
            </>
          ) : (
            <TabNoticeEmptyState />
          )}
        </Stack>
      )}

      {/* <Stack
          spacing={1}
          direction="row"
          sx={{
            pl: 2,
            pr: 1.5,
            py: 1,
            borderRadius: 2,
            bgcolor: "neutral.1",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "neutral.2",
            alignSelf: "center",
          }}
        >
          <Typography component="span" variant="h4">
            See all site updates and roadmap
          </Typography>
          <IconArrowRight style={{ color: theme.palette.neutral[5] }} />
        </Stack> */}
    </Box>
  );
}

function TabIconButton({
  tooltip,
  children,
  ...others
}: {
  tooltip: string;
  children: ReactNode;
} & IconButtonProps) {
  return (
    <Tooltip title={tooltip} arrow>
      <IconButton
        sx={{
          width: 32,
          height: 32,
          borderRadius: 1,
          p: 0.5,
          ":hover": {
            color: "neutral.6",
          },
        }}
        {...others}
      >
        <Stack>{children}</Stack>
      </IconButton>
    </Tooltip>
  );
}

function TabInboxEmptyState() {
  const theme = useTheme();
  return (
    <Stack sx={{ pt: 2.5, pb: 6 }}>
      <Box
        sx={{
          height: 72,
          width: 72,
          borderRadius: "50%",
          p: 2,
          // bgcolor: "teal.1",
          bgcolor: "teal.0",
          alignSelf: "center",
        }}
      >
        <IconInbox size={40} style={{ color: theme.palette.blue[6] }} />
        {/* <IconCircleCheckFilled size={40} style={{ color: theme.palette.teal[4] }} /> */}
      </Box>

      <Box mt={3} />

      <Typography component="span" variant="h6" sx={{ textAlign: "center" }}>
        No notifications yet
        {/* You’re all caught up */}
      </Typography>

      <Box mt={2} />

      <Typography component="span" variant="b5" sx={{ textAlign: "center", color: "neutral.5" }}>
        We’ll let you know when there’s new activity.
        {/* You’ve cleared all notifications. */}
      </Typography>
    </Stack>
  );
}

function TabNoticeEmptyState() {
  return (
    <Stack sx={{ pt: 2.5, pb: 6, px: 3 }}>
      <Box
        sx={{
          height: 72,
          width: 72,
          borderRadius: "50%",
          p: 2,
          bgcolor: "teal.1",
          alignSelf: "center",
        }}
      >
        <LightBulbIcon sx={{ fontSize: 40 }} />
      </Box>

      <Box mt={3} />

      <Typography component="span" variant="h6" sx={{ textAlign: "center" }}>
        Updates coming soon
      </Typography>

      <Box mt={2} />

      <Typography component="span" variant="b5" sx={{ textAlign: "center", color: "neutral.5" }}>
        We’ll let you know when there are new updates, features or improvements to the site here
      </Typography>
    </Stack>
  );
}
