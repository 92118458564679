import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Box, Divider, Drawer, IconButton, Stack } from "@mui/material";
import { useState } from "react";

import {
  AccountSettingIcon,
  BacknoteSparkleIcon,
  HamburgerIcon,
  SignOutIcon,
  SparklesFilledIcon,
} from "~/components/icons";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useNotifications } from "~/modules/notifications";

import { StyledMenuItem, UserInfoCard } from "./account-nav-menu.common";
import { AdminMenuItem } from "./admin-menu-item";

function WisherMenu({
  closeMenu,
  resetNotification,
}: {
  closeMenu: () => void;
  resetNotification: () => void;
}) {
  return (
    <Box>
      <StyledMenuItem
        to={Routes.settings.main()}
        onClick={closeMenu}
        icon={<AccountSettingIcon />}
        text="Account Settings"
      />
      <StyledMenuItem
        to={Routes.wisher.paymentDashboard.main()}
        onClick={closeMenu}
        icon={<BacknoteSparkleIcon />}
        text="Payment Dashboard"
      />
      <StyledMenuItem
        to={Routes.external.intercom()}
        onClick={closeMenu}
        icon={<InfoIcon />}
        text="FAQ"
      />
      <Divider sx={{ borderColor: "gray.2" }} />
      <AdminMenuItem closeMenu={closeMenu} />
      <StyledMenuItem
        to={Routes.auth.logout()}
        onClick={() => {
          resetNotification();
          closeMenu();
        }}
        icon={<SignOutIcon />}
        text="Log Out"
      />
    </Box>
  );
}

function GifterMenu({
  closeMenu,
  resetNotification,
}: {
  closeMenu: () => void;
  resetNotification: () => void;
}) {
  return (
    <Box>
      <StyledMenuItem
        to={Routes.gifter.settings.main()}
        onClick={closeMenu}
        icon={<AccountSettingIcon />}
        text="Account Settings"
      />
      <StyledMenuItem
        to={Routes.external.intercom()}
        onClick={closeMenu}
        icon={<InfoIcon />}
        text="FAQ"
      />
      <Divider sx={{ borderColor: "gray.2" }} />
      <AdminMenuItem closeMenu={closeMenu} />
      <StyledMenuItem
        to={Routes.auth.logout()}
        onClick={() => {
          resetNotification();
          closeMenu();
        }}
        icon={<SignOutIcon />}
        text="Log Out"
      />
    </Box>
  );
}

function GuestMenu({ closeMenu }: { closeMenu: () => void }) {
  return (
    <Box>
      <StyledMenuItem
        to={Routes.auth.signUp()}
        onClick={closeMenu}
        icon={<SparklesFilledIcon />}
        text="Sign Up"
        iconColor="teal.4"
        textColor="teal.4"
      />
      <StyledMenuItem
        to={Routes.external.intercom()}
        onClick={closeMenu}
        icon={<InfoIcon />}
        text="FAQ"
      />
    </Box>
  );
}

export function AccountNavMenuMobile() {
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  const { reset } = useNotifications();

  /* Set the default menu to be Guest Menu */
  let menu = <GuestMenu closeMenu={() => setIsOpen(false)} />;
  if (auth.wisher) {
    menu = <WisherMenu closeMenu={() => setIsOpen(false)} resetNotification={reset} />;
  }
  if (auth.gifter) {
    menu = <GifterMenu closeMenu={() => setIsOpen(false)} resetNotification={reset} />;
  }

  return (
    <>
      <IconButton
        type="button"
        edge="start"
        color="inherit"
        aria-label={isOpen ? "close menu" : "menu"}
        size="large"
        sx={
          isOpen
            ? { border: 2, borderColor: "teal.4", borderRadius: 2, px: 1, py: 1 }
            : { px: 1, py: 1 }
        }
        onClick={() => setIsOpen((x) => !x)}
      >
        {isOpen ? <CloseIcon sx={{ fill: (theme) => theme.palette.teal[4] }} /> : <HamburgerIcon />}
      </IconButton>

      <Drawer
        hideBackdrop={true}
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          width: "100%",
          top: 72,
          display: {
            xs: "block",
            sm: "none",
          },
          "& .MuiDrawer-paper": {
            width: "100%",
            top: 72,
            boxShadow: "none",
          },
        }}
      >
        <Box sx={{ bgcolor: "gray.0", height: "100%", px: 0, py: 2 }}>
          <Stack spacing={1}>
            <UserInfoCard onClick={() => setIsOpen(false)} />
            {menu}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
