import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";
import { useId } from "react";

export function RecapLetterIcon(props: SvgIconProps) {
  const id = useId();

  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M41.9346 0.613525H6.06429C4.27188 0.613525 2.81885 2.06656 2.81885 3.85897V20.9118C2.81885 22.7042 4.27188 24.1572 6.06429 24.1572H41.9346C43.727 24.1572 45.18 22.7042 45.18 20.9118V3.85897C45.18 2.06656 43.727 0.613525 41.9346 0.613525Z"
        fill="#02BFF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 19.6746C48 18.1522 47.1669 16.7536 45.8275 16.029C39.9198 12.8315 23.9994 4.21362 23.9994 4.21362C23.9994 4.21362 8.0789 12.8315 2.1712 16.029C0.834399 16.7523 0 18.1522 0 19.6746V44.5109C0 45.2733 0.302959 46.0055 0.843235 46.5445C1.38225 47.0835 2.1144 47.3865 2.87684 47.3865H45.1244C45.8869 47.3865 46.619 47.0835 47.158 46.5445C47.697 46.0055 48 45.2733 48 44.5109V19.6746Z"
        fill="#02BFF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 19.3919C47.9748 18.9867 47.8813 18.5891 47.7286 18.1978L24.0006 32.7208L0.265089 18.2154C0.0971992 18.6471 0.0151479 19.0776 0 19.5068V28.7697H48V19.3919Z"
        fill={`url(#${id}_paint0_linear_40_815)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.18278 2.22681H40.8162C42.1808 2.22681 43.2866 3.33261 43.2866 4.69718V34.0867C43.2866 35.4513 42.1808 36.5571 40.8162 36.5571H7.18278C5.8182 36.5571 4.7124 35.4513 4.7124 34.0867V4.69718C4.7124 3.33261 5.8182 2.22681 7.18278 2.22681Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.2876 36.557V18.1978C42.895 21.3056 37.9946 24.594 32.602 27.4569C27.2093 30.3199 26.1225 27.0946 21.8419 28.7697C17.5626 30.4461 13.7201 29.0146 7.32766 25.4587C6.28119 24.8767 5.42028 24.392 4.71338 23.9906V36.557H43.2876Z"
        fill={`url(#${id}_paint1_linear_40_815)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 21.134L24.0006 35.2999L0 21.134V44.5098C0 45.2723 0.302959 46.0044 0.843235 46.5434C1.38225 47.0824 2.1144 47.3854 2.87684 47.3854H45.1244C45.8869 47.3854 46.619 47.0824 47.158 46.5434C47.697 46.0044 48 45.2723 48 44.5098V21.134Z"
        fill="#02BFF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8036 31.8738L23.9994 35.2997L18.1952 31.8738L0 40.1281V44.5109C0 45.2734 0.302959 46.0055 0.843235 46.5445C1.38225 47.0836 2.1144 47.3865 2.87684 47.3865H45.1244C45.8869 47.3865 46.619 47.0836 47.158 46.5445C47.697 46.0055 48 45.2734 48 44.5109V40.1281L29.8048 31.8738H29.8036Z"
        fill={`url(#${id}_paint2_linear_40_815)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 44.4113V44.5097C0 45.2722 0.302959 46.0043 0.843235 46.5434C1.38225 47.0824 2.1144 47.3853 2.87684 47.3853H45.1244C45.8869 47.3853 46.619 47.0824 47.158 46.5434C47.697 46.0043 48 45.2722 48 44.5097V44.4113C48 44.4113 34.1308 35.1938 27.4783 30.7718C25.3715 29.3707 22.6285 29.3707 20.5217 30.7718L0 44.4113Z"
        fill={`url(#${id}_paint3_radial_40_815)`}
      />
      <path
        d="M16.2643 14.1334C16.1017 14.3774 16 14.6621 16 14.9469C16 15.2316 16.0813 15.5163 16.2643 15.7603C16.4269 16.0044 16.6709 16.1874 16.9555 16.2891L19.2529 17.0416C19.8831 17.2449 20.4524 17.611 20.8996 18.0584C21.3672 18.5261 21.7128 19.0956 21.9161 19.726L22.6684 22.0444C22.77 22.3291 22.953 22.5528 23.1766 22.7359C23.4206 22.8986 23.7052 23.0002 23.9898 23.0002C24.2745 23.0002 24.5794 22.9189 24.803 22.7359C25.047 22.5732 25.2097 22.3291 25.3113 22.0444L26.0635 19.7464C26.2668 19.1362 26.6328 18.5465 27.0801 18.0991C27.5477 17.6313 28.1169 17.2856 28.7268 17.0822L31.0445 16.3298C31.3291 16.2281 31.5731 16.045 31.7357 15.8213C31.8983 15.5773 32 15.2926 32 15.0079C32 14.7232 31.9187 14.4181 31.7357 14.1944C31.5731 13.9504 31.3291 13.7673 30.9835 13.6656L28.6861 12.9132C28.0559 12.7098 27.4867 12.3437 27.0191 11.8963C26.5515 11.4286 26.2058 10.8592 26.0025 10.2491L25.2503 7.95099C25.1487 7.66628 24.986 7.44257 24.7421 7.25954C24.2745 6.91381 23.5832 6.91381 23.0953 7.25954C22.8513 7.42223 22.6684 7.66628 22.587 7.95099L21.8145 10.2897C21.6112 10.8998 21.2656 11.4489 20.798 11.8963C20.3507 12.3437 19.7814 12.6895 19.1919 12.9132L16.8742 13.6656C16.5896 13.7673 16.3456 13.9504 16.183 14.1741L16.2643 14.1334Z"
        fill={`url(#${id}_paint4_linear_40_815)`}
      />
      <defs>
        <linearGradient
          id={`${id}_paint0_linear_40_815`}
          x1="23.9994"
          y1="32.7208"
          x2="23.9994"
          y2="18.1965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4353FF" />
          <stop offset="0.14" stopColor="#415CFF" />
          <stop offset="0.38" stopColor="#3E76FF" />
          <stop offset="1" stopColor="#02BFF2" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint1_linear_40_815`}
          x1="14.9231"
          y1="15.2123"
          x2="37.9921"
          y2="46.1293"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#B3CDE9" stopOpacity="0" />
          <stop offset="1" stopColor="#B3CDE9" />
        </linearGradient>
        <linearGradient
          id={`${id}_paint2_linear_40_815`}
          x1="23.9994"
          y1="47.3865"
          x2="23.9994"
          y2="31.8738"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#008DD1" />
          <stop offset="1" stopColor="#02BFF2" />
        </linearGradient>
        <radialGradient
          id={`${id}_paint3_radial_40_815`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.3793 26.2893) scale(11.8671)"
        >
          <stop offset="0.04" stopColor="#C4E9FB" stopOpacity="0.9" />
          <stop offset="1" stopColor="#02BFF2" />
        </radialGradient>
        <linearGradient
          id={`${id}_paint4_linear_40_815`}
          x1="24.0029"
          y1="14.1628"
          x2="24.0029"
          y2="57.5721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD079" />
          <stop offset="0.17" stopColor="#FFB43F" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
