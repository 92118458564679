import { Box } from "@mui/material";

import { useAuth } from "~/hooks/use-auth";

import { GifterMobileNavigation } from "./mobile-navigation-gifter";
import { GuestMobileNavigation } from "./mobile-navigation-guest";
import { WisherMobileNavigation } from "./mobile-navigation-wisher";

export function MobileNavigation() {
  const auth = useAuth();
  let navigator = <GuestMobileNavigation user={auth.user} />;
  if (auth.wisher) {
    navigator = <WisherMobileNavigation wisher={auth.wisher} user={auth.user} />;
  }
  if (auth.gifter) {
    navigator = <GifterMobileNavigation user={auth.user} />;
  }

  return (
    <Box
      sx={{
        /* Mobile only */
        display: {
          xs: "flex",
          sm: "none",
        },
        position: "fixed",
        zIndex: 3,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      {navigator}
    </Box>
  );
}
