import { Skeleton, Stack } from "@mui/material";

export function NoticeSkeleton({ items = 8 }: { items?: number }) {
  return (
    <Stack spacing={3} sx={{ width: "100%" }}>
      {[...Array.from({ length: items }).keys()].map((item) => (
        <Stack
          key={`notice_skeleton_${item}`}
          spacing={1.5}
          sx={{
            width: "100%",
            pt: 1,
            pb: 2,
            pl: 2.5,
            pr: 2,
          }}
        >
          <Stack spacing={1} sx={{ width: "100%" }}>
            <Skeleton variant="rounded" width="24%" height={16} />
            <Skeleton variant="rounded" width="70%" height={20} />
          </Stack>
          <Stack spacing={1} sx={{ width: "100%" }}>
            <Skeleton variant="rounded" width="100%" height={16} />
            <Skeleton variant="rounded" width="100%" height={16} />
            <Skeleton variant="rounded" width="100%" height={16} />
          </Stack>
          <Skeleton variant="rounded" width="60%" height={24} />
        </Stack>
      ))}
    </Stack>
  );
}
