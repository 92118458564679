import { SvgIcon, type SvgIconProps } from "@mui/material";

export function LightBulbIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      {...props}
    >
      <path
        d="M15.5 30H25.5V33.3333C25.5 35.1743 24.0076 36.6667 22.1667 36.6667H18.8333C16.9924 36.6667 15.5 35.1743 15.5 33.3333V30Z"
        fill="#0CB4E9"
      />
      <path
        d="M18.3452 17.1547C17.6943 16.5038 16.639 16.5038 15.9882 17.1547C15.3373 17.8055 15.3373 18.8608 15.9882 19.5117L18.8333 22.3569V33.3332H22.1667V22.3569L25.0118 19.5117C25.6627 18.8608 25.6627 17.8055 25.0118 17.1547C24.361 16.5038 23.3057 16.5038 22.6548 17.1547L20.5 19.3095L18.3452 17.1547Z"
        fill="#1F5099"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4998 25.4757L26.7483 24.475C29.0412 22.6368 30.4998 19.8233 30.4998 16.6668C30.4998 11.144 26.0227 6.66683 20.4998 6.66683C14.977 6.66683 10.4998 11.144 10.4998 16.6668C10.4998 19.8233 11.9584 22.6368 14.2514 24.475L15.4998 25.4757V28.3335C15.4998 29.254 16.246 30.0002 17.1665 30.0002H23.8332C24.7536 30.0002 25.4998 29.254 25.4998 28.3335V25.4757ZM28.8332 27.0758V28.3335C28.8332 31.0949 26.5946 33.3335 23.8332 33.3335H17.1665C14.4051 33.3335 12.1665 31.0949 12.1665 28.3335V27.0758C9.1183 24.6322 7.1665 20.8775 7.1665 16.6668C7.1665 9.30303 13.136 3.3335 20.4998 3.3335C27.8636 3.3335 33.8332 9.30303 33.8332 16.6668C33.8332 20.8775 31.8814 24.6322 28.8332 27.0758Z"
        fill="#1F5099"
      />
    </SvgIcon>
  );
}
