import type { OutlinedInputProps } from "@mui/material";
import { Box, Divider, IconButton, InputAdornment, Stack, useTheme } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useSearchParams } from "@remix-run/react";
import { IconSearch, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import type { z } from "zod";

import { TextField } from "~/components/form/text-field";

import { useSearchFullPage } from "../hooks/use-search-full-page";
import type { SearchSchema } from "../schemas";

type SearchInputFormProps = Omit<OutlinedInputProps, "ref"> & {
  onSubmitted?: () => void;
  loading?: boolean;
};

export function SearchInputForm({
  onSubmitted,
  loading = false,
  onFocus,
  ...others
}: SearchInputFormProps) {
  const theme = useTheme();
  const { search } = useSearchFullPage();
  const [searchParams] = useSearchParams();

  const [isFocused, setIsFocused] = useState(false);

  const queryString = searchParams.get("queryString") ?? "";

  const { setValue, handleSubmit, reset, control } = useFormContext<z.infer<typeof SearchSchema>>();

  function handleSearch(data: { searchText: string }) {
    if (data.searchText) {
      search({ queryString: data.searchText, page: 1 });
      onSubmitted && onSubmitted();
    }
  }

  useEffect(() => {
    if (queryString !== "") setValue("searchText", queryString);
  }, [queryString, setValue]);

  return (
    <Box component="form" onSubmit={handleSubmit(handleSearch)}>
      <Controller
        render={({ field, fieldState: { isDirty } }) => (
          <TextField.Root
            sx={{
              "& input:-webkit-autofill": {
                height: 16,
                borderRadius: 0,
              },
            }}
            hiddenLabel
            fullWidth
          >
            <TextField.Label sx={visuallyHidden}>Search</TextField.Label>
            <Stack
              sx={{
                borderWidth: 3,
                borderStyle: "solid",
                borderColor: (theme) => (isFocused ? theme.palette.teal[1] : "transparent"),
                borderRadius: 8,
              }}
            >
              <TextField.Input
                {...field}
                placeholder="Search"
                notched={false}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    {isDirty && (
                      <>
                        <IconButton
                          aria-label="Clear search text"
                          disableRipple
                          onClick={() => reset()}
                        >
                          <IconX
                            color={isFocused ? theme.palette.neutral[6] : theme.palette.neutral[5]}
                          />
                        </IconButton>
                        <Divider orientation="vertical" sx={{ height: 24 }} />
                      </>
                    )}
                    {loading && isFocused ? (
                      <SearchLoader />
                    ) : (
                      <IconButton aria-label="Submit search" disableRipple type="submit">
                        <IconSearch
                          color={isFocused ? theme.palette.neutral[6] : theme.palette.neutral[5]}
                        />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "1px !important",
                    borderColor: (theme) =>
                      `${isFocused ? theme.palette.teal[3] : "transparent"} !important`,
                  },

                  bgcolor: "neutral.2",
                  height: 40,
                  borderRadius: 8,
                  px: 1.5,
                }}
                onFocus={(event) => {
                  setIsFocused(true);
                  onFocus && onFocus(event);
                }}
                onBlur={() => {
                  setIsFocused(false);
                  field.onBlur();
                }}
                {...others}
              />
            </Stack>
          </TextField.Root>
        )}
        name={"searchText"}
        control={control}
      />
    </Box>
  );
}

function SearchLoader() {
  return (
    <Box
      component="img"
      src="/icons/loader.png"
      alt="loader"
      sx={{
        animation: "rotation 2s infinite linear",
        "@keyframes rotation": {
          from: { transform: "rotate(0deg)" },
          to: { transform: "rotate(360deg)" },
        },
        p: 1,
      }}
    />
  );
}
