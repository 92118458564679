import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";

import { RecapLetterIcon } from "~/components/icons/recap-letter-icon";

import type { NotificationType, NotificationV2 } from "../../types";
import { EndOfYearRecapDialog } from "../end-of-year-recap-dialog";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.YEARLY_RECAP_WRAPPED>;
}) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  return (
    <NotificationContentContainer
      notification={notification}
      sx={{
        bgcolor: "neutral.0",
      }}
      onClick={() => setIsDialogOpened(true)}
    >
      {/* This element is for multi-color gradient border */}
      <Box
        sx={{
          padding: "3px",
          borderRadius: 2,
          background:
            "linear-gradient(2.92deg, #007EFF -4.71%, #02D6F2 28.23%, #01AEF8 56%, #D06DFF 83.76%)",
        }}
      >
        <Stack
          spacing={2}
          direction="row"
          sx={{ width: "100%", borderRadius: 1.5, px: 2, py: 1.5, bgcolor: "neutral.0" }}
        >
          <RecapLetterIcon sx={{ fontSize: 48 }} />

          <Stack spacing={0.5}>
            <Typography
              variant="h5"
              color="linear-gradient(to left, rgb(53, 133, 254), rgb(52, 218, 255))"
            >
              Your 2023 End-of-Year Recap
            </Typography>

            <Typography variant="b5">Unwrap your stats from last year now 🎁</Typography>
          </Stack>
        </Stack>
      </Box>

      <EndOfYearRecapDialog
        notification={notification}
        open={isDialogOpened}
        onClose={() => setIsDialogOpened(false)}
      />
    </NotificationContentContainer>
  );
}
