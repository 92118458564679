import { Box, Stack, Typography } from "@mui/material";

import { InfoCircleFilledIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";

import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.SETUP_PAYOUT_METHOD_REMIND>;
}) {
  return (
    <NotificationContentContainer to={Routes.wisher.activeWishlist()} notification={notification}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "blue.0",
          borderRadius: 6,
          height: 40,
          minWidth: 40,
          color: "blue.4",
        }}
      >
        <InfoCircleFilledIcon sx={{ fontSize: 28 }} />
      </Box>
      <Stack>
        <Typography color="neutral.7" variant="h5">
          You’ve yet to set up a payout method
        </Typography>
        <Typography color="neutral.7" variant="b5">
          Get started by going to your Payment Dashboard.{" "}
          <Typography component="span" variant="h5">
            We recommend choosing {notification.data.recommendedCurrency}
          </Typography>{" "}
          as your currency for low payout fees.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
