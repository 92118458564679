import { SvgIcon, type SvgIconProps } from "@mui/material";
import { useId } from "react";

export function MedalIcon(props: SvgIconProps) {
  const id = useId();
  const clipPathId = `cp_${id}`;
  const l1Id = `l1_${id}`;
  const l2Id = `l2_${id}`;
  const l3Id = `l3_${id}`;
  const l4Id = `l4_${id}`;
  const l5Id = `l5_${id}`;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="66"
      viewBox="0 0 48 66"
      fill="none"
      {...props}
    >
      <g clipPath={`url(#${clipPathId})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6067 43.7503C35.6067 43.1667 35.3049 42.6251 34.8055 42.3178C34.3062 42.0105 33.6841 41.9839 33.1608 42.2421C30.4003 43.6086 27.2901 44.3775 24.0006 44.3775C20.7112 44.3775 17.5995 43.61 14.8404 42.2421C14.3171 41.9839 13.6937 42.0105 13.1957 42.3178C12.6978 42.6251 12.3945 43.1667 12.3945 43.7503V62.0196C12.3945 63.4704 13.2183 64.8229 14.588 65.5259C15.8857 66.1923 17.4726 66.1516 18.7322 65.422L23.5422 62.6356C23.8243 62.4714 24.1812 62.4714 24.4633 62.6356L29.2733 65.422C30.5329 66.1516 32.1198 66.1923 33.4175 65.5259C34.7872 64.8229 35.611 63.469 35.611 62.0196V43.7503H35.6067Z"
          fill={`url(#${l1Id})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.692 44.3074C25.1348 44.3523 24.5678 44.3775 23.9993 44.3775C23.4309 44.3775 22.8638 44.3523 22.3066 44.3074V63.3511L23.5395 62.637C23.8216 62.4728 24.1785 62.4728 24.4606 62.637L25.6934 63.3511V44.3074H25.692Z"
          fill={`url(#${l2Id})`}
        />
        <path
          d="M23.9993 47.7434C37.2537 47.7434 47.9986 37.0556 47.9986 23.8717C47.9986 10.6877 37.2537 0 23.9993 0C10.7448 0 0 10.6877 0 23.8717C0 37.0556 10.7448 47.7434 23.9993 47.7434Z"
          fill={`url(#${l3Id})`}
        />
        <path
          d="M23.9994 40.7618C33.3774 40.7618 40.9797 33.1999 40.9797 23.8717C40.9797 14.5436 33.3774 6.98169 23.9994 6.98169C14.6214 6.98169 7.01904 14.5436 7.01904 23.8717C7.01904 33.1999 14.6214 40.7618 23.9994 40.7618Z"
          fill={`url(#${l4Id})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4239 15.546C25.1121 15.0633 24.5747 14.7715 23.9992 14.7715C23.4237 14.7715 22.8863 15.0633 22.5745 15.546L20.3966 18.9203L16.4964 19.9376C15.9392 20.0835 15.4949 20.5016 15.3158 21.046C15.138 21.589 15.2523 22.1881 15.6162 22.6314L18.1707 25.7364L17.938 29.7393C17.9042 30.3118 18.1665 30.8618 18.6334 31.1985C19.0989 31.5353 19.7054 31.611 20.2429 31.4048L23.9978 29.9484L27.7527 31.4048C28.2901 31.611 28.8967 31.5353 29.3621 31.1985C29.829 30.8618 30.0914 30.3118 30.0576 29.7393L29.8248 25.735L32.3793 22.6328C32.7433 22.1909 32.8575 21.5918 32.6798 21.0474C32.5021 20.5016 32.0563 20.0849 31.4991 19.939L27.5989 18.9217L25.421 15.5474L25.4239 15.546Z"
          fill={`url(#${l5Id})`}
        />
      </g>
      <defs>
        <linearGradient
          id={l1Id}
          x1="24.4916"
          y1="60.028"
          x2="24.4916"
          y2="46.5839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD079" />
          <stop offset="1" stopColor="#FFB43F" />
        </linearGradient>
        <linearGradient
          id={l2Id}
          x1="23.5038"
          y1="53.1189"
          x2="23.5038"
          y2="40.0732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFB35D" />
        </linearGradient>
        <linearGradient
          id={l3Id}
          x1="23.7787"
          y1="22.5306"
          x2="23.7787"
          y2="91.1684"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.08" stopColor="#34DAFF" />
          <stop offset="0.65" stopColor="#3585FE" />
        </linearGradient>
        <linearGradient
          id={l4Id}
          x1="23.6639"
          y1="13.873"
          x2="23.6639"
          y2="61.8325"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4353FF" />
          <stop offset="0.14" stopColor="#415CFF" />
          <stop offset="0.38" stopColor="#3E76FF" />
          <stop offset="0.68" stopColor="#3A9FFF" />
          <stop offset="1" stopColor="#34D3FF" />
        </linearGradient>
        <linearGradient
          id={l5Id}
          x1="23.4214"
          y1="22.2675"
          x2="23.4214"
          y2="67.7015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#B3B3B3" />
        </linearGradient>
        <clipPath id={clipPathId}>
          <rect width="48" height="66" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
