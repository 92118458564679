import { Box, Typography } from "@mui/material";
import { IconClipboardList, IconGiftFilled } from "@tabler/icons-react";
import type { ReactNode } from "react";

import { BacknoteSparkleIcon, SparklesFilledIcon } from "~/components/icons";
import type { ReferralActivity } from "~/modules/referrals/types";
import { formatTime } from "~/utils/time";

import { useWisherReferralActivities } from "../../hooks/use-wisher-referrals";

export function ActivitiesSection() {
  const activities = useWisherReferralActivities();
  return (
    <Box>
      <Typography variant="sh3" color="neutral.7">
        ACTIVITIES
      </Typography>
      <Box mt={2} />
      {activities.length === 0 && (
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: 48,
              height: 48,
              bgcolor: "neutral.2",
              color: "neutral.7",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 999,
            }}
          >
            <IconClipboardList size={32} />
          </Box>
          <Box mt={2} />
          <Typography variant="b5" color="neutral.5">
            Recent activities related to your referrals will show up here
          </Typography>
        </Box>
      )}
      {activities.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {activities.slice(0, 5).map((activity) => (
            <Box key={activity.id} sx={{ p: 0.5, display: "flex", gap: 1 }}>
              <Box sx={{ flexShrink: 0, color: "neutral.5" }}>{ActivityIconMap[activity.type]}</Box>
              <Box>
                <Typography variant="b5" color="neutral.7" component="div">
                  {ActivityTitleMap[activity.type]}
                </Typography>
                <Typography variant="b4" color="neutral.5" component="div">
                  {formatTime(activity.createdAt, "dynamic")}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

const ActivityIconMap: Record<ReferralActivity["type"], ReactNode> = {
  wisherSignedUp: <SparklesFilledIcon sx={{ width: 24, height: 24 }} />,
  gifterSignedUp: <IconGiftFilled size={24} />,
  wisherReceivedGift: <BacknoteSparkleIcon sx={{ width: 24, height: 24, color: "primary.4" }} />,
  gifterSentGift: <BacknoteSparkleIcon sx={{ width: 24, height: 24, color: "primary.4" }} />,
};

const ActivityTitleMap: Record<ReferralActivity["type"], ReactNode> = {
  wisherSignedUp: "A new wisher signed up with your code.",
  gifterSignedUp: "A new gifter signed up with your code.",
  wisherReceivedGift: "One of your referees was granted a wish.",
  gifterSentGift: "One of your referees gifted somebody.",
};
