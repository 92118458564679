import { Box, Stack, Typography } from "@mui/material";
import { IconInfoCircleFilled } from "@tabler/icons-react";

import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.INCORRECT_ACTIVATED_STRIPE_ACCOUNT_NOTIFICATION>;
}) {
  return (
    <NotificationContentContainer notification={notification}>
      <Box
        sx={{
          color: "teal.4",
          backgroundColor: "teal.0",
          p: 0.75,
          borderRadius: 6,
          height: 40,
          width: 40,
        }}
      >
        <IconInfoCircleFilled size={28} />
      </Box>
      <Stack sx={{ color: "neutral.7" }}>
        <Typography variant="h5">Reach out to us to reactivate your account</Typography>
        <Typography variant="b5">
          We accidentally sent you a notification telling you to follow a link to reactivate your
          account. This link was incorrect. Instead, contact us in the support chat or at&nbsp;
          <Typography variant="b5" component="a" href="mailto:support@wishtender.com">
            support@wishtender.com
          </Typography>
          &nbsp;for help reactivating your account.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
