import { Box, Skeleton, Stack } from "@mui/material";

export function NotificationSkeleton({ items = 8 }: { items?: number }) {
  return (
    <Stack spacing={0.25} sx={{ width: "100%" }}>
      {[...Array.from({ length: items }).keys()].map((item) => (
        <Stack
          key={`notification_skeleton_${item}`}
          spacing={1.5}
          direction="row"
          sx={{
            width: "100%",
            height: 76,
            pt: 1.5,
            pb: 2,
            px: {
              xs: 2,
              sm: 3,
            },
          }}
        >
          <Box>
            <Skeleton variant="circular" width={40} height={40} />
          </Box>
          <Stack spacing={1.5} sx={{ width: "100%" }}>
            <Skeleton variant="rounded" width="80%" height={20} />
            <Skeleton variant="rounded" width="23%" height={16} />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
