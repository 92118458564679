import { Box, Fade, LinearProgress, ThemeProvider } from "@mui/material";
import { useNavigation } from "@remix-run/react";

import { themeV2 } from "~/utils/theme";

export function LinearIndeterminate() {
  const navigation = useNavigation();
  return (
    <ThemeProvider theme={themeV2}>
      <Fade in={navigation.state === "loading"}>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="indeterminate"
            sx={{
              bgcolor: "teal.1",
              "& .MuiLinearProgress-bar": {
                bgcolor: "teal.4",
                animationDuration: "2s",
              },
            }}
          />
        </Box>
      </Fade>
    </ThemeProvider>
  );
}
