import { Box, Stack, Typography } from "@mui/material";
import { Link } from "@remix-run/react";
import React from "react";

import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";

import { UserAvatar } from "../user-avatar";

export function UserInfoCard({ onClick }: { onClick: () => void }) {
  const auth = useAuth();
  if (!auth.wisher && !auth.gifter) return <></>;
  return (
    <Link
      onClick={onClick}
      to={
        auth.wisher
          ? Routes.public.wisher({ username: auth.wisher.handle })
          : (auth.gifter && Routes.public.gifter({ username: auth.gifter.handle })) || "/"
      }
    >
      <Stack
        direction="row"
        spacing={1.5}
        sx={{
          bgcolor: "gray.1",
          px: 3.5,
          py: 1.25,
          borderRadius: 2,
          alignItems: "center",
          boxShadow:
            "0px 1px 1px 0px rgba(38, 103, 255, 0.10), 0px 0px 1px 0px rgba(38, 103, 255, 0.05)",
        }}
      >
        <UserAvatar
          sx={{ width: 52, height: 52 }}
          src={auth?.wisher?.avatarUrl || auth?.gifter?.avatarUrl}
        />
        <Box>
          <Typography variant="h5" component="h5" color="gray.7" className="notranslate">
            {auth?.wisher?.name || auth?.gifter?.name}
          </Typography>
          <Typography variant="b4" color="gray.6" className="notranslate">
            @{auth?.wisher?.handle || auth?.gifter?.handle}
          </Typography>
        </Box>
      </Stack>
    </Link>
  );
}

export function StyledMenuItem({
  to,
  onClick,
  icon,
  text,
  iconColor,
  textColor,
}: {
  to: string;
  onClick: () => void;
  icon: React.ReactElement;
  text: string;
  iconColor?: string;
  textColor?: string;
}) {
  return (
    <Link to={to} onClick={onClick}>
      <Stack
        sx={{
          px: 2,
          py: 1.5,
          alignItems: "center",
          "&:hover": { bgcolor: "gray.2", borderRadius: 2 },
          "& svg": {
            color: iconColor || "neutral.5",
          },
        }}
        direction="row"
        spacing={1.5}
      >
        {icon}
        <Typography variant="b5" color={textColor || "gray.7"}>
          {text}
        </Typography>
      </Stack>
    </Link>
  );
}
