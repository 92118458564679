import { LoadingButton } from "@mui/lab";
import { Box, Link, Stack, Typography } from "@mui/material";

import { CopyLinkButton } from "~/components/copy-link-button";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

export function TestStreamOverlay({ streamUrl }: { streamUrl?: string }) {
  const [sendNewTestGift, sendNewTestGiftState] = useWtFetcher({
    onError: (response) => alert(response.error),
  });

  const [sendSeenTestGift, sendSeenTestGiftState] = useWtFetcher({
    onError: (response) => alert(response.error),
  });

  return (
    <Stack
      spacing={3}
      sx={{
        borderRadius: 1,
        color: "neutral.0",
        bgcolor: "blue.4",
        p: 2,
      }}
    >
      {streamUrl && (
        <>
          <Stack spacing={2}>
            <Typography component="span" variant="h7">
              Your new stream URL.
            </Typography>
            <Typography component="p" variant="b4">
              Please keep this somewhere safe as you will never seen this again. You can always
              generate a new link if you lose access to this one.
            </Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 1,
              bgcolor: "neutral.1",
              p: 2,
            }}
          >
            <Link
              href={streamUrl}
              target="_blank"
              rel="noreferrer noopener"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {streamUrl}
            </Link>

            <CopyLinkButton link={streamUrl} iconStyles={{ color: "#000" }} />
          </Stack>
        </>
      )}
      <Stack spacing={2}>
        <Typography component="span" variant="h7">
          Test Stream Overlay
        </Typography>
        <Typography component="p" variant="b4">
          Once you've created a browser overlay with your stream URL, use these buttons to test the
          stream.
        </Typography>
        {!streamUrl && (
          <Stack>
            <Box
              component="img"
              src="/images/test_stream_overlay.png"
              alt="Test stream overlay"
              width="100%"
              height="100%"
            />
          </Stack>
        )}
      </Stack>
      <Stack
        spacing={1}
        direction={{
          xs: "column",
          sm: "row",
        }}
        sx={{
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <LoadingButton
          variant="contained"
          loading={sendNewTestGiftState.isFetching}
          sx={{
            width: "100%",
            bgcolor: "neutral.6",
            ":hover": {
              bgcolor: "neutral.5",
            },
          }}
          onClick={() => sendNewTestGift.load(Routes.wisher.settings.sendNewTestGift())}
        >
          <span>Send Test Gift</span>
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={sendSeenTestGiftState.isFetching}
          sx={{
            width: "100%",
            bgcolor: "neutral.6",
            ":hover": {
              bgcolor: "neutral.5",
            },
          }}
          onClick={() => sendSeenTestGift.load(Routes.wisher.settings.sendSeenTestGift())}
        >
          <span>Open Test Gift</span>
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
