import { RouteIds, Routes } from "~/constants/routes";
import { useRawFetcher } from "~/hooks/use-raw-fetcher";

import type { NotificationType } from "../types";

export function useNewNotificationsCount({
  onFetched,
  query,
}: {
  query: { type?: NotificationType; excludeType?: NotificationType };
  onFetched: (total: number) => void;
}) {
  const [fetch] = useRawFetcher<{ total: number }>({
    onError: (response) => {
      alert(response.error);
    },
    onSuccess: (data) => {
      onFetched(data.total);
    },
  });

  function fetchNewNotificationsCount() {
    fetch.load(
      Routes.loaders.notification.newNotificationsCount(query),
      RouteIds.loaders.notifications.newCount(),
    );
  }

  return {
    fetchNewNotificationsCount,
  };
}
