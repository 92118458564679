import { Avatar, Box, Link, Stack, Typography } from "@mui/material";
import { Link as RLink } from "@remix-run/react";
import { IconPointFilled } from "@tabler/icons-react";

import { CalendarAddIcon } from "~/components/icons/calendar-add-icon";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useFormatCurrency } from "~/modules/currency";
import { getIntervalLabel } from "~/modules/wishlist/utils/subscription";
import { formatTime } from "~/utils/time";

import { useNotifications } from "../../context/notification-context";
import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.SUBSCRIPTION_CREATED>;
}) {
  const auth = useAuth();

  if (auth.wisher) return <WisherContent notification={notification} />;
  return <GifterContent notification={notification} />;
}

function GifterContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.SUBSCRIPTION_CREATED>;
}) {
  const { toggleNotificationHub } = useNotifications();
  const formatCurrency = useFormatCurrency(notification.data.gifter.currency!);

  return (
    <NotificationContentContainer to={Routes.gifter.subscriptionList()} notification={notification}>
      <Box sx={{ width: 40, height: 40, position: "relative" }}>
        <Avatar
          sx={{
            width: 40,
            height: 40,
          }}
          alt={notification.data.wisher.handle}
          src={notification.data.wisher.avatarUrl}
        />
        <Avatar
          sx={{
            position: "absolute",
            width: 24,
            height: 24,
            bgcolor: "neutral.0",
            boxShadow:
              "0px 1px 1px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05)",
            bottom: -10,
            right: 0,
          }}
        >
          <CalendarAddIcon sx={{ fontSize: 20, color: "teal.4" }} />
        </Avatar>
      </Box>

      <Stack spacing={0.5}>
        <Typography variant="b5">
          <span>Subscribed to </span>
          <Link
            component={RLink}
            to={Routes.public.wisher({ username: notification.data.wisher.handle })}
            underline="none"
            sx={{ zIndex: 1, color: "neutral.7" }}
            onClick={(event) => {
              event.stopPropagation();
              toggleNotificationHub(false);
            }}
          >
            <Typography
              component="span"
              className="notranslate"
              variant="h5"
              sx={{
                position: "relative",
                zIndex: 1,
                ":hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {notification.data.wisher.name}
            </Typography>
          </Link>
          <span>'s wish </span>
          <span>{getIntervalLabel(notification.data.subscription.interval)} </span>
          <IconPointFilled size={8} />
          <span> &quot;{notification.data.subscription.wishItem.name}&quot; </span>
          <span>for </span>
          <span>{formatCurrency(notification.data.subscription.price)}</span>
        </Typography>

        <Typography component="span" variant="b5" sx={{ color: "neutral.5" }}>
          {formatTime(notification.createdAt, "dynamic")}
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}

function WisherContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.SUBSCRIPTION_CREATED>;
}) {
  const { toggleNotificationHub } = useNotifications();
  const formatCurrency = useFormatCurrency(notification.data.wisher.currency);

  return (
    <NotificationContentContainer to={Routes.wisher.subscriptionList()} notification={notification}>
      <Box sx={{ width: 40, height: 40, position: "relative" }}>
        <Avatar
          sx={{
            width: 40,
            height: 40,
          }}
          alt={notification.data.gifter.handle || notification.data.gifter.pseudonym || "Anonymous"}
          src={notification.data.gifter.avatarUrl}
        />
        <Avatar
          sx={{
            position: "absolute",
            width: 24,
            height: 24,
            bgcolor: "neutral.0",
            boxShadow:
              "0px 1px 1px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05)",
            bottom: -10,
            right: 0,
          }}
        >
          <CalendarAddIcon sx={{ fontSize: 20, color: "teal.4" }} />
        </Avatar>
      </Box>

      <Stack spacing={0.5}>
        <Typography>
          {notification.data.gifter.handle ? (
            <Link
              component={RLink}
              to={Routes.public.gifter({ username: notification.data.gifter.handle })}
              underline="none"
              sx={{ zIndex: 1, color: "neutral.7" }}
              onClick={(event) => {
                event.stopPropagation();
                toggleNotificationHub(false);
              }}
            >
              <Typography
                component="span"
                className="notranslate"
                variant="h5"
                sx={{
                  position: "relative",
                  zIndex: 1,
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                @{notification.data.gifter.handle}
              </Typography>
            </Link>
          ) : (
            <Typography component="span" variant="h5" className="notranslate">
              {notification.data.gifter.pseudonym}
            </Typography>
          )}
          <Typography component="span" variant="b5">
            <span> subscribed to your gift </span>
            <span>{getIntervalLabel(notification.data.subscription.interval)} </span>
            <IconPointFilled size={8} />
            <span> &quot;{notification.data.subscription.wishItem.name}&quot; </span>
            <span>for </span>
            <span>{formatCurrency(notification.data.subscription.price)}</span>
          </Typography>
        </Typography>

        <Typography component="span" variant="b5" sx={{ color: "neutral.5" }}>
          {formatTime(notification.createdAt, "dynamic")}
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
