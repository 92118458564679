import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function LoyalFansIcon(props: SvgIconProps) {
  return (
    <SvgIcon version="1.1" x="0px" y="0px" viewBox="0 0 210.1 220" {...props}>
      <path
        fill="currentColor"
        d="M69.3,36.3c8.5-8.5,22.5-8.5,31,0s8.5,22.5,0,31s-22.5,8.5-31,0S60.6,44.8,69.3,36.3z M210.1,6.8L132,103.7
	c-1.2,1.4-2.4,3-3.6,4.4c-5.9,7.7-11.1,16-15.2,24.9c-8.3,17.6-13.1,37.4-13.1,58.3V220h-31v-30.1c0-2.6,0-5.1-0.2-7.7
	c-1-18-5.5-34.8-12.7-50.2c-1.8-3.8-3.8-7.5-5.9-11.3l-0.8-1.4L0.1,36.9l84.8,72l0,0l0,0L210.1,6.8z"
      />
    </SvgIcon>
  );
}
