import { Box, Link, Stack, Typography } from "@mui/material";
import { Link as RLink } from "@remix-run/react";

import { InfoIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";

import { useNotifications } from "../..";
import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.MONTHLY_LEADERBOARD>;
}) {
  const { toggleNotificationHub } = useNotifications();

  return (
    <NotificationContentContainer to={Routes.wisher.settings.main()} notification={notification}>
      <Box sx={{ backgroundColor: "blue.1", p: 0.75, borderRadius: 6, height: 40, width: 40 }}>
        <InfoIcon sx={{ fontSize: 28 }} />
      </Box>
      <Stack sx={{ color: "neutral.7" }}>
        <Typography variant="h5">You now have access to Instant Payouts!</Typography>
        <Typography component="span" variant="b5">
          Receive fund within minutes using the{" "}
          <Link
            component={RLink}
            to={Routes.wisher.balance.account()}
            sx={{ textDecoration: "none" }}
            onClick={() => toggleNotificationHub(false)}
          >
            <Typography
              component="span"
              variant="b5"
              color="blue.4"
              sx={{
                textDecoration: "underline",
                textDecorationColor: "blue.4",
                position: "relative",
                zIndex: 2,
              }}
            >
              Instant Payouts dashboard
            </Typography>
          </Link>
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
