import { z } from "zod";

export const HandleSchema = z
  .string()
  .trim()
  .nonempty("Wishlist URL is required")
  .max(24, "Wishlist URL must be less than 25 characters")
  .regex(/^[\w-]+$/, "Your wishlist URL can only contain letters, numbers, '_', or '-'");

export const WisherSetupSchema = z.object({
  handle: HandleSchema,
  aliasName: z.string().trim().nonempty("Display name is required"),
  country: z.string().trim().nonempty("Country is required"),
});
