import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Twitter from "@mui/icons-material/Twitter";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Typography,
} from "@mui/material";
import { useSearchParams } from "@remix-run/react";
import { useState } from "react";

import { Dialog } from "~/components/dialog";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

import { LinkTwitterForm } from "./twitter-settings/link-twitter-form";
import { TwitterSettingsForm } from "./twitter-settings/twitter-settings-form";

const steps = ["Link Twitter", "Tweet Settings"];

export function TwitterIntegrationMenuItem() {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const setDialog = searchParams.get("setDialog");
  const setTwitterSetupStep = searchParams.get("setTwitterSetupStep");

  const [shouldOpenTwitterDialog, setShouldOpenTwitterDialog] = useState(
    setDialog === "autoTweetsStepper",
  );
  const [enableAutoTweet, setEnableAutoTweet] = useState(
    auth.wisher?.twitterSettings?.autoTweet ?? false,
  );

  const [unlink, unlinkState] = useWtFetcher({
    onError: (response) => alert(response.error),
    onSuccess: () => setShouldOpenTwitterDialog(false),
  });

  const [toggleAutoTweets] = useWtFetcher({
    onError: (response) => {
      alert(response.error);
      setEnableAutoTweet(!enableAutoTweet);
    },
  });

  const activeStep = Number(setTwitterSetupStep ?? 0);

  function handleUnlink() {
    unlink.submit({}, { method: "patch", action: Routes.actions.settings.unlinkTwitter() });
  }

  function handleToggleSwitch(enabled: boolean) {
    setEnableAutoTweet(enabled);
    toggleAutoTweets.submit(
      {},
      {
        method: "patch",
        action: enabled
          ? Routes.actions.settings.enableAutoTweet()
          : Routes.actions.settings.disableAutoTweet(),
      },
    );
  }

  return (
    <>
      <MenuItem
        sx={{ py: 1 }}
        onMouseDown={(event) => {
          if (event.nativeEvent.target instanceof HTMLInputElement) {
            event.stopPropagation();
          } else {
            setShouldOpenTwitterDialog(true);
          }
        }}
      >
        {auth.wisher?.twitterUsername ? (
          <Stack
            direction="row"
            sx={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}
          >
            <Stack
              direction={{
                xs: "column",
                sm: "row",
              }}
              sx={{
                width: "100%",
                alignItems: {
                  xs: "flex-start",
                  sm: "center",
                },
                justifyContent: "space-between",
              }}
            >
              <Typography component="span" variant="b5">
                Twitter:
                <Typography component="span" variant="h5">
                  {" "}
                  Linked
                </Typography>
              </Typography>
              <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Typography component="span" variant="b5">
                  AutoTweets:{" "}
                  <Typography component="span" variant="b5" sx={{ fontWeight: "bold" }}>
                    {enableAutoTweet ? <span>Enabled</span> : <span>Disabled</span>}
                  </Typography>
                </Typography>
                <Switch
                  checked={enableAutoTweet}
                  onChange={(event) => {
                    event.stopPropagation();
                    handleToggleSwitch(event.target.checked);
                  }}
                />
              </Stack>
            </Stack>
            <ListItemIcon>
              <ArrowRight />
            </ListItemIcon>
          </Stack>
        ) : (
          <>
            <ListItemText>Auto Tweet</ListItemText>
            <Button variant="contained" startIcon={<Twitter />} sx={{ py: 1, px: 2 }}>
              Set up AutoTweet
            </Button>
          </>
        )}
      </MenuItem>
      <Dialog.Root
        maxWidth="sm"
        fullWidth
        variant="normal"
        open={shouldOpenTwitterDialog}
        onClose={() => setShouldOpenTwitterDialog(false)}
      >
        <Dialog.Title sx={{ textAlign: "center" }}>Twitter Integration</Dialog.Title>
        <Dialog.Content>
          {!auth.wisher?.twitterUsername || setDialog === "autoTweetsStepper" ? (
            <Stack spacing={3}>
              <Box sx={{ width: "100%" }}>
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  sx={{
                    "& .MuiStepIcon-text": { fill: "#fff" },
                  }}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              {activeStep === 0 && (
                <LinkTwitterForm onFinished={() => setShouldOpenTwitterDialog(false)} />
              )}
              {activeStep === 1 && (
                <Stack spacing={3}>
                  <Typography
                    component="span"
                    variant="h8"
                    sx={{
                      textAlign: "center",
                      fontWeight: "500",
                    }}
                  >
                    Step 2: Tweet Settings
                  </Typography>
                  <Typography
                    component="span"
                    variant="h7"
                    sx={{
                      textAlign: "center",
                      fontWeight: "normal",
                    }}
                  >
                    Configure your tweet settings
                  </Typography>
                  <TwitterSettingsForm
                    twitterSettings={auth.wisher?.twitterSettings}
                    twitterName={auth.wisher?.twitterName}
                    twitterUsername={auth.wisher?.twitterUsername}
                    onFinished={() => {
                      setSearchParams("");
                      setShouldOpenTwitterDialog(false);
                      setEnableAutoTweet(true);
                    }}
                  />
                </Stack>
              )}
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Stack direction="row" sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Stack direction="row" sx={{ alignItems: "center" }} spacing={1}>
                  <AccountCircle sx={{ color: "neutral.3", fontSize: 60 }} />
                  <Stack>
                    <Typography component="span" variant="b5">
                      {auth.wisher.twitterName}
                    </Typography>
                  </Stack>
                </Stack>
                <LoadingButton
                  variant="contained"
                  color="error"
                  loading={unlinkState.isFetching}
                  onClick={handleUnlink}
                >
                  <span>Unlink</span>
                </LoadingButton>
              </Stack>

              <Divider />

              <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Typography component="span" variant="b5">
                  AutoTweets:{" "}
                  <Typography component="span" variant="b5" sx={{ fontWeight: "bold" }}>
                    {enableAutoTweet ? <span>Enabled</span> : <span>Disabled</span>}
                  </Typography>
                </Typography>
                <Switch
                  checked={enableAutoTweet}
                  onChange={(event) => handleToggleSwitch(event.target.checked)}
                />
              </Stack>
              {enableAutoTweet && (
                <TwitterSettingsForm
                  twitterName={auth.wisher.twitterName}
                  twitterUsername={auth.wisher.twitterUsername}
                  twitterSettings={auth.wisher.twitterSettings}
                  onFinished={() => setShouldOpenTwitterDialog(false)}
                />
              )}
            </Stack>
          )}
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
