import { z } from "zod";

import { TAG_VALIDATION_REGEX } from "~/constants/regex";

export const GifterHandleSchema = z
  .string()
  .trim()
  .nonempty("Gifter username required")
  .max(24, "Gifter username must be less than 25 characters")
  .regex(/^[\w-]+$/, "Your gifter username can only contain letters, numbers, '_', or '-'");

export const GifterSetupSchema = z.object({
  handle: GifterHandleSchema,
  name: z.string().trim().optional(),
  currency: z.string(),
  isPublic: z.preprocess((v) => v === true || v === "true", z.boolean()).default(false),
});

export const GifterProfileUpdateSchema = z.object({
  id: z.string(),
  name: z.string().trim(),
  handle: GifterHandleSchema,
  currentHandle: GifterHandleSchema,
  isPublic: z.preprocess((v) => v === true || v === "true", z.boolean()).default(false),
  tags: z
    .preprocess(
      (v) => {
        if (typeof v === "string" && v.length > 0) {
          return v.split(",");
        }
        if (Array.isArray(v)) {
          return v;
        }
        return [];
      },
      z.array(
        z.string().max(40).regex(TAG_VALIDATION_REGEX, {
          message: "Tag must contain only letters, digits, and spaces",
        }),
      ),
    )
    .refine((items) => new Set(items).size === items.length, {
      message: "Duplicate tag",
    })
    .default([]),
});

export const DeleteGifterAccountSchema = z.object({
  password: z
    .string()
    .trim()
    .nonempty("Password is required.")
    .min(8, "Password must be at least 8 characters"),
  phrase: z
    .string()
    .nonempty("Phrase is required.")
    .refine((value) => value === "permanently delete", {
      message: "Phrase must match 'permanently delete'",
    }),
  gifterId: z.string(),
});

export const ChangeStatementDescriptorSchema = z.object({
  statementDescriptorOption: z.string(),
});
