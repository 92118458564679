import { zodResolver } from "@hookform/resolvers/zod";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { Dialog } from "~/components/dialog";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import { getCurrencyList, usePreferredCurrency } from "~/modules/currency";

import { UpdateDisplayCurrencySchema } from "../schema";

export function DisplayCurrencyMenuItem({ wisherId }: { wisherId?: string }) {
  const [shouldOpenDisplayCurrencyDialog, setShouldOpenDisplayCurrencyDialog] = useState(false);
  const currency = usePreferredCurrency();

  const listCurrencies = getCurrencyList(undefined, true);

  const [updateDisplayCurrency, updateDisplayCurrencyState] = useWtFetcher({
    onError: (response) => alert(response.error),
    onSuccess: () => setShouldOpenDisplayCurrencyDialog(false),
  });

  const form = useForm<z.infer<typeof UpdateDisplayCurrencySchema>>({
    resolver: zodResolver(UpdateDisplayCurrencySchema),
    defaultValues: {
      displayCurrency: currency,
      wisherId,
    },
  });

  function handleUpdateDisplayCurrency(data: z.infer<typeof UpdateDisplayCurrencySchema>) {
    updateDisplayCurrency.submit(data, {
      method: "patch",
      action: Routes.actions.settings.updateDisplayCurrency(),
    });
  }

  return (
    <>
      <MenuItem sx={{ py: 1 }} onClick={() => setShouldOpenDisplayCurrencyDialog(true)}>
        <ListItemText>Display Currency</ListItemText>
        <Typography component="span" variant="b4" color="text.secondary">
          {currency}
        </Typography>
        <ListItemIcon>
          <ArrowRight />
        </ListItemIcon>
      </MenuItem>
      <Dialog.Root
        maxWidth="xs"
        fullWidth
        variant="normal"
        open={shouldOpenDisplayCurrencyDialog}
        onClose={() => setShouldOpenDisplayCurrencyDialog(false)}
      >
        <Dialog.Title sx={{ textAlign: "center" }}>Update Display Currency </Dialog.Title>
        <Dialog.Content>
          <Stack
            spacing={5}
            component="form"
            onSubmit={form.handleSubmit(handleUpdateDisplayCurrency)}
          >
            <Controller
              name="displayCurrency"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <FormLabel id="display-currency-select">Display Currency</FormLabel>
                    <Select {...field} labelId="display-currency-select">
                      {listCurrencies.map((currency) => (
                        <MenuItem key={currency.code} value={currency.code}>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="b5" sx={{ color: "green.5" }}>
                              {currency.symbol}
                            </Typography>
                            <Typography variant="b5">{currency.name}</Typography>
                            <Typography variant="b5" sx={{ color: "neutral.4" }}>
                              {currency.code}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
            />
            <LoadingButton
              type="submit"
              variant="contained"
              loading={updateDisplayCurrencyState.isFetching}
              disabled={updateDisplayCurrencyState.isFetching}
            >
              <span>Save</span>
            </LoadingButton>
          </Stack>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
