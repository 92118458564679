import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { InfoSquareTwoTonesIcon } from "~/components/icons/info-square-two-tones-icon";
import { FIRST_GIFT_EXPECTATION_URL } from "~/constants/platform";
import { NotificationContentContainer } from "~/modules/notifications/components/notification-content-container";
import type { NotificationType, NotificationV2 } from "~/modules/notifications/types";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.FIRST_GIFT>;
}) {
  return (
    <NotificationContentContainer
      notification={notification}
      to={FIRST_GIFT_EXPECTATION_URL}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        "& .MuiLink-root": {
          ml: "0 !important",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "teal.0",
          borderRadius: 10,
          height: 40,
          minWidth: 40,
        }}
      >
        <InfoSquareTwoTonesIcon sx={{ fontSize: 22 }} />
      </Box>
      <Stack sx={{ color: "neutral.7" }}>
        <Typography variant="h5">You received your first gift after activation! 🎉</Typography>
        <Typography variant="b5">
          Read this for a guide on what to expect and what you can do next ▶️
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
