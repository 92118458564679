import { useRouteLoaderData } from "@remix-run/react";

import { RouteIds } from "~/constants/routes";
import type { Referee, Referral, ReferralActivity, ReferralCode } from "~/modules/referrals/types";

import type { WisherReferralsStats } from "../types";

export function useWisherReferralCodes() {
  const data = useRouteLoaderData<{ codes: ReferralCode[] }>(RouteIds.wisher.referrals.layout());
  return data?.codes ?? [];
}

export function useWisherReferralActivities() {
  const data = useRouteLoaderData<{ activities: ReferralActivity[] }>(
    RouteIds.wisher.referrals.layout(),
  );
  return data?.activities ?? [];
}

export function useWisherReferralTopActiveReferees() {
  const data = useRouteLoaderData<{ topActiveReferees: Referee[] }>(
    RouteIds.wisher.referrals.layout(),
  );
  return data?.topActiveReferees ?? [];
}

export function useWisherReferrals() {
  const data = useRouteLoaderData<{ referrals: { data: Referral[]; total: number } }>(
    RouteIds.wisher.referrals.list(),
  );
  return {
    data: data?.referrals?.data ?? [],
    total: data?.referrals?.total ?? 0,
  };
}

export function useWisherReferralStats() {
  const data = useRouteLoaderData<{ stats: WisherReferralsStats }>(
    RouteIds.wisher.referrals.list(),
  );
  return (
    data?.stats ?? {
      yourEarnings: { amount: 0, currency: "USD" },
      totalSignups: 0,
      activeReferees: 0,
    }
  );
}
