import { Stack } from "@mui/material";

import { CodesSection } from "../components/referrals/codes-section";
import { CreateReferralCodeDialog } from "../components/referrals/create-referral-code-dialog";
import { DurationRatesSection } from "../components/referrals/duration-rates-section";
import { ReferralsSection } from "../components/referrals/referrals-section";
import { StatsSection } from "../components/referrals/stats-section";

export function ReferralsIndexPage() {
  return (
    <Stack spacing={4}>
      <StatsSection />
      <DurationRatesSection />
      <CodesSection />
      <ReferralsSection />
      <CreateReferralCodeDialog />
    </Stack>
  );
}
