import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { BankCardExportIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";
import { useFormatCurrency } from "~/modules/currency";
import { NotificationContentContainer } from "~/modules/notifications/components/notification-content-container";
import type { NotificationType, NotificationV2 } from "~/modules/notifications/types";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.PAYOUT_AVAILABLE_TO_WITHDRAW>;
}) {
  const formatCurrency = useFormatCurrency(notification.data.total.currency);

  return (
    <NotificationContentContainer
      notification={notification}
      to={Routes.wisher.paymentDashboard.main()}
      rel="noopener noreferrer"
      sx={{
        "& .MuiLink-root": {
          ml: "0 !important",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "green.1",
          borderRadius: 6,
          height: 40,
          minWidth: 40,
          color: "green.5",
        }}
      >
        <BankCardExportIcon sx={{ fontSize: 28 }} />
      </Box>
      <Stack sx={{ color: "neutral.7" }}>
        <Typography variant="h5">
          {formatCurrency(notification.data.total.amount)} is now available to withdraw 👋
        </Typography>
        <Typography component="span" variant="b5">
          {formatCurrency(notification.data.total.amount)} has settled into your available balance.
          You can now initiate a payout with this amount.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
