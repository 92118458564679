import { zodResolver } from "@hookform/resolvers/zod";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { LoadingButton } from "@mui/lab";
import { Button, InputAdornment, ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { Dialog } from "~/components/dialog";
import { TextField } from "~/components/form/text-field";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

import { DeleteWisherSchema } from "../schema";

export function DeleteWisherMenuItem({ wisherId }: { wisherId?: string }) {
  const [shouldOpenDeleteAccountDialog, setShouldOpenDeleteAccountDialog] = useState(false);
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const [deleteAccount, deleteAccountState] = useWtFetcher({
    onError: (response) => alert(response.error),
    onSuccess: () => setShouldOpenDeleteAccountDialog(false),
  });

  const form = useForm<z.infer<typeof DeleteWisherSchema>>({
    resolver: zodResolver(DeleteWisherSchema),
    defaultValues: {
      password: "",
      phrase: "",
      wisherId,
    },
  });

  function handleDeleteAccount(payload: z.infer<typeof DeleteWisherSchema>) {
    deleteAccount.submit(payload, {
      method: "delete",
      action: Routes.actions.settings.deleteWisher(),
    });
  }

  return (
    <>
      <MenuItem sx={{ py: 1 }} onClick={() => setShouldOpenDeleteAccountDialog(true)}>
        <ListItemText>Delete Account</ListItemText>
        <ListItemIcon>
          <ArrowRight />
        </ListItemIcon>
      </MenuItem>
      <Dialog.Root
        maxWidth="xs"
        fullWidth
        variant="normal"
        open={shouldOpenDeleteAccountDialog}
        onClose={() => setShouldOpenDeleteAccountDialog(false)}
      >
        <Dialog.Title sx={{ textAlign: "center" }}>Delete Account</Dialog.Title>
        <Dialog.Content>
          <Stack spacing={5} component="form" onSubmit={form.handleSubmit(handleDeleteAccount)}>
            <Stack spacing={2}>
              <Controller
                name="password"
                control={form.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextField.Root error={!!error} fullWidth>
                      <TextField.Label>Password</TextField.Label>
                      <TextField.Input
                        {...field}
                        type={shouldShowPassword ? "text" : "password"}
                        placeholder="Type your password"
                        endAdornment={
                          <InputAdornment position="end">
                            <Button
                              variant="text"
                              size="small"
                              disableTouchRipple
                              sx={{
                                typography: "b4",
                                color: "neutral.6",
                                ":hover": {
                                  bgcolor: "neutral.1",
                                },
                              }}
                              onClick={() => setShouldShowPassword(!shouldShowPassword)}
                            >
                              {shouldShowPassword ? <span>Hide</span> : <span>Show</span>}
                            </Button>
                          </InputAdornment>
                        }
                      />
                      {error && <TextField.Helper error>{error.message}</TextField.Helper>}
                    </TextField.Root>
                  );
                }}
              />
              <Controller
                name="phrase"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <TextField.Root error={!!error} fullWidth>
                    <TextField.Label>Type permanently delete to continue</TextField.Label>
                    <TextField.Input {...field} placeholder="Type permanently delete to continue" />
                    {error && <TextField.Helper error>{error.message}</TextField.Helper>}
                  </TextField.Root>
                )}
              />
            </Stack>
            <LoadingButton
              type="submit"
              variant="contained"
              color="error"
              loading={deleteAccountState.isFetching}
              disabled={deleteAccountState.isFetching}
            >
              <span>Delete</span>
            </LoadingButton>
          </Stack>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
