import { MenuItem, Stack, Typography } from "@mui/material";

import type { CurrencyData, PreferredCurrency } from "~/types";

type CurrenciesMenuItemsProps = {
  selectedCurrency: PreferredCurrency;
  currencies: CurrencyData<PreferredCurrency>[];
  onClick?: (currency: PreferredCurrency) => void;
};

export function CurrenciesMenuItems({
  currencies,
  selectedCurrency,
  onClick,
}: CurrenciesMenuItemsProps) {
  return (
    <>
      {currencies?.map((currency) => (
        <MenuItem
          key={currency.code}
          onClick={() => onClick?.(currency.code)}
          selected={currency.code === selectedCurrency}
          value={currency.code}
          sx={{ px: 1.5, py: 1.5 }}
        >
          <Stack direction="row" spacing={2}>
            <Typography variant="b5" color="green.4" sx={{ minWidth: 40 }}>
              {currency.symbol}
            </Typography>
            <Typography variant="b5" color="gray.7">
              {currency.name}
            </Typography>
            <Typography variant="b5" color="gray.5">
              {currency.code !== "noConversion" && currency.code}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </>
  );
}
