import ArrowRight from "@mui/icons-material/ArrowRight";
import { Divider, ListItemIcon, ListItemText, MenuItem, Paper, ThemeProvider } from "@mui/material";
import { Box, Container } from "@mui/system";
import { Link, useLoaderData, useNavigate } from "@remix-run/react";

import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { DeleteWisherMenuItem } from "~/modules/settings/components/delete-wisher-menu-item";
import { DisplayCurrencyMenuItem } from "~/modules/settings/components/display-currency-menu-item";
import { ResetPasswordMenuItem } from "~/modules/settings/components/reset-password-menu-item";
import { StreamManagementMenuItem } from "~/modules/settings/components/stream-management-menu-item";
import { SurpriseGiftSettingsMenuItem } from "~/modules/settings/components/surprise-gift-settings-menu-item";
import { TwitterIntegrationMenuItem } from "~/modules/settings/components/twitter-integration-menu-item";
import { UpdateDetailedGiftNotificationMenuItem } from "~/modules/settings/components/update-detailed-gift-notification-menu-item";
import { UpdateEmailMenuItem } from "~/modules/settings/components/update-email-menu-item";
import { UpdatePublicizeStatsMenuItem } from "~/modules/settings/components/update-publicize-stats-menu-item";
import type { loader } from "~/routes/wisher.settings";
import { themeV2 } from "~/utils/theme";

export function SettingsPage() {
  const navigate = useNavigate();
  const auth = useAuth();
  const data = useLoaderData<typeof loader>();

  return (
    <ThemeProvider theme={themeV2}>
      <Box component="main" sx={{ minHeight: "calc(100vh - 72px)", py: "7vw" }}>
        <Container maxWidth="md">
          <Paper>
            <MenuItem component={Link} to={Routes.wisher.paymentDashboard.main()} sx={{ py: 1 }}>
              <ListItemText sx={{ color: "teal.4" }}>Payment Dashboard</ListItemText>
              <ListItemIcon>
                <ArrowRight color="primary" />
              </ListItemIcon>
            </MenuItem>

            <Divider sx={{ m: "0 !important" }} />
            {/* {data.instantPayout.enabled && (
              <MenuItem component={Link} to={Routes.wisher.balance.account()} sx={{ py: 1 }}>
                <ListItemText>Instant Payout</ListItemText>
                <ListItemIcon>
                  <ArrowRight />
                </ListItemIcon>
              </MenuItem>
            )} */}

            {data.totalReferralCodes > 0 && (
              <>
                <Divider sx={{ m: "0 !important" }} />
                <MenuItem
                  sx={{ py: 1 }}
                  onClick={() => {
                    navigate(Routes.wisher.referrals.main());
                  }}
                >
                  <ListItemText>Referral Dashboard</ListItemText>
                  <ListItemIcon>
                    <ArrowRight />
                  </ListItemIcon>
                </MenuItem>
              </>
            )}

            <Divider sx={{ m: "0 !important" }} />

            <UpdateEmailMenuItem email={auth.user?.email} />

            <Divider sx={{ m: "0 !important" }} />

            <ResetPasswordMenuItem />

            <Divider sx={{ m: "0 !important" }} />

            <DisplayCurrencyMenuItem wisherId={auth.wisher?.id} />

            <Divider sx={{ m: "0 !important" }} />

            <TwitterIntegrationMenuItem />

            <Divider sx={{ m: "0 !important" }} />

            <SurpriseGiftSettingsMenuItem wishlistId={auth.wisher?.wishlistId} />

            <Divider sx={{ m: "0 !important" }} />

            <StreamManagementMenuItem />

            <Divider sx={{ m: "0 !important" }} />

            <UpdatePublicizeStatsMenuItem publicizeStats={auth.user?.publicizeStats} />

            <Divider sx={{ m: "0 !important" }} />

            <UpdateDetailedGiftNotificationMenuItem
              detailedGiftNotification={auth.user?.detailedGiftNotification}
            />

            <Divider sx={{ m: "0 !important" }} />

            <DeleteWisherMenuItem wisherId={auth.user?.id} />
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
