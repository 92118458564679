import { z } from "zod";

export const UpdateUserEmailSchema = z.object({
  email: z.string().trim().nonempty("Email is required.").email("Enter a valid e-mail address"),
  password: z
    .string()
    .trim()
    .nonempty("Password is required.")
    .min(8, "Password must be at least 8 characters"),
});
