import { Link, Stack, Toolbar } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Link as RLink } from "@remix-run/react";

import { Routes } from "~/constants/routes";
import { SearchBarMobile } from "~/modules/search/components/search-bar-mobile";
import type { Auth } from "~/types";
import { themeV2 } from "~/utils/theme";

import { HeaderLogoMobile } from "../header-logo";
import { AccountNavMenuTablet } from "./account-nav-menu.tablet";
import { CurrenciesMenu } from "./currencies-menu";
import { HeaderAppBar } from "./header-app-bar";
import {
  AuthActions,
  CartLink,
  HelpCenterLink,
  LeaderboardLinkMobile,
  NotificationLinkTablet,
  OrderHistoryLink,
  WishTracker,
} from "./header-links";

function WisherMenu() {
  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
      <LeaderboardLinkMobile />
      <WishTracker />
      <NotificationLinkTablet />
      <AccountNavMenuTablet />
    </Stack>
  );
}

function GifterMenu() {
  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
      <LeaderboardLinkMobile />
      <CartLink />
      <OrderHistoryLink />
      <NotificationLinkTablet />
      <AccountNavMenuTablet />
    </Stack>
  );
}

function GuestMenu() {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      <HelpCenterLink />
      <LeaderboardLinkMobile />
      <CurrenciesMenu />
      <CartLink />
      <Stack direction="row" spacing={1}>
        <AuthActions />
      </Stack>
    </Stack>
  );
}

export function TabletGeneralHeader({ auth }: { auth: Auth }) {
  let menu = <GuestMenu />;
  if (auth.wisher) {
    menu = <WisherMenu />;
  }
  if (auth.gifter) {
    menu = <GifterMenu />;
  }

  return (
    <ThemeProvider theme={themeV2}>
      <HeaderAppBar screenSize="tablet">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Stack sx={{ alignItems: "flex-start" }}>
            <Link component={RLink} to={Routes.landing.home()}>
              <HeaderLogoMobile />
            </Link>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            sx={{ flex: 1, alignItems: "center", justifyContent: "flex-end" }}
          >
            {auth.user?.featureFlags.includes("search") && <SearchBarMobile />}
            {menu}
          </Stack>
        </Toolbar>
      </HeaderAppBar>
    </ThemeProvider>
  );
}
