import { Avatar, Box, Grid, Link, Stack, Typography } from "@mui/material";
import type { SerializeFrom } from "@remix-run/node";
import { Link as RLink, useSearchParams } from "@remix-run/react";
import { IconArrowRight } from "@tabler/icons-react";
import DOMPurify from "dompurify";
import { SwiperSlide } from "swiper/react";

import { LinearIndeterminate } from "~/components/linear-indeterminate";
import { DEFAULT_WISHER_PROFILE_IMAGE_PATH } from "~/constants/default-images";
import { Routes } from "~/constants/routes";
import { useFormatCurrency } from "~/modules/currency";
import { buildImageUrl } from "~/utils/helpers";

import type { SearchedGifter, SearchedWish, SearchedWisher, SearchResult } from "../types";
import { SearchCarousel } from "./search-carousel";
import { SearchSuggestedSkeleton } from "./skeleton/search-suggested-skeleton";

export function SearchBarResults({
  data,
  queryString,
  loading = false,
  onClickShowAll,
}: {
  data?: SearchResult;
  queryString: string;
  loading?: boolean;
  onClickShowAll?: () => void;
}) {
  const [searchParams] = useSearchParams();

  function getSearchParams() {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("queryString", queryString);
    newSearchParams.set("page", "1");
    return newSearchParams.toString();
  }

  return (
    <Box sx={{ maxHeight: "80vh", overflow: "auto" }}>
      <LinearIndeterminate />
      <Stack spacing={3} sx={{ pt: 1.5, pb: 2.5, px: 2.5 }}>
        <Link
          component={RLink}
          to={Routes.public.search.general({
            queryParams: getSearchParams(),
          })}
          underline="none"
          onClick={onClickShowAll}
        >
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 1,
              p: 1,
              color: "neutral.5",
              transition: "background-color 0.3s ease-out",
              ":hover": {
                backgroundColor: "neutral.2",
                color: "neutral.7",
              },
            }}
          >
            <Typography component="span" variant="b5">
              Show all results for{" "}
              <Typography component="span" variant="h5">{`"${queryString}"`}</Typography>{" "}
            </Typography>
            <IconArrowRight />
          </Stack>
        </Link>

        {data && !loading ? (
          <Stack spacing={4} px={1}>
            {/* Wishers */}
            {data.wisher && data.wisher.wishers.length > 0 && (
              <SearchCarousel title="Wishers" totalItems={data.wisher.totalResults}>
                {data.wisher.wishers.map((wisher) => (
                  <SwiperSlide key={wisher.id} style={{ width: 120 }}>
                    <WisherItem wisher={wisher} />
                  </SwiperSlide>
                ))}
              </SearchCarousel>
            )}

            {/* Gifters */}
            {data.gifter && data.gifter.gifters.length > 0 && (
              <SearchCarousel title="Gifters" totalItems={data.gifter.totalResults}>
                {data.gifter.gifters.map((gifter) => (
                  <SwiperSlide key={gifter.id} style={{ width: 120 }}>
                    <GifterItem gifter={gifter} />
                  </SwiperSlide>
                ))}
              </SearchCarousel>
            )}

            {/* Wishes */}
            {data.wish && data.wish.wishes.length > 0 && (
              <Stack spacing={2}>
                <Typography
                  component="span"
                  variant="b4"
                  sx={{
                    fontWeight: 600,
                    color: "neutral.5",
                    letterSpacing: "1.2px",
                    lineHeight: 2.5,
                  }}
                >
                  WISHES
                </Typography>
                <Stack>
                  <Grid container spacing={1.5}>
                    {data.wish.wishes.slice(0, 4).map((wish) => (
                      <Grid key={wish.id} item xs={6}>
                        <WishItem wish={wish} />
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Stack>
            )}
          </Stack>
        ) : (
          <SearchSuggestedSkeleton />
        )}
      </Stack>
    </Box>
  );
}

function WisherItem({ wisher }: { wisher: SearchedWisher }) {
  const defaultProfileImage = buildImageUrl(DEFAULT_WISHER_PROFILE_IMAGE_PATH);

  return (
    <Link
      component={RLink}
      to={Routes.public.wisher({ username: wisher.handle.toLowerCase() })}
      underline="none"
      sx={{ color: "neutral.7" }}
    >
      <Stack
        key={wisher.id}
        spacing={1}
        sx={{
          bgcolor: "red",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
          transition: "color 0.3s ease-out",
          ":hover": {
            color: "teal.4",
          },
        }}
      >
        <Avatar
          alt={wisher.name}
          src={wisher.avatarUrl ?? defaultProfileImage}
          sx={{ width: 64, height: 64 }}
        />
        <Typography
          className="notranslate"
          component="span"
          variant="b4"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
            width: 104,
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(`@${wisher.highlight?.handleAnalyzed ?? wisher.handle}`),
          }}
        />
      </Stack>
    </Link>
  );
}

function GifterItem({ gifter }: { gifter: SerializeFrom<SearchedGifter> }) {
  return (
    <Link
      component={RLink}
      to={Routes.public.gifter({ username: gifter.handle.toLowerCase() })}
      underline="none"
      sx={{ color: "neutral.7" }}
    >
      <Stack
        key={gifter.id}
        spacing={1}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
          transition: "color 0.3s ease-out",
          ":hover": {
            color: "teal.4",
          },
        }}
      >
        <Box
          sx={{
            width: 64,
            height: 64,
            borderRadius: "100%",
            background: "#0277bd",
            color: "#b3e5fc",
            display: "grid",
            placeContent: "center",
            fontSize: 32,
            textTransform: "uppercase",
          }}
        >
          {gifter.handle[0]}
        </Box>
        <Typography
          className="notranslate"
          component="span"
          variant="b4"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
            width: 104,
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(`@${gifter.highlight?.handleAnalyzed ?? gifter.handle}`),
          }}
        />
      </Stack>
    </Link>
  );
}

function WishItem({ wish }: { wish: SearchedWish }) {
  const formatCurrency = useFormatCurrency(wish.price.currency);
  return (
    <Link
      component={RLink}
      to={Routes.public.wish({
        username: wish.wisherHandle.toLowerCase(),
        wishId: wish.id,
      })}
      underline="none"
      sx={{ color: "neutral.7" }}
    >
      <Stack
        direction="row"
        spacing={1.5}
        sx={{
          p: 0.5,
          borderRadius: 2,
          bgcolor: "neutral.0",
          transition: "background-color 0.3s ease-out",
          ":hover": {
            bgcolor: "neutral.2",
          },
        }}
      >
        <Stack>
          <Box
            component="img"
            src={wish.image}
            alt={wish.name}
            sx={{ width: 72, height: 72, objectFit: "cover", borderRadius: 1 }}
          />
        </Stack>
        <Stack
          sx={{
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <Typography
            component="p"
            variant="b4"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {wish.name}
          </Typography>
          <Typography
            component="span"
            variant="b5"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {formatCurrency(wish.price.amount)}
          </Typography>
        </Stack>
      </Stack>
    </Link>
  );
}
