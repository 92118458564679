import { Avatar, Box, Link, Stack, Typography } from "@mui/material";
import { Link as RLink } from "@remix-run/react";

import { MoneyCanceledIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";
import { CartRequestStatus } from "~/types";
import { formatTime } from "~/utils/time";

import { useNotifications } from "../../context/notification-context";
import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.REQUEST_SENT>;
}) {
  const { toggleNotificationHub } = useNotifications();
  const urlSearchParams = new URLSearchParams({
    status: CartRequestStatus.CANCELED,
  });

  return (
    <NotificationContentContainer
      to={`${Routes.gifter.cartRequestList()}?${urlSearchParams.toString()}`}
      notification={notification}
    >
      <Box sx={{ width: 40, height: 40, position: "relative" }}>
        <Avatar
          sx={{
            width: 40,
            height: 40,
          }}
          alt={notification.data.wisher.handle}
          src={notification.data.wisher.avatarUrl}
        />
        <Avatar
          sx={{
            position: "absolute",
            width: 24,
            height: 24,
            bgcolor: "neutral.0",
            boxShadow:
              "0px 1px 1px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05)",
            bottom: -10,
            right: 0,
          }}
        >
          <MoneyCanceledIcon sx={{ fontSize: 20, color: "teal.4" }} />
        </Avatar>
      </Box>

      <Stack spacing={0.5}>
        <Stack direction="row" spacing={0.5}>
          <Link
            component={RLink}
            to={Routes.public.wisher({ username: notification.data.wisher.handle })}
            underline="none"
            sx={{ zIndex: 1, color: "neutral.7" }}
            onClick={() => toggleNotificationHub(false)}
          >
            <Typography
              className="notranslate"
              component="span"
              variant="h5"
              sx={{
                ":hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {notification.data.wisher.name}
            </Typography>
          </Link>
          <Typography component="span" variant="b5">
            <span> canceled their request.</span>
          </Typography>
        </Stack>

        <Typography component="span" variant="b5" sx={{ color: "neutral.5" }}>
          {formatTime(notification.createdAt, "dynamic")}
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
