import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function FanslyIcon(props: SvgIconProps) {
  return (
    <SvgIcon version="1.1" x="0px" y="0px" viewBox="0 0 33.7 28" {...props}>
      <path
        fill="currentColor"
        d="M8.3,0.5c-1.9,0.1-3.8,0.7-5.3,2C1.4,3.9,0.6,6.1,0.4,8.2v0.7c0.1,1.8,0.9,3.6,2.2,4.9
	c3.8,3.9,7.7,7.8,11.6,11.6c0.7,0.7,1.4,1.5,2.3,2h0.7c1-0.3,1.6-1.2,2.3-1.9c2.9-2.9,5.8-5.7,8.6-8.7l-8.4-8.4
	c0.9-0.9,1.9-1.9,2.8-2.8c0.8-0.8,1.9-1.2,3-1.1c2.2,0,4.1,2.3,3.7,4.5c-0.2,1.9-2,3-3.2,4.3l2.8,2.7c0.9-0.8,1.8-1.7,2.6-2.6
	c2.2-2.6,2.4-6.8,0.3-9.6c-1.3-2-3.6-3.2-5.9-3.4h-1.2C23,0.7,21.3,1.4,20,2.6C16.1,6.4,12.3,10.2,8.4,14c-0.9-0.9-1.8-1.8-2.7-2.7
	C4.8,10.4,4.3,9,4.5,7.7C4.9,5.7,7,4.2,9,4.5c1.9,0.2,3,2.1,4.4,3.2l2.8-2.7C14.4,2.8,12,0.7,9.1,0.5H8.3z M16.9,12.9
	c1.9,0,3.7,1.5,3.9,3.4c0.4,2.4-1.9,4.7-4.3,4.4c-2.3-0.1-4.1-2.7-3.5-4.9c0.3-1.5,1.6-2.6,3.1-2.9C16.4,13,16.7,12.9,16.9,12.9z
	 M16.9,14.3c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5c0-0.4-0.1-0.8-0.2-1.1C19,15.9,18.8,16,18.5,16
	c-0.6,0-1.1-0.5-1.1-1.1c0,0,0,0,0,0c0-0.2,0-0.3,0.1-0.5C17.3,14.3,17.1,14.3,16.9,14.3L16.9,14.3z"
      />
    </SvgIcon>
  );
}
