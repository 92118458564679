import { FormHelperText, LinearProgress, useTheme } from "@mui/material";
import type { FieldErrors } from "react-hook-form";

import type { HandleStatus } from "~/modules/auth/types";

function displayHandleStatus(handleStatus: HandleStatus) {
  if (handleStatus === "error") {
    return "Error";
  }
  if (handleStatus === "available") {
    return "Available";
  }
  if (handleStatus === "loading") {
    return "Checking availability...";
  }
  if (handleStatus === "unavailable") {
    return "Unavailable";
  }
  return "";
}

export function HandleProgressBar(props: {
  handleStatus: HandleStatus;
  errors: FieldErrors<{ handle: string }>;
  handle?: string;
  gifter?: boolean;
}) {
  const { handleStatus, errors, handle, gifter } = props;
  const theme = useTheme();

  function linearProgressColor() {
    if (handleStatus === "error" || handleStatus === "unavailable" || errors?.handle) {
      return theme.palette.error.main;
    }
    if (handleStatus === "available") {
      return "greenyellow";
    }
    return "";
  }

  return (
    <FormHelperText component="div" sx={{ mx: 0 }}>
      <LinearProgress
        color={handleStatus === "loading" ? "secondary" : "primary"}
        variant={handleStatus === "loading" ? "indeterminate" : "determinate"}
        value={100}
        sx={{
          "& .MuiLinearProgress-bar": {
            backgroundColor: linearProgressColor(),
          },
        }}
      />
      <span style={errors?.handle?.message ? { color: "red" } : {}}>
        {errors?.handle?.message || (
          <span>{`www.wishtender.com/${gifter ? "gifter/" : ""}${handle || "yourname"}`}</span>
        )}
      </span>
      <div style={{ display: "inline", float: "right" }}>{displayHandleStatus(handleStatus)}</div>
    </FormHelperText>
  );
}
