import { Box, Typography } from "@mui/material";

import { PAYMENT_TRANSITION_DETAIL_URL } from "~/constants/platform";

export function BannerContent() {
  return (
    <Box sx={{ px: 2, py: 1.5, bgcolor: "yellow.2" }}>
      <Typography component="p" variant="h5" color="neutral.6" textAlign="center">
        <span>📢 Important: We’re parting ways with our financial partners. </span>
        <Box
          component="a"
          href={PAYMENT_TRANSITION_DETAIL_URL}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "neutral.6", textDecoration: "underline" }}
        >
          Read here for how this affects your account.
        </Box>
      </Typography>
    </Box>
  );
}
