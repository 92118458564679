import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDebounce } from "usehooks-ts";
import type { z } from "zod";

import { RouteIds, Routes } from "~/constants/routes";
import { useRawFetcher } from "~/hooks/use-raw-fetcher";

import { SearchSchema } from "../schemas";
import type { SearchResult } from "../types";

export function useSearch() {
  const [search, searchState] = useRawFetcher<SearchResult>({
    onError: (response) => alert(response.error),
  });
  const form = useForm<z.infer<typeof SearchSchema>>({
    resolver: zodResolver(SearchSchema),

    defaultValues: {
      searchText: "",
    },
  });

  const watchedSearchText = form.watch("searchText");
  const debouncedSearchText = useDebounce<string>(watchedSearchText, 500);

  useEffect(() => {
    if (debouncedSearchText)
      search.load(
        Routes.public.search.general({
          queryParams: `queryString=${debouncedSearchText}&page=1&limit=8`,
        }),
        RouteIds.search.layout(),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  return {
    search,
    form,
    data: search.data,
    isLoading: searchState.isLoading,
    isFetching: searchState.isFetching,
  };
}
