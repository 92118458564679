import { Box, Stack, Typography } from "@mui/material";

import { ExclamationCircleFilledIcon } from "~/components/icons";

import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.PAYMENT_TRANSITIONED>;
}) {
  return (
    <NotificationContentContainer
      notification={notification}
      to={notification.data.detailUrl}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        "& .MuiLink-root": {
          ml: "0 !important",
        },
        backgroundColor: "yellow.1",
        "&:hover": {
          backgroundColor: "yellow.1",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "yellow.0",
          borderRadius: 6,
          height: 40,
          minWidth: 40,
          color: "yellow.4",
        }}
      >
        <ExclamationCircleFilledIcon sx={{ fontSize: 28 }} />
      </Box>
      <Stack sx={{ color: "neutral.7" }}>
        <Typography variant="h5" color="neutral.7">
          Important: Changes to payments
        </Typography>
        <Typography component="span" color="neutral.7" variant="b5">
          WishTender is switching payment providers. Read more to find important action items for
          your account.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
