import SearchIcon from "@mui/icons-material/Search";
import { Badge, BottomNavigation, BottomNavigationAction, Typography } from "@mui/material";
import { Link, useMatches } from "@remix-run/react";
import { useState } from "react";

import { RouteIds, Routes } from "~/constants/routes";
import { useNotifications } from "~/modules/notifications/context/notification-context";
import { SearchDialogMobile } from "~/modules/search/components/search-bar-mobile";
import { useWisher } from "~/modules/wisher/context/wisher-context";
import type { Auth } from "~/types";

import { BellIcon, EcommerceGiftIcon } from "./icons";
import { UserAvatar } from "./user-avatar";

const WISHER_NAVIGATION_STATES = {
  WISHLIST: "Wishlist",
  SEARCH: "Search",
  NOTIFICATIONS: "Notifications",
  WISHTRACKER: "Wish-Tracker",
};

function useWisherNavigationState(wisher: NonNullable<Auth["wisher"]>): string {
  const matches = useMatches();

  /* Wishlist */
  const wishlistRoute = matches.find((match) => match.id === RouteIds.public.wishlist());
  if (wishlistRoute && wishlistRoute.params["handle"] === wisher.handle)
    return WISHER_NAVIGATION_STATES.WISHLIST;

  /* Notifications */
  if (matches.some((match) => match.id === RouteIds.notifications.list()))
    return WISHER_NAVIGATION_STATES.NOTIFICATIONS;

  /* Wish-Tracker */
  if (matches.some((match) => match.id === RouteIds.wisher.order.list()))
    return WISHER_NAVIGATION_STATES.WISHTRACKER;

  return "";
}

export function WisherMobileNavigation({
  wisher,
  user,
}: {
  wisher: NonNullable<Auth["wisher"]>;
  user: Auth["user"];
}) {
  const { totalNewNotifications } = useNotifications();
  const defaultNavigationValue = useWisherNavigationState(wisher);
  const [value, setValue] = useState(defaultNavigationValue);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const { totalNewOrders } = useWisher();

  return (
    <>
      <BottomNavigation
        showLabels={true}
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          width: "100%",
          borderTop: 1,
          borderColor: "gray.2",
          "& .MuiBottomNavigationAction-root": {
            px: 0,
            color: "neutral.5",
            "&.Mui-selected": { color: "teal.4" },
          },
        }}
      >
        <BottomNavigationAction
          value={WISHER_NAVIGATION_STATES.WISHLIST}
          component={Link}
          to={Routes.public.wisher({ username: wisher.handle })}
          label={<Typography variant="b3">Wishlist</Typography>}
          icon={
            <UserAvatar
              sx={{
                width: 24,
                height: 24,
                mixBlendMode: value === WISHER_NAVIGATION_STATES.WISHLIST ? "none" : "luminosity",
              }}
              src={wisher.avatarUrl}
            />
          }
        />
        {user?.featureFlags.includes("search") && (
          <BottomNavigationAction
            value={WISHER_NAVIGATION_STATES.SEARCH}
            label={<Typography variant="b3">Search</Typography>}
            icon={<SearchIcon />}
            onClick={() => setOpenSearchDialog(true)}
          />
        )}
        <BottomNavigationAction
          value={WISHER_NAVIGATION_STATES.NOTIFICATIONS}
          component={Link}
          to={Routes.notifications.main({})}
          label={<Typography variant="b3">Notifications</Typography>}
          icon={
            <Badge
              badgeContent={totalNewNotifications}
              sx={{
                "& .MuiBadge-badge": {
                  top: 4,
                  fontSize: 10,
                  bgcolor: "teal.4",
                  color: "neutral.0",
                },
              }}
            >
              <BellIcon />
            </Badge>
          }
        />
        <BottomNavigationAction
          value={WISHER_NAVIGATION_STATES.WISHTRACKER}
          component={Link}
          to={Routes.wisher.orderList()}
          label={<Typography variant="b3">Wish-Tracker</Typography>}
          icon={
            <Badge
              badgeContent={totalNewOrders}
              sx={{
                "& .MuiBadge-badge": {
                  color: "neutral.0",
                  top: 4,
                  fontSize: 10,
                  background:
                    "radial-gradient(222.19% 110.71% at 53.57% 110.71%, #005CE5 17.71%, #00C8F3 100%)",
                },
              }}
            >
              <EcommerceGiftIcon />
            </Badge>
          }
        />
      </BottomNavigation>
      <SearchDialogMobile open={openSearchDialog} setOpen={setOpenSearchDialog} />
    </>
  );
}
