import { type Dispatch, useState } from "react";

import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

import { type NotificationAction, NotificationActionType } from "../context/notification-context";
import type { NotificationType, NotificationV2 } from "../types";

export function useSeenNotifications({ dispatch }: { dispatch: Dispatch<NotificationAction> }) {
  const [currentNotification, setCurrentNotification] = useState<
    NotificationV2<NotificationType> | undefined
  >();

  const [markSeen, markSeenState] = useWtFetcher({
    onError: (response) => {
      alert(response.error);
    },
    onSuccess: () => {
      dispatch({
        type: NotificationActionType.SEEN_NOTIFICATION,
        payload: {
          notification: currentNotification,
        },
      });
      setCurrentNotification(undefined);
    },
  });

  function handleMarkSeen(notification: NotificationV2<NotificationType>) {
    if (!notification.seen) {
      setCurrentNotification(notification);
      markSeen.submit(
        {
          id: notification.id,
        },
        {
          method: "post",
          action: Routes.actions.notifications.seen(),
        },
      );
    }
  }

  return {
    mutate: handleMarkSeen,
    isFetching: markSeenState.isFetching,
  };
}
