import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  type SxProps,
  type Theme,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IconDownload } from "@tabler/icons-react";
import type { MouseEvent } from "react";
import { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Button } from "~/components/button";
import { Dialog } from "~/components/dialog";
import { Routes } from "~/constants/routes";
import { themeV2 } from "~/utils/theme";

import type { NotificationType, NotificationV2 } from "../types";
import { confettiFireWorks } from "./confetti-fire-works";

const RECAP_STEP = {
  INTRO: "Intro",
  RECAP: "Recap",
};

enum CARD_TYPE {
  RECAP = "Recap",
  ARCHETYPE = "ArcheType",
}

function ImageWithLoadingSpinner({
  src,
  alt,
  sx,
}: {
  src: string;
  alt: string;
  sx: SxProps<Theme>;
}) {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      {!loaded && (
        <Box
          sx={{
            height: 460,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        component="img"
        onLoad={() => setLoaded(true)}
        src={src}
        sx={{ ...sx, display: loaded ? "block" : "none" }}
        alt={alt}
      />
    </>
  );
}

export function EndOfYearRecapDialog({
  notification,
  open,
  onClose,
}: {
  notification: NotificationV2<NotificationType.YEARLY_RECAP_WRAPPED>;
  open: boolean;
  onClose: () => void;
}) {
  const theme = useTheme();
  const isLargeDesktopScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isSmallMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [step, setStep] = useState<string>(RECAP_STEP.INTRO);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [confettiInterval, setConfettiInterval] = useState<ReturnType<typeof setInterval>>();
  const [swiperRef, setSwiperRef] = useState<any>();

  const [selectedRecapCardIndex, setSelectedRecapCardIndex] = useState(0);
  const recapCardUrl = notification.data?.recapCardUrls[selectedRecapCardIndex];

  const recapCardPath = recapCardUrl?.replace(new URL(recapCardUrl).origin, "");
  const recapCardDownloadUrl = Routes.proxy.dynamicImage(recapCardPath);
  const personaCardWebpUrl = `/images/${notification.data?.archetype}.webp`;
  const personaCardPngUrl = `/images/${notification.data?.archetype}.png`;

  const reset = useCallback(() => {
    if (confettiInterval) clearInterval(confettiInterval);
    setStep(RECAP_STEP.INTRO);
    setSelectedRecapCardIndex(0);
  }, [confettiInterval]);

  function handleClose(event: MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    reset();
    onClose();
  }

  function handleSwitchToRecap() {
    if (confettiInterval) {
      clearInterval(confettiInterval);
    }
    setStep(RECAP_STEP.RECAP);
  }

  function handleScroll(to: CARD_TYPE) {
    /* Scroll up to recap card session */
    if (to === CARD_TYPE.RECAP) {
      swiperRef?.slidePrev();
    }
    /* Scroll down to arche type card session */
    if (to === CARD_TYPE.ARCHETYPE) {
      swiperRef?.slideNext();
    }
  }

  function handleChangeColor() {
    const total = notification.data?.recapCardUrls.length || 0;
    const nextIndex = selectedRecapCardIndex + 1 >= total ? 0 : selectedRecapCardIndex + 1;
    setSelectedRecapCardIndex(nextIndex);
  }

  /* Display confetti firework when dialog opened */
  useEffect(() => {
    if (notification && open) setConfettiInterval(confettiFireWorks());
  }, [notification, open]);

  /* Clean confetti firework and reset state when dialog closed */
  useEffect(() => {
    return reset;
  }, [reset]);

  /* Force browser to preload recap images */
  useEffect(() => {
    if (notification?.data?.recapCardUrls.length > 0) {
      /* Preload the first recap image */
      const firstImageUrl = notification?.data?.recapCardUrls[0];
      const img = new Image();
      img.src = firstImageUrl;
      /* After that, preload the rest for other colors */
      img.addEventListener("load", function () {
        const restUrls = notification?.data?.recapCardUrls.slice(1);
        for (const url of restUrls) {
          const img = new Image();
          img.src = url;
        }
      });
    }
  }, [notification?.data?.recapCardUrls]);

  return (
    <ThemeProvider theme={themeV2}>
      <Dialog.Root
        open={open}
        maxWidth="sm"
        fullWidth
        variant="normal"
        onClose={handleClose}
        sx={{
          "& .MuiDialog-close-icon": {
            color: "gray.0",
          },
          "& .MuiDialog-paper": {
            maxHeight: "100%",
            borderRadius: 4,
            border: 3,
            borderColor: "gray.2",
            boxShadow:
              "0px 8px 16px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05)",
          },
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            border: 2,
            borderColor: "gray.2",
            boxShadow:
              "0px 8px 16px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05)",
          },
        }}
      >
        <Dialog.Content
          sx={{
            background:
              "radial-gradient(211.78% 110.13% at 97.45% 92.6%, #9757C9 6.77%, #0C56C6 54.42%, #0DC4EC 100%)",
            overflowY: "hidden",
            pb: 2.5,
          }}
        >
          {/* Introduction */}
          {step === RECAP_STEP.INTRO && (
            <Stack
              spacing={3}
              direction="column"
              sx={{ alignItems: "center", justifyContent: "center", pt: { xs: 4.5, xl: 6 } }}
            >
              <Typography
                variant={isSmallMobileScreen ? "bh7" : "bh9"}
                color="gray.0"
                sx={{
                  textAlign: "center",
                  textShadow:
                    "0px 2px 4px rgba(38, 103, 255, 0.10), 0px 0px 1px rgba(38, 103, 255, 0.05)",
                }}
              >
                Your End-of-Year 2023 Recap ✨
              </Typography>
              <Typography
                variant={isSmallMobileScreen ? "b4" : "b6"}
                color="gray.0"
                sx={{ textAlign: "center" }}
              >
                Hi {notification.data?.wisher?.name} 👋,
                <br />
                We've had a fruitful year together, haven't we?
                <br />
                The WishTender team has pulled together some memorable stats of your year into two
                cards. Let's flip them over together 👀
              </Typography>
              <img
                src="/images/recap-cards.png"
                width={isSmallMobileScreen ? 200 : 222}
                alt="Recap cards"
              />
              <Button
                onClick={handleSwitchToRecap}
                variant="outlined"
                sx={{
                  borderRadius: 12,
                  border: 0,
                  bgcolor: "gray.0",
                  px: 3.5,
                  py: 1.25,
                  "&:hover": { bgcolor: "gray.0" },
                }}
              >
                <Typography variant="h5" color="teal.4">
                  Get my Recap
                </Typography>
              </Button>
            </Stack>
          )}

          {/* Show recap cards */}
          {step === RECAP_STEP.RECAP && (
            <Box
              sx={{
                "& .swiper-pagination-bullet": {
                  bgcolor: "gray.2",
                },
                "& .swiper-pagination-bullet-active": {
                  bgcolor: "gray.0",
                },
              }}
            >
              {/* Indicators dot */}
              <Stack
                spacing={1.5}
                direction="column"
                sx={{
                  position: "absolute",
                  right: 24,
                  top: 0,
                  bottom: 0,
                  justifyContent: "center",
                }}
              >
                <IconButton sx={{ padding: 0 }} onClick={() => handleScroll(CARD_TYPE.RECAP)}>
                  <CircleIcon
                    sx={{ fontSize: 12, color: activeIndex === 0 ? "gray.0" : "gray.3" }}
                  />
                </IconButton>
                <IconButton sx={{ padding: 0 }} onClick={() => handleScroll(CARD_TYPE.ARCHETYPE)}>
                  <CircleIcon
                    sx={{ fontSize: 12, color: activeIndex === 1 ? "gray.0" : "gray.3" }}
                  />
                </IconButton>
              </Stack>

              {/* Swiper */}
              <Swiper
                onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                onSwiper={setSwiperRef}
                direction="vertical"
                pagination={{
                  clickable: true,
                }}
                style={{ maxHeight: isLargeDesktopScreen ? 720 : 600 }}
              >
                {/* Recap card */}

                <SwiperSlide key={CARD_TYPE.RECAP}>
                  <Stack
                    spacing={{ xs: 3, xl: 6 }}
                    direction="column"
                    sx={{
                      height: "100%",
                      pt: { xs: 2, lg: 4.5, xl: 6 },
                    }}
                  >
                    <Stack
                      spacing={{ xs: 2, xl: 3 }}
                      direction="column"
                      sx={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 0,
                      }}
                    >
                      <Typography
                        variant={isSmallMobileScreen ? "bh7" : "bh9"}
                        color="gray.0"
                        sx={{
                          textAlign: "center",
                          textShadow:
                            "0px 2px 4px rgba(38, 103, 255, 0.10), 0px 0px 1px rgba(38, 103, 255, 0.05)",
                        }}
                      >
                        Your Recap (1/2)
                      </Typography>

                      <Box sx={{ flex: 1, minHeight: 0 }}>
                        <ImageWithLoadingSpinner
                          src={recapCardUrl}
                          sx={{
                            height: "100%",
                            borderRadius: 2,
                            border: 2,
                            borderColor: "gray.0",
                          }}
                          alt="2023 Wisher Recap Card"
                        />
                      </Box>

                      <Stack direction={{ xs: "column", sm: "row" }} spacing={1.25}>
                        <Button
                          variant="outlined"
                          onClick={handleChangeColor}
                          sx={{
                            borderRadius: 12,
                            borderColor: "gray.0",
                            px: 3.5,
                            py: 1.25,
                            "&:hover": { borderColor: "gray.0" },
                          }}
                        >
                          <Typography variant="h5" color="gray.0">
                            Change Card Color
                          </Typography>
                        </Button>
                        <Button
                          component="a"
                          href={recapCardDownloadUrl}
                          download="your-2023-wt-wrapped.png"
                          variant="outlined"
                          sx={{
                            borderRadius: 12,
                            border: 0,
                            bgcolor: "gray.0",
                            px: 3.5,
                            py: 1.25,
                            "&:hover": { bgcolor: "gray.0", border: 0 },
                          }}
                        >
                          <Stack direction="row" spacing={0.5}>
                            <Typography variant="h5" color="teal.4">
                              Save Image
                            </Typography>
                            <IconDownload />
                          </Stack>
                        </Button>
                      </Stack>
                    </Stack>
                    <Button
                      onClick={() => handleScroll(CARD_TYPE.ARCHETYPE)}
                      sx={{
                        background: "none",
                        "&:hover": { border: 0, background: "none", outline: "none" },
                        "&:focus": { border: 0, background: "none", outline: "none" },
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Typography variant="h6" color="gray.0">
                          Your Wisher Persona (2/2)
                        </Typography>
                        <KeyboardArrowDownIcon />
                      </Stack>
                    </Button>
                  </Stack>
                </SwiperSlide>

                {/* Archetype card */}
                <SwiperSlide key={CARD_TYPE.ARCHETYPE}>
                  <Stack
                    spacing={{ xs: 3, xl: 6 }}
                    direction="column"
                    sx={{
                      pt: { xs: 2, lg: 4.5, xl: 6 },
                      height: "100%",
                    }}
                  >
                    <Stack
                      spacing={{ xs: 2, xl: 3 }}
                      direction="column"
                      sx={{ alignItems: "center", justifyContent: "center", minHeight: 0, flex: 1 }}
                    >
                      <Typography
                        variant={isSmallMobileScreen ? "bh7" : "bh9"}
                        color="gray.0"
                        sx={{
                          textAlign: "center",
                          textShadow:
                            "0px 2px 4px rgba(38, 103, 255, 0.10), 0px 0px 1px rgba(38, 103, 255, 0.05)",
                        }}
                      >
                        Your Wisher Persona (2/2)
                      </Typography>

                      <Box sx={{ flex: 1, minHeight: 0 }}>
                        <Box component="picture">
                          <source srcSet={personaCardWebpUrl} />
                          <ImageWithLoadingSpinner
                            src={personaCardPngUrl}
                            sx={{
                              height: "100%",
                              borderRadius: 3,
                              border: 2,
                              borderColor: "gray.0",
                            }}
                            alt="2023 Wisher Persona Card"
                          />
                        </Box>
                      </Box>

                      <Button
                        component="a"
                        href={personaCardPngUrl}
                        download="your-2023-wisher-persona.png"
                        variant="outlined"
                        sx={{
                          borderRadius: 12,
                          border: 0,
                          bgcolor: "gray.0",
                          px: 3.5,
                          py: 1.25,
                          "&:hover": { bgcolor: "gray.0", border: 0 },
                        }}
                      >
                        <Stack direction="row" spacing={0.5}>
                          <Typography variant="h5" color="teal.4">
                            Save Image
                          </Typography>
                          <IconDownload />
                        </Stack>
                      </Button>
                    </Stack>
                    <Button
                      onClick={() => handleScroll(CARD_TYPE.RECAP)}
                      sx={{
                        background: "none",
                        "&:hover": { border: 0, background: "none", outline: "none" },
                        "&:focus": { border: 0, background: "none", outline: "none" },
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Typography variant="h6" color="gray.0">
                          Your Recap (1/2)
                        </Typography>
                        <KeyboardArrowUpIcon />
                      </Stack>
                    </Button>
                  </Stack>
                </SwiperSlide>
              </Swiper>
            </Box>
          )}
        </Dialog.Content>
      </Dialog.Root>
    </ThemeProvider>
  );
}
