import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Box, Divider, IconButton, Menu, Stack } from "@mui/material";
import { type MouseEvent, useState } from "react";

import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useNotifications } from "~/modules/notifications";
import type { Auth } from "~/types";

import {
  AccountSettingIcon,
  BacknoteSparkleIcon,
  SignOutIcon,
  UserCircleIcon,
  WishlistIcon,
} from "../icons";
import { UserAvatar } from "../user-avatar";
import { StyledMenuItem, UserInfoCard } from "./account-nav-menu.common";
import { AdminMenuItem } from "./admin-menu-item";

function WisherMenu({
  wisher,
  closeMenu,
  resetNotification,
}: {
  wisher: NonNullable<Auth["wisher"]>;
  closeMenu: () => void;
  resetNotification: () => void;
}) {
  return (
    <Box>
      <StyledMenuItem
        to={Routes.public.wisher({ username: wisher.handle })}
        onClick={closeMenu}
        icon={<WishlistIcon />}
        text="Wishlist"
      />
      <StyledMenuItem
        to={Routes.settings.main()}
        onClick={closeMenu}
        icon={<AccountSettingIcon />}
        text="Account Settings"
      />
      <StyledMenuItem
        to={Routes.wisher.paymentDashboard.main()}
        onClick={closeMenu}
        icon={<BacknoteSparkleIcon />}
        text="Payment Dashboard"
      />
      <StyledMenuItem
        to={Routes.external.intercom()}
        onClick={closeMenu}
        icon={<InfoIcon />}
        text="FAQ"
      />
      <Divider sx={{ borderColor: "gray.2" }} />
      <AdminMenuItem closeMenu={closeMenu} />
      <StyledMenuItem
        to={Routes.auth.logout()}
        onClick={() => {
          resetNotification();
          closeMenu();
        }}
        icon={<SignOutIcon />}
        text="Log Out"
      />
    </Box>
  );
}

function GifterMenu({
  gifter,
  closeMenu,
  resetNotification,
}: {
  gifter: NonNullable<Auth["gifter"]>;
  closeMenu: () => void;
  resetNotification: () => void;
}) {
  return (
    <Box>
      <StyledMenuItem
        to={Routes.public.gifter({ username: gifter.handle })}
        onClick={closeMenu}
        icon={<UserCircleIcon />}
        text="Profile"
      />
      <StyledMenuItem
        to={Routes.gifter.settings.main()}
        onClick={closeMenu}
        icon={<AccountSettingIcon />}
        text="Account Settings"
      />
      <StyledMenuItem
        to={Routes.external.intercom()}
        onClick={closeMenu}
        icon={<InfoIcon />}
        text="FAQ"
      />
      <Divider sx={{ borderColor: "gray.2" }} />
      <AdminMenuItem closeMenu={closeMenu} />
      <StyledMenuItem
        to={Routes.auth.logout()}
        onClick={() => {
          resetNotification();
          closeMenu();
        }}
        icon={<SignOutIcon />}
        text="Log Out"
      />
    </Box>
  );
}

function GuestMenu({ closeMenu }: { closeMenu: () => void }) {
  return (
    <Box>
      <StyledMenuItem
        to={Routes.external.intercom()}
        onClick={closeMenu}
        icon={<InfoIcon />}
        text="FAQ"
      />
    </Box>
  );
}

export function AccountNavMenuTablet() {
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>();
  const auth = useAuth();
  const { reset } = useNotifications();

  function open(event: MouseEvent<HTMLButtonElement>) {
    setAnchorElement(event.currentTarget);
  }
  function close() {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setAnchorElement(undefined);
  }

  /* Set the default menu to be Guest Menu */
  let menu = <GuestMenu closeMenu={close} />;
  if (auth.wisher) {
    menu = <WisherMenu wisher={auth.wisher} closeMenu={close} resetNotification={reset} />;
  }
  if (auth.gifter) {
    menu = <GifterMenu gifter={auth.gifter} closeMenu={close} resetNotification={reset} />;
  }

  return (
    <>
      <IconButton
        id="account-button"
        onClick={open}
        onMouseOver={open}
        size="large"
        aria-label="account"
        sx={{ ":hover": { background: "none" }, px: 1, py: 1 }}
      >
        <UserAvatar
          sx={{ width: 32, height: 32 }}
          src={auth?.wisher?.avatarUrl || auth?.gifter?.avatarUrl}
        />
      </IconButton>

      <Menu
        anchorEl={anchorElement}
        open={!!anchorElement}
        onClose={close}
        MenuListProps={{ "aria-labelledby": "account-button", onMouseLeave: close }}
      >
        <Box sx={{ zIndex: 901, bgcolor: "gray.0", height: "100%", px: 0, py: 2 }}>
          <Stack spacing={1}>
            <UserInfoCard onClick={close} />
            {menu}
          </Stack>
        </Box>
      </Menu>
    </>
  );
}
