import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Stack, ThemeProvider } from "@mui/material";
import { useNavigation } from "@remix-run/react";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";

import { themeV2 } from "~/utils/theme";

import { useSearch } from "../hooks/use-search";
import { SearchBarResults } from "./search-bar-results";
import { SearchInputForm } from "./search-input-form";

export function SearchBarDesktop() {
  const navigation = useNavigation();
  const { form, isFetching, data } = useSearch();
  const [isFocused, setIsFocused] = useState(false);

  const searchText = form.watch("searchText");

  useEffect(() => {
    if (navigation.state === "loading" && isFocused) setIsFocused(false);
  }, [navigation.state, isFocused]);

  return (
    <ThemeProvider theme={themeV2}>
      <ClickAwayListener onClickAway={() => setIsFocused(false)}>
        <Stack sx={{ width: "30vw" }} position="relative">
          <FormProvider {...form}>
            <SearchInputForm onFocus={() => setIsFocused(true)} loading={isFetching} />
          </FormProvider>

          {isFocused && searchText && (
            <Stack
              sx={{
                width: {
                  xs: 448,
                  lg: "32vw",
                },
                position: "absolute",
                bgcolor: "neutral.0",
                boxShadow:
                  "0px 16px 24px 0px rgba(38, 103, 255, 0.09), 0px 0px 1px 0px rgba(38, 103, 255, 0.05)",
                borderRadius: 2,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                overflow: "hidden",
                top: 59,
                left: {
                  xs: 0,
                  lg: -8,
                },
              }}
            >
              <SearchBarResults data={data} queryString={searchText} loading={isFetching} />
            </Stack>
          )}
        </Stack>
      </ClickAwayListener>
    </ThemeProvider>
  );
}
