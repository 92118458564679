import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { IconBrandTwitter, IconStar } from "@tabler/icons-react";
import type { MouseEvent } from "react";
import { useEffect, useState } from "react";

import { Dialog } from "~/components/dialog";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useOrigin } from "~/hooks/use-origin";
import { dayjs } from "~/utils/dayjs";
import { themeV2 } from "~/utils/theme";

import type { NotificationType, NotificationV2 } from "../types";
import { confettiFireWorks } from "./confetti-fire-works";

export function TopRankAlert({
  notification,
  open,
  onClose,
}: {
  notification: NotificationV2<NotificationType.MONTHLY_LEADERBOARD>;
  open: boolean;
  onClose: () => void;
}) {
  const origin = useOrigin();
  const { wisher } = useAuth();
  const [confettiInterval, setConfettiInterval] = useState<ReturnType<typeof setInterval>>();

  function handleClose(event: MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    if (confettiInterval) clearInterval(confettiInterval);
    onClose();
  }

  useEffect(() => {
    if (notification && open) setConfettiInterval(confettiFireWorks());
  }, [notification, open]);

  useEffect(() => {
    return () => {
      if (confettiInterval) clearInterval(confettiInterval);
    };
  }, [confettiInterval]);

  if (!notification) return <></>;
  return (
    <ThemeProvider theme={themeV2}>
      <Dialog.Root open={open} maxWidth="xs" variant="normal" fullWidth onClose={handleClose}>
        <Dialog.Content sx={{ background: (theme) => theme.palette.gradient[100], py: 8, px: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
              height: { xs: "100vh", sm: "auto" },
            }}
          >
            <Typography
              variant="h8"
              sx={{ color: "white", textAlign: "center", textShadow: "0px 2px 4px #0000006b" }}
            >
              {`You were the #${notification.data.rank} top wisher in all of ${
                dayjs().localeData().months()[notification.data.month - 1]
              }!`}
            </Typography>
            <Box sx={{ color: "#ffff00" }}>
              <IconStar size={60} />
            </Box>
            <Box>
              <Button
                variant="text"
                aria-label="Share on Twitter"
                size="medium"
                endIcon={<IconBrandTwitter size={20} />}
                onClick={(event) => {
                  // format: top_1_1-22 (top_<rank>_<month>-<year>)
                  const wishlistUrl = `${origin}${Routes.public.wisher({
                    username: wisher?.handle || "",
                  })}`;
                  const params = new URLSearchParams({
                    text: `I was the #${
                      notification.data.rank
                    } top wisher on WishTender in all of ${
                      dayjs().localeData().months()[notification.data.month - 1]
                    }. \n\n🥳🥳🥳\n\nCheckout my wishlist here: ${wishlistUrl} via @wishtender`,
                  });
                  const shareUrl = `https://twitter.com/intent/tweet?${params.toString()}`;
                  event.stopPropagation();
                  window.open(shareUrl, "popup", "width=600,height=600");
                }}
                sx={{ color: "white" }}
              >
                Share
              </Button>
            </Box>
          </Box>
        </Dialog.Content>
      </Dialog.Root>
    </ThemeProvider>
  );
}
