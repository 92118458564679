import { Stack, Typography, useTheme } from "@mui/material";
import { IconExternalLink } from "@tabler/icons-react";

import { formatTime } from "~/utils/time";

import type { NotificationType, NotificationV2 } from "../../types";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.NEW_NOTICE>;
}) {
  const theme = useTheme();
  return (
    <Stack
      spacing={0.25}
      sx={{
        pt: 1,
        pb: 2,
        pl: 2.5,
        pr: 2,
      }}
    >
      <Typography component="span" variant="b5" sx={{ color: "neutral.5" }}>
        {formatTime(notification.createdAt, "dynamic")}
      </Typography>
      <Typography component="span" variant="h5">
        {notification.data.title}
      </Typography>
      <Typography
        component="p"
        variant="b5"
        sx={{
          color: "neutral.5",
          pt: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "3",
          WebkitBoxOrient: "vertical",
        }}
      >
        {notification.data.description}
      </Typography>
      <Stack
        component="a"
        href={notification.data.url}
        target="_blank"
        rel="noreferrer noopener"
        direction="row"
        spacing={1}
        sx={{
          py: 0.75,
          alignItems: "center",
        }}
      >
        <Typography component="span" variant="h5" sx={{ color: "teal.4" }}>
          Learn more & see it in action
        </Typography>
        <IconExternalLink size={20} style={{ color: theme.palette.teal[4] }} />
      </Stack>
    </Stack>
  );
}
