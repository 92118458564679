import { z } from "zod";

export const SearchSchema = z.object({
  searchText: z.string(),
});

export const PriceRangeFilterSchema = z.object({
  minPrice: z.string().nonempty({ message: "Required." }),
  maxPrice: z.string().nonempty({ message: "Required." }),
});
