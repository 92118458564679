import ArrowRight from "@mui/icons-material/ArrowRight";
import { Divider, ListItemIcon, ListItemText, MenuItem, Paper, ThemeProvider } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useNavigate, useRouteLoaderData } from "@remix-run/react";

import { GeneralLayout } from "~/components/general-layout";
import { RouteIds, Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { themeV2 } from "~/utils/theme";

import type { loader } from "../../../routes/gifter.settings";
import { ChangeStatementDescriptor } from "../components/change-statement-descriptor-item";
import { DeleteGifterAccountItem } from "../components/delete-gifter-account-item";
import { RequestSettingsItem } from "../components/request-settings-items";
import { ResetGifterPasswordItem } from "../components/reset-gifter-password-item";
import { UpdateGifterEmailItem } from "../components/update-gifter-email-item";

export function SettingsPage() {
  const navigate = useNavigate();
  const authData = useAuth();
  const data = useRouteLoaderData<typeof loader>(RouteIds.gifter.settings());

  const shouldShowStatementDescriptorFeature =
    data?.statementDescriptors &&
    data.statementDescriptors.length > 0 &&
    authData.gifter &&
    authData.user?.featureFlags?.includes("statementDescriptor"); // TODO: remove this check when launch statement descriptor feature

  return (
    <GeneralLayout>
      <ThemeProvider theme={themeV2}>
        <Box component="main" sx={{ minHeight: "calc(100vh - 72px)", py: "7vw" }}>
          <Container maxWidth="md">
            <Paper>
              <UpdateGifterEmailItem email={authData.user?.email} />

              <Divider sx={{ m: "0 !important" }} />

              <ResetGifterPasswordItem />

              {shouldShowStatementDescriptorFeature && (
                <>
                  <Divider sx={{ m: "0 !important" }} />

                  <ChangeStatementDescriptor />
                </>
              )}

              <Divider sx={{ m: "0 !important" }} />

              <RequestSettingsItem />

              <Divider sx={{ m: "0 !important" }} />

              <MenuItem
                sx={{ py: 1 }}
                onClick={() => navigate(Routes.user.payment.payin.methods())}
              >
                <ListItemText>Payment Method</ListItemText>
                <ListItemIcon>
                  <ArrowRight />
                </ListItemIcon>
              </MenuItem>

              <Divider sx={{ m: "0 !important" }} />

              <DeleteGifterAccountItem gifterId={authData.gifter?.id} />
            </Paper>
          </Container>
        </Box>
      </ThemeProvider>
    </GeneralLayout>
  );
}
