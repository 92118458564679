import { Box, Paper, Typography } from "@mui/material";

import { ReferralDurationsTimeline } from "~/modules/referrals";

import { useWisherReferralCodes } from "../../hooks/use-wisher-referrals";

export function DurationRatesSection() {
  const referralCodes = useWisherReferralCodes();

  if (referralCodes.length === 0) return undefined;

  // In business logic, we will use the same rates for all referral codes, so we can just use the first one to show
  const firstDurations = referralCodes[0]?.commissionDurations;
  const wisherDurations = firstDurations.map((duration) => ({
    time: duration.time,
    rate: duration.wisherRate,
  }));
  const gifterDurations = firstDurations.map((duration) => ({
    time: duration.time,
    rate: duration.gifterRate,
  }));
  return (
    <Box>
      <Typography variant="h8" color="neutral.7">
        Referral Rates
      </Typography>
      <Box sx={{ mt: "20px" }} />
      <Paper
        sx={{
          boxShadow: (theme) => theme.palette.shadows.primary[200],
          p: { xs: 2, sm: 4 },
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              md: "repeat(2, minmax(0, 1fr))",
            },
            gap: 3,
          }}
        >
          <div>
            <Typography variant="sh5" color="neutral.6">
              Refer a Wisher
            </Typography>
            <Box sx={{ mt: "20px" }} />
            <ReferralDurationsTimeline durations={wisherDurations} />
          </div>
          <div>
            <Typography variant="sh5" color="neutral.6">
              Refer a Gifter
            </Typography>
            <Box sx={{ mt: "20px" }} />
            <ReferralDurationsTimeline durations={gifterDurations} />
          </div>
        </Box>
      </Paper>
    </Box>
  );
}
