import type { SxProps } from "@mui/material";
import { Box, Link, Stack } from "@mui/material";
import { Link as RLink } from "@remix-run/react";
import type { HTMLAttributeAnchorTarget, ReactNode } from "react";

import { useNotifications } from "../context/notification-context";
import type { NotificationType, NotificationV2 } from "../types";

export function NotificationContentContainer({
  notification,
  to,
  target,
  rel,
  children,
  sx,
  onClick,
}: {
  to?: string;
  target?: HTMLAttributeAnchorTarget;
  rel?: string;
  onClick?: () => void;
  notification: NotificationV2<NotificationType>;
  children: ReactNode;
  sx?: SxProps;
}) {
  const { markSeenMutation, toggleNotificationHub } = useNotifications();

  return (
    <Stack
      component="div"
      role=""
      direction="row"
      spacing={1.5}
      sx={{
        width: "100%",
        cursor: "pointer",
        px: {
          xs: 2,
          sm: 3,
        },
        pt: 1.5,
        pb: 2,
        position: "relative",
        bgcolor: notification.seen ? "neutral.0" : "teal.1",
        transition: "background-color 0.2s ease-in-out",
        ":hover": {
          bgcolor: notification.seen ? "neutral.2" : "teal.2",
        },
        ...sx,
      }}
      onClick={() => {
        markSeenMutation.mutate(notification);
        onClick?.();
      }}
    >
      {children}
      {/* This is a link overlay, solution for warning of multiple nested links */}
      {to && (
        <Link
          component={RLink}
          to={to}
          target={target}
          rel={rel}
          underline="none"
          sx={{ color: "neutral.7" }}
          onClick={(event) => {
            event.stopPropagation();
            markSeenMutation.mutate(notification);
            toggleNotificationHub(false);
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        </Link>
      )}
    </Stack>
  );
}
