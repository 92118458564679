import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function DeviceElectronicIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56451 6C6.01329 6 4.71789 7.18258 4.57692 8.72738L4.0119 14.9193C3.86095 16.5734 5.16327 18 6.8243 18C7.50105 18 8.11933 17.7638 8.60688 17.3665C9.34256 16.7668 10.5124 16 12 16C13.4875 16 14.6574 16.7668 15.3931 17.3665C15.8806 17.7638 16.4989 18 17.1757 18C18.8367 18 20.139 16.5734 19.9881 14.9193L19.4231 8.72738C19.2821 7.18258 17.9867 6 16.4355 6H7.56451ZM7.56451 4C4.97914 4 2.82014 5.97096 2.58519 8.54563L2.02017 14.7375C1.76233 17.5632 3.98693 20 6.8243 20C7.97772 20 9.03887 19.5946 9.87048 18.9167C10.4859 18.4151 11.206 18 12 18C12.794 18 13.5141 18.4151 14.1295 18.9167C14.9611 19.5946 16.0223 20 17.1757 20C20.013 20 22.2376 17.5632 21.9798 14.7375L21.4148 8.54564C21.1798 5.97097 19.0208 4 16.4355 4H7.56451Z"
        fill="currentColor"
      />
      <path
        d="M9 8C8.44772 8 8 8.44772 8 9V10H7C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H8V13C8 13.5523 8.44772 14 9 14C9.55228 14 10 13.5523 10 13V12H11C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10H10V9C10 8.44772 9.55228 8 9 8Z"
        fill="currentColor"
      />
      <path
        d="M16 13C16 13.5523 15.5523 14 15 14C14.4477 14 14 13.5523 14 13C14 12.4477 14.4477 12 15 12C15.5523 12 16 12.4477 16 13Z"
        fill="currentColor"
      />
      <path
        d="M17 10C17.5523 10 18 9.55228 18 9C18 8.44772 17.5523 8 17 8C16.4477 8 16 8.44772 16 9C16 9.55228 16.4477 10 17 10Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
