import { Box, Typography } from "@mui/material";
import { Link } from "@remix-run/react";
import { IconUser } from "@tabler/icons-react";

import { UserAvatar } from "~/components/user-avatar";
import { Routes } from "~/constants/routes";

import { useWisherReferralTopActiveReferees } from "../../hooks/use-wisher-referrals";

export function TopActiveRefereesSection() {
  const referees = useWisherReferralTopActiveReferees();
  return (
    <Box>
      <Typography variant="sh3" color="neutral.7">
        TOP ACTIVE REFEREES
      </Typography>
      <Box mt={2} />
      {referees.length === 0 && (
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: 48,
              height: 48,
              bgcolor: "neutral.2",
              color: "neutral.7",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 999,
            }}
          >
            <IconUser size={32} />
          </Box>
          <Box mt={2} />
          <Typography variant="b5" color="neutral.5">
            Your most active referees will be listed here
          </Typography>
        </Box>
      )}
      {referees.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {referees.slice(0, 6).map((referee) => {
            const name = referee.gifter?.name || referee.wisher?.name;
            const avatarUrl = referee.gifter?.avatarUrl || referee.wisher?.avatarUrl;
            let url = "";
            if (referee.gifter) url = Routes.public.gifter(referee.gifter);
            if (referee.wisher) url = Routes.public.wisher(referee.wisher);
            return (
              <Box
                key={referee.id}
                sx={{ p: 0.5, display: "flex", gap: 1, alignItems: "center" }}
                component={url ? Link : "div"}
                to={url}
              >
                <Box sx={{ flexShrink: 0, color: "neutral.5" }}>
                  <UserAvatar src={avatarUrl} alt={name} sx={{ width: 32, height: 32 }} />
                </Box>
                <Box sx={{ overflow: "hidden" }}>
                  <Typography
                    variant="b5"
                    color="neutral.7"
                    component="div"
                    className="notranslate"
                    noWrap
                    title={name}
                  >
                    {name}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
