import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

import { type NotificationContextValues } from "../context/notification-context";
import type { NotificationType } from "../types";

export function useSeenAllNotifications({
  query,
  state,
  onSuccess,
}: {
  query: { type?: NotificationType };
  state: NotificationContextValues;
  onSuccess: () => void;
}) {
  const [markSeenAll, markSeenAllState] = useWtFetcher({
    onError: (response) => {
      alert(response.error);
    },
    onSuccess,
  });

  function handleMarkSeenAll() {
    if (markSeenAllState.isFetching) return;
    markSeenAll.submit(query, {
      method: "patch",
      action: Routes.actions.notifications.markSeenAll(),
    });
    onSuccess();
  }

  return {
    mutate: handleMarkSeenAll,
    isFetching: markSeenAllState.isFetching,
  };
}
