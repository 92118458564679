import type { SerializeFrom } from "@remix-run/node";
import { useNavigate, useRouteLoaderData, useSearchParams } from "@remix-run/react";

import { RouteIds, Routes } from "~/constants/routes";
import type { SearchQueryParams, SearchResult } from "~/modules/search/types";

export function useSearchFullPage() {
  const searchData = useRouteLoaderData(RouteIds.search.layout()) as SerializeFrom<SearchResult>;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  function search(payload: SearchQueryParams) {
    const newSearchParams = new URLSearchParams(searchParams);

    payload.source
      ? newSearchParams.set("source", payload.source)
      : payload.source === "" && newSearchParams.delete("source");

    payload.queryString
      ? newSearchParams.set("queryString", payload.queryString)
      : payload.queryString === "" && newSearchParams.delete("queryString");

    payload.minPrice
      ? newSearchParams.set("minPrice", payload.minPrice)
      : payload.minPrice === "" && newSearchParams.delete("minPrice");

    payload.maxPrice
      ? newSearchParams.set("maxPrice", payload.maxPrice)
      : payload.maxPrice === "" && newSearchParams.delete("maxPrice");

    payload.limit && newSearchParams.set("limit", payload.limit.toString());

    payload.page && newSearchParams.set("page", payload.page.toString());

    payload.sort && newSearchParams.set("sort", payload.sort);

    navigate(
      Routes.public.search.general({
        queryParams: newSearchParams.toString(),
      }),
    );
  }

  return { searchData, search };
}
