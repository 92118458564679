import { Box, Link, Stack, Typography } from "@mui/material";
import { Link as RLink } from "@remix-run/react";
import { IconAlertTriangleFilled } from "@tabler/icons-react";

import { useNotifications } from "../..";
import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

const INSTRUCTIONS_LINK =
  "https://intercom.help/wishtender/en/articles/8741876-my-stripe-account-was-deactivated";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.STRIPE_ACCOUNT_INFO_UNACTIVATED>;
}) {
  const { toggleNotificationHub } = useNotifications();

  return (
    <NotificationContentContainer notification={notification} to={INSTRUCTIONS_LINK}>
      <Box
        sx={{
          color: "yellow.4",
          backgroundColor: "yellow.0",
          p: 0.75,
          borderRadius: 6,
          height: 40,
          width: 40,
        }}
      >
        <IconAlertTriangleFilled size={28} />
      </Box>
      <Stack
        sx={{
          color: "neutral.7",
          // add here because of notification container has spacing
          // and when add link to notification container, it make more space and break line of notification title
          mr: "-12px !important",
        }}
      >
        <Typography variant="h5">Attention: Your Stripe account was deactivated</Typography>
        <Typography component="span" variant="b5">
          Please follow&nbsp;
          <Link
            component={RLink}
            to={INSTRUCTIONS_LINK}
            sx={{ textDecoration: "none" }}
            onClick={() => toggleNotificationHub(false)}
          >
            <Typography
              component="span"
              variant="b5"
              color="blue.4"
              sx={{
                textDecoration: "underline",
                textDecorationColor: "blue.4",
              }}
            >
              these instructions
            </Typography>
          </Link>
          &nbsp;to reopen your account.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
