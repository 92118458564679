import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";

import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

export function LinkTwitterForm({ onFinished }: { onFinished: () => void }) {
  const [linkTwitter, linkTwitterState] = useWtFetcher({
    onError: (response) => alert(response.error),
    onSuccess: () => onFinished(),
  });

  return (
    <Stack
      component={linkTwitter.Form}
      method="patch"
      action={Routes.actions.settings.linkTwitter()}
      spacing={3}
    >
      <Typography
        component="span"
        variant="h8"
        sx={{
          textAlign: "center",
          fontWeight: "500",
        }}
      >
        Step 1: Link Twitter
      </Typography>
      <Typography
        component="span"
        variant="h7"
        sx={{
          textAlign: "center",
          fontWeight: "normal",
        }}
      >
        Set up Twitter to auto tweet when you receive a gift.
      </Typography>
      <Box
        component="img"
        src="/images/lux_tweet.png"
        alt="autotweet"
        sx={{
          borderRadius: "20px",
          boxShadow: "0px 4px 10px #80808087",
          width: "100%",
        }}
      />
      <LoadingButton type="submit" variant="contained" loading={linkTwitterState.isFetching}>
        <span>Link Twitter</span>
      </LoadingButton>
    </Stack>
  );
}
