import { useEffect, useState } from "react";

import { RouteIds, Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useRawFetcher } from "~/hooks/use-raw-fetcher";

import type { NotificationType, NotificationV2 } from "../types";

const LIMIT = 10;

export function useNotificationsLoadMore({
  query,
  onFetched,
}: {
  query: {
    type?: NotificationType;
    excludeType?: NotificationType;
  };
  onFetched: (data: NotificationV2<NotificationType>[]) => void;
}) {
  const auth = useAuth();
  const [lastNotificationId, setLastNotificationId] = useState<string | undefined>();

  const [fetch, fetchState] = useRawFetcher<any>({
    onError: (response) => {
      alert(response.error);
    },
    onSuccess: (data) => {
      const newNotifications = data.notifications;
      if (newNotifications.length === 0 || newNotifications.length < LIMIT) {
        setLastNotificationId("");
      } else {
        setLastNotificationId(newNotifications[newNotifications.length - 1].id);
      }

      if (newNotifications.length > 0) {
        onFetched(newNotifications);
      }
    },
  });

  function loadMore() {
    if (fetchState.isFetching || !lastNotificationId) return;
    fetch.load(
      Routes.notifications.main({ cursor: lastNotificationId, ...query }),
      RouteIds.notifications.list(),
    );
  }

  // load the first notifications when the component is mounted
  useEffect(() => {
    if (auth.user?.id) {
      fetch.load(
        Routes.notifications.main({ cursor: lastNotificationId, ...query }),
        RouteIds.notifications.list(),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user?.id]);

  return {
    loadMore,
    isFetching: fetchState.isFetching,
  };
}
