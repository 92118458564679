import type { AvatarProps } from "@mui/material";
import { Avatar } from "@mui/material";

import { UserAvatarIcon } from "./icons";

export function UserAvatar({ sx, ...props }: AvatarProps) {
  return (
    <Avatar sx={{ bgcolor: "neutral.4", ...sx }} {...props}>
      <UserAvatarIcon sx={{ width: "50%", height: "50%" }} />
    </Avatar>
  );
}
