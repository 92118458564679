import { Box, Grid, Skeleton, Stack, useTheme } from "@mui/material";
import { IconChevronRight } from "@tabler/icons-react";

export function SearchSuggestedSkeleton() {
  const theme = useTheme();
  return (
    <Stack spacing={4} px={1}>
      {/* Wisher */}
      <Stack spacing={2.5} sx={{ pt: 1 }}>
        <Skeleton variant="rounded" width={72} height={20} />
        <Stack
          spacing={1}
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <Stack spacing={1} direction="row">
            {[...Array.from({ length: 3 }).keys()].map((item) => (
              <Stack key={`wisher_${item}`} spacing={1} sx={{ alignItems: "center", p: 0.5 }}>
                <Skeleton variant="circular" width={64} height={64} />
                <Skeleton variant="rounded" width={112} height={24} />
              </Stack>
            ))}
          </Stack>
          <IconChevronRight color={theme.palette.neutral[5]} />
        </Stack>
      </Stack>

      {/* Gifter */}
      <Stack spacing={2.5} sx={{ pt: 1 }}>
        <Skeleton variant="rounded" width={72} height={20} />
        <Stack
          spacing={1}
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "hidden",
          }}
        >
          <Stack spacing={1} direction="row">
            {[...Array.from({ length: 3 }).keys()].map((item) => (
              <Stack key={`wisher_${item}`} spacing={1} sx={{ alignItems: "center", p: 0.5 }}>
                <Skeleton variant="circular" width={64} height={64} />
                <Skeleton variant="rounded" width={112} height={24} />
              </Stack>
            ))}
          </Stack>
          <IconChevronRight color={theme.palette.neutral[5]} />
        </Stack>
      </Stack>

      {/* Wishes */}
      <Stack spacing={2.5} sx={{ pt: 1 }}>
        <Skeleton variant="rounded" width={72} height={20} />
        <Box>
          <Grid container spacing={2}>
            {[...Array.from({ length: 4 }).keys()].map((item) => (
              <Grid key={`gifter_${item}`} item xs={6}>
                <Stack direction="row" key={`gifter_${item}`} spacing={1.5} sx={{ p: 0.5 }}>
                  <Skeleton variant="rounded" width={72} height={72} />
                  <Stack sx={{ justifyContent: "space-between" }}>
                    <Skeleton variant="rounded" width={106} height={22} />
                    <Skeleton variant="rounded" width={106} height={22} />
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
}
