import { Link, ListItemText, MenuItem, Switch, Typography } from "@mui/material";
import { Link as RLink } from "@remix-run/react";
import { useState } from "react";

import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

export function UpdatePublicizeStatsMenuItem({ publicizeStats }: { publicizeStats?: boolean }) {
  const [publicizeStatsState, setPublicizeStatsState] = useState(publicizeStats);

  const [updateUserSettings] = useWtFetcher({
    onError: (response) => {
      alert(response.error);
      setPublicizeStatsState(!publicizeStatsState);
    },
  });

  function handleChange(isPublic: boolean) {
    setPublicizeStatsState(isPublic);
    updateUserSettings.submit(
      {
        publicizeStats: isPublic.toString(),
      },
      { method: "patch", action: Routes.actions.settings.updateUserSettings() },
    );
  }

  return (
    <MenuItem sx={{ py: 1 }} onClick={() => handleChange(!publicizeStatsState)}>
      <ListItemText sx={{ whiteSpace: "normal" }}>
        <Typography component="span" variant="b5">
          Display my account on the public{" "}
          <Link
            component={RLink}
            to={Routes.leaderboard.main()}
            onClick={(event) => event.stopPropagation()}
          >
            <Typography component="span" variant="b5">
              Leaderboard
            </Typography>
          </Link>
        </Typography>
      </ListItemText>
      <Switch
        checked={publicizeStatsState}
        onChange={(event) => handleChange(event.target.checked)}
      />
    </MenuItem>
  );
}
