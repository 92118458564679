import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useSearchParams } from "@remix-run/react";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { TextField } from "~/components/form/text-field";
import { HandleProgressBar } from "~/components/handle-progress-bar";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import { useValidateHandleInput } from "~/modules/auth";
import { getCurrencyList } from "~/modules/currency";
import type { Auth } from "~/types";
import { themeV2 } from "~/utils/theme";

import { GifterSetupSchema } from "../schemas";

export function GifterOnboard({ auth }: { auth: Auth }) {
  const [searchParams] = useSearchParams();
  const currencyList = useMemo(() => getCurrencyList(undefined, true), []);
  const form = useForm<z.infer<typeof GifterSetupSchema>>({
    resolver: zodResolver(GifterSetupSchema),
    defaultValues: {
      handle: "",
      name: "",
      currency: "USD",
      isPublic: false,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [submit, submitState] = useWtFetcher({ onError: (response) => alert(response.error) });

  const { getHandleStatus, validateState } = useValidateHandleInput(form, undefined, true);

  const handleStatus = getHandleStatus();

  const redirectUrl = searchParams.get("redirect");
  const urlSearchParams = new URLSearchParams();
  if (redirectUrl) {
    urlSearchParams.set("redirect", redirectUrl);
  }

  function getIsFormSubmittable() {
    if (!form.formState.isValid) return false;
    if (validateState.isFetching) return false;
    if (validateState.isError) return false;
    if (submitState.isSubmitting) return false;

    return handleStatus === "available";
  }
  return (
    <ThemeProvider theme={themeV2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          minHeight: "calc(100vh - 72px)",
        }}
      >
        <Container
          component={Paper}
          sx={{
            py: 4,
            px: 4,
            maxWidth: { xs: "100%", sm: 444 },
            borderRadius: { xs: 0, sm: 2 },
            height: { xs: "100%", sm: "auto" },
          }}
        >
          {auth && auth.user && (
            <Typography
              color={themeV2.palette.neutral[5]}
              sx={{
                position: "relative",
                top: "-2rem",
                display: { sm: "none" },
              }}
            >
              Logged in as: <span style={{ fontWeight: "bold" }}>{auth.user.email}</span>
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="column"
            method="post"
            component={submit.Form}
            action={`${Routes.actions.gifter.profile.setup()}?${urlSearchParams.toString()}`}
          >
            <Typography component="h1" variant="h7">
              Setting up your profile
            </Typography>
            <Box mt={2} />
            <Typography component="p">
              You can change your <b>profile url</b> and <b>display name</b> at any time.
            </Typography>
            <Box mt={4} />
            <Controller
              name="handle"
              control={form.control}
              render={({ field }) => {
                return (
                  <TextField.Root fullWidth>
                    <TextField.Label>Profile URL</TextField.Label>
                    <TextField.Input
                      autoComplete="off"
                      spellCheck="false"
                      placeholder="yourname"
                      startAdornment={
                        <InputAdornment position="start">
                          <span>wishtender.com/gifter/</span>
                        </InputAdornment>
                      }
                      {...field}
                    />

                    <HandleProgressBar
                      gifter
                      handle={field.value}
                      handleStatus={handleStatus}
                      errors={form.formState.errors}
                    />
                  </TextField.Root>
                );
              }}
            />

            <Box mt={2} />

            <Controller
              name="name"
              control={form.control}
              render={({ field, fieldState }) => (
                <TextField.Root fullWidth>
                  <TextField.Label>Display Name</TextField.Label>
                  <TextField.Input placeholder="Your display name" {...field} />
                  {fieldState.error && (
                    <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
                  )}
                </TextField.Root>
              )}
            />

            <Box mt={2} />

            <Controller
              name="currency"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <FormLabel id="gifter-currency-label">Currency</FormLabel>
                    <Select labelId="gifter-currency-label" variant="outlined" {...field}>
                      {currencyList.map((currency: any) => {
                        return (
                          <MenuItem
                            key={currency.code}
                            value={currency.code}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography component="span" color="limegreen">
                              {currency.symbol}
                            </Typography>

                            <Typography component="span" pl={1}>
                              {currency.name}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                );
              }}
            />

            <Box mt={3} />

            <Controller
              name="isPublic"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    control={<Checkbox checked={field.value} {...field} />}
                    label="Public Profile"
                  />
                );
              }}
            />

            <Box mt={2} />

            <Button type="submit" variant="contained" disabled={!getIsFormSubmittable()}>
              {submitState.isFetching ? <span>Submitting...</span> : <span>Submit</span>}
            </Button>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
