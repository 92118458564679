import { z } from "zod";

export const UpdateUserEmailSchema = z.object({
  email: z.string().trim().nonempty("Email is required.").email("Enter a valid e-mail address"),
  password: z
    .string()
    .trim()
    .nonempty("Password is required.")
    .min(8, "Password must be at least 8 characters"),
});

export const DeleteWisherSchema = z.object({
  password: z
    .string()
    .trim()
    .nonempty("Password is required.")
    .min(8, "Password must be at least 8 characters"),
  phrase: z
    .string()
    .nonempty("Phrase is required.")
    .refine((value) => value === "permanently delete", {
      message: "Phrase must match 'permanently delete'",
    }),
  wisherId: z.string(),
});

export const UpdateDisplayCurrencySchema = z.object({
  displayCurrency: z.string().trim().nonempty("Currency is required."),
  wisherId: z.string(),
});

export const UpdateSurpriseGiftSettingsSchema = z.object({
  enabled: z.preprocess((v) => v === true || v === "true", z.boolean()).default(false),
  minAmount: z
    .string()
    .refine((value) => !Number.isNaN(Number.parseFloat(value)) && Number.parseFloat(value) >= 0, {
      message: "Min amount must be greater than or equal to 0",
    }),
  wishlistId: z.string(),
});

export const UpdateUserSettingsSchema = z.object({
  detailedGiftNotification: z.preprocess((v) => v === true || v === "true", z.boolean()).optional(),
  publicizeStats: z.preprocess((v) => v === true || v === "true", z.boolean()).optional(),
});

export const UpdateGifterSettingsSchema = z.object({
  gifterId: z.string(),
  allowReceiveRequests: z.preprocess((v) => v === true || v === "true", z.boolean()).optional(),
});

export const UpdateTweetSettingsSchema = z.object({
  blockPseudonym: z.preprocess((v) => v === true || v === "true", z.boolean()).optional(),
  includeImages: z.preprocess((v) => v === true || v === "true", z.boolean()).optional(),
  includeLink: z.preprocess((v) => v === true || v === "true", z.boolean()).optional(),
  autoTweet: z.preprocess((v) => v === true || v === "true", z.boolean()).optional(),
  totalThreshold: z
    .string()
    .refine((value) => !Number.isNaN(Number.parseFloat(value)) && Number.parseFloat(value) >= 0, {
      message: "Min amount must be greater than or equal to 0",
    })
    .optional(),
});
