import { Box, Stack, Toolbar } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "@remix-run/react";

import { Routes } from "~/constants/routes";
import type { Auth } from "~/types";
import { themeV2 } from "~/utils/theme";

import { HeaderLogoMobile } from "../header-logo";
import { AccountNavMenuMobile } from "./account-nav-menu.mobile";
import { CurrenciesMenu } from "./currencies-menu";
import { HeaderAppBar } from "./header-app-bar";
import { GifterProfile, LeaderboardLinkMobile, LoginActionMobile } from "./header-links";

function WisherMenu() {
  return (
    <>
      <LeaderboardLinkMobile />
      <AccountNavMenuMobile />
    </>
  );
}

// eslint-disable-next-line sonarjs/no-identical-functions
function GifterMenu({ gifter }: { gifter: NonNullable<Auth["gifter"]> }) {
  return (
    <>
      <GifterProfile gifter={gifter} />
      <LeaderboardLinkMobile />
      <AccountNavMenuMobile />
    </>
  );
}

function GuestMenu() {
  return (
    <>
      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
        <LoginActionMobile />
        <CurrenciesMenu />
      </Stack>
      <AccountNavMenuMobile />
    </>
  );
}

export function MobileGeneralHeader({ auth }: { auth: Auth }) {
  let menu = <GuestMenu />;
  if (auth.wisher) {
    menu = <WisherMenu />;
  }
  if (auth.gifter) {
    menu = <GifterMenu gifter={auth.gifter} />;
  }

  return (
    <ThemeProvider theme={themeV2}>
      <HeaderAppBar screenSize="mobile">
        <Toolbar sx={{ px: 0 }}>
          <Stack direction="row" alignItems="center">
            <Link to={Routes.landing.home()}>
              <HeaderLogoMobile />
            </Link>
          </Stack>

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" sx={{ alignItems: "center" }} spacing={1}>
            {menu}
          </Stack>
        </Toolbar>
      </HeaderAppBar>
    </ThemeProvider>
  );
}
