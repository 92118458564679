import { ButtonBase, Tooltip } from "@mui/material";
import type { TablerIconsProps } from "@tabler/icons-react";
import { IconLink } from "@tabler/icons-react";

import { useCopyWithTimeout } from "~/hooks/use-copy-with-timeout";

type Props = {
  link: string;
  iconStyles: TablerIconsProps;
};

export function CopyLinkButton({ link, iconStyles = {} }: Props) {
  const [copiedText, copy] = useCopyWithTimeout();

  return (
    <Tooltip
      sx={{ marginLeft: ".2em" }}
      title={copiedText ? "Link copied!" : "Copy link"}
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset: [0, -10] } }],
      }}
    >
      <ButtonBase
        type="button"
        onClick={async (event) => {
          event.stopPropagation();
          await copy(link);
        }}
      >
        <IconLink {...iconStyles} />
      </ButtonBase>
    </Tooltip>
  );
}
