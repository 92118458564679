/* eslint-disable no-unused-vars */

import uniqBy from "lodash/uniqBy";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useReducer } from "react";

import { useAuth } from "~/hooks/use-auth";

import { useNewNotificationsCount } from "../hooks/use-new-notifications-count";
import { useNotificationsLoadMore } from "../hooks/use-notifications-load-more";
import { useSeenAllNotifications } from "../hooks/use-seen-all-notifications";
import { useSeenNotifications } from "../hooks/use-seen-notifications";
import { NotificationType, type NotificationV2 } from "../types";

export type NotificationContextValues = {
  isNotificationHubOpen: boolean;
  totalNewNotifications: number;
  totalNewNotices: number;
  notifications: NotificationV2<NotificationType>[];
  notices: NotificationV2<NotificationType>[];
  toggleNotificationHub: (open: boolean) => void;
  loadMoreNotificationsQuery: {
    loadMore: () => void;
    isFetching: boolean;
  };
  loadMoreNoticesQuery: {
    loadMore: () => void;
    isFetching: boolean;
  };
  markSeenMutation: {
    mutate: (notification: NotificationV2<NotificationType>) => void;
    isFetching: boolean;
  };
  markSeenAllNotificationsMutation: {
    mutate: () => void;
    isFetching: boolean;
  };
  markSeenAllNoticesMutation: {
    mutate: () => void;
    isFetching: boolean;
  };
  reset: () => void;
  // WARNING
  // This function is providing too much capability.
  // Be careful when use this function while we're think more about solution for it.
  dangerouslyUpdateNotification: (notification: NotificationV2<NotificationType>) => void;
};

export enum NotificationActionType {
  SEEN_NOTIFICATION = "seenNotification",
  SEEN_ALL_NOTIFICATION = "seenAllNotification",
  SEEN_ALL_NOTICES = "seenAllNotices",
  UPDATE_NEW_NOTIFICATIONS_COUNT = "updateNewNotificationsCount",
  UPDATE_NEW_NOTICES_COUNT = "updateNewNoticesCount",
  UPDATE_NOTIFICATIONS = "updateNotifications",
  UPDATE_NOTIFICATION = "updateNotification",
  UPDATE_POPOVER_STATE = "updatePopoverState",
  UPDATE_NOTICES = "updateNotices",
  ADD_MORE_NOTICES = "addMoreNotices",
  ADD_MORE_NOTIFICATIONS = "addMoreNotifications",
  RESET = "reset",
}

export type NotificationAction = {
  type: NotificationActionType;
  payload?: Partial<NotificationContextValues> & {
    notification?: NotificationV2<NotificationType>;
  };
};

const INIT_STATE: NotificationContextValues = {
  isNotificationHubOpen: false,
  totalNewNotifications: 0,
  totalNewNotices: 0,
  notifications: [],
  notices: [],
  toggleNotificationHub: () => {},
  loadMoreNotificationsQuery: {
    loadMore: () => {},
    isFetching: false,
  },
  loadMoreNoticesQuery: {
    loadMore: () => {},
    isFetching: false,
  },
  markSeenMutation: {
    mutate: () => {},
    isFetching: false,
  },
  markSeenAllNotificationsMutation: {
    mutate: () => {},
    isFetching: false,
  },
  markSeenAllNoticesMutation: {
    mutate: () => {},
    isFetching: false,
  },
  reset: () => {},
  dangerouslyUpdateNotification: () => {},
};

const NotificationContext = createContext<NotificationContextValues | string>(
  "Invalid usage of NotificationsContext. Please ensure you wrap your components with NotificationsContextProvider.",
);

function notificationReducer(
  notificationValues: NotificationContextValues,
  action: NotificationAction,
) {
  switch (action.type) {
    case NotificationActionType.SEEN_NOTIFICATION: {
      return {
        ...notificationValues,
        notifications: notificationValues.notifications.map((notification) => {
          if (notification.id === action.payload?.notification?.id) {
            return {
              ...action.payload?.notification,
              seen: true,
            };
          }
          return notification;
        }),
        totalNewNotifications: notificationValues.totalNewNotifications - 1,
      };
    }
    case NotificationActionType.SEEN_ALL_NOTIFICATION: {
      return {
        ...notificationValues,
        totalNewNotifications: 0,
        notifications: notificationValues.notifications.map((notification) => {
          if (!notification.seen) {
            return {
              ...notification,
              seen: true,
            };
          }
          return notification;
        }),
      };
    }
    case NotificationActionType.SEEN_ALL_NOTICES: {
      return {
        ...notificationValues,
        totalNewNotices: 0,
        notices: notificationValues.notices.map((notice) => {
          if (!notice.seen) {
            return {
              ...notice,
              seen: true,
            };
          }
          return notice;
        }),
      };
    }
    case NotificationActionType.UPDATE_NOTIFICATIONS: {
      return {
        ...notificationValues,
        notifications: action.payload?.notifications ?? [],
      };
    }
    case NotificationActionType.UPDATE_NOTIFICATION: {
      const notificationIndex = notificationValues.notifications.findIndex(
        (notification) => notification.id === action.payload?.notification?.id,
      );
      if (notificationIndex < 0 || !action.payload?.notification) {
        return notificationValues;
      }

      return {
        ...notificationValues,
        notifications: [
          ...notificationValues.notifications.slice(0, notificationIndex),
          action.payload?.notification,
          ...notificationValues.notifications.slice(notificationIndex + 1),
        ],
      };
    }
    case NotificationActionType.ADD_MORE_NOTIFICATIONS: {
      const moreNotifications = action.payload?.notifications ?? [];
      const deduplicatedNotifications = uniqBy(
        [...notificationValues.notifications, ...moreNotifications],
        "id",
      );

      return {
        ...notificationValues,
        notifications: deduplicatedNotifications,
      };
    }
    case NotificationActionType.UPDATE_NOTICES: {
      return {
        ...notificationValues,
        notices: action.payload?.notices ?? [],
      };
    }
    case NotificationActionType.ADD_MORE_NOTICES: {
      const moreNotices = action.payload?.notices ?? [];
      const deduplicatedNotices = uniqBy([...notificationValues.notices, ...moreNotices], "id");

      return {
        ...notificationValues,
        notices: deduplicatedNotices,
      };
    }
    case NotificationActionType.UPDATE_NEW_NOTIFICATIONS_COUNT: {
      return {
        ...notificationValues,
        totalNewNotifications: action.payload?.totalNewNotifications ?? 0,
      };
    }
    case NotificationActionType.UPDATE_NEW_NOTICES_COUNT: {
      return {
        ...notificationValues,
        totalNewNotices: action.payload?.totalNewNotices ?? 0,
      };
    }
    case NotificationActionType.UPDATE_POPOVER_STATE: {
      return {
        ...notificationValues,
        isNotificationHubOpen: action.payload?.isNotificationHubOpen ?? false,
      };
    }
    case NotificationActionType.RESET: {
      return {
        ...notificationValues,
        totalNewNotifications: 0,
        totalNewNotices: 0,
        notifications: [],
        notices: [],
      };
    }
    default: {
      throw new Error("Unknown action: " + action.type);
    }
  }
}

export function NotificationContextProvider({ children }: { children: ReactNode }) {
  const auth = useAuth();
  const [state, dispatch] = useReducer(notificationReducer, INIT_STATE);
  const { fetchNewNotificationsCount } = useNewNotificationsCount({
    query: { excludeType: NotificationType.ORDER_PAID },
    onFetched: (total) =>
      dispatch({
        type: NotificationActionType.UPDATE_NEW_NOTIFICATIONS_COUNT,
        payload: {
          totalNewNotifications: total,
        },
      }),
  });
  const { fetchNewNotificationsCount: fetchNewNoticeCount } = useNewNotificationsCount({
    query: { type: NotificationType.NEW_NOTICE },
    onFetched: (total) =>
      dispatch({
        type: NotificationActionType.UPDATE_NEW_NOTICES_COUNT,
        payload: {
          totalNewNotices: total,
        },
      }),
  });

  const loadMoreNotificationsQuery = useNotificationsLoadMore({
    query: { excludeType: NotificationType.ORDER_PAID },
    onFetched: (moreNotifications) =>
      dispatch({
        type: NotificationActionType.ADD_MORE_NOTIFICATIONS,
        payload: {
          notifications: moreNotifications,
        },
      }),
  });
  const loadMoreNoticesQuery = useNotificationsLoadMore({
    query: { type: NotificationType.NEW_NOTICE },
    onFetched: (moreNotices) =>
      dispatch({
        type: NotificationActionType.ADD_MORE_NOTICES,
        payload: {
          notices: moreNotices,
        },
      }),
  });

  const markSeenMutation = useSeenNotifications({ dispatch });
  const markSeenAllNotificationsMutation = useSeenAllNotifications({
    query: {},
    state,
    onSuccess: () => {
      dispatch({
        type: NotificationActionType.SEEN_ALL_NOTIFICATION,
      });
    },
  });
  const markSeenAllNoticesMutation = useSeenAllNotifications({
    query: { type: NotificationType.NEW_NOTICE },
    state,
    onSuccess: () => {
      dispatch({
        type: NotificationActionType.SEEN_ALL_NOTICES,
      });
    },
  });

  function toggleNotificationHub(isOpen: boolean) {
    dispatch({
      type: NotificationActionType.UPDATE_POPOVER_STATE,
      payload: {
        isNotificationHubOpen: isOpen,
      },
    });
  }

  function reset() {
    dispatch({
      type: NotificationActionType.RESET,
    });
  }

  function dangerouslyUpdateNotification(notification: NotificationV2<NotificationType>) {
    dispatch({
      type: NotificationActionType.UPDATE_NOTIFICATION,
      payload: {
        notification,
      },
    });
  }

  useEffect(() => {
    if (auth.user?.id) {
      fetchNewNotificationsCount();
      fetchNewNoticeCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user?.id]);

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        // TODO: Sometimes the total is negative, so we need to make sure it's not negative and find out the root cause later (maybe race condition?)
        totalNewNotices: Math.max(state.totalNewNotices, 0),
        totalNewNotifications: Math.max(state.totalNewNotifications, 0),
        toggleNotificationHub,
        loadMoreNotificationsQuery,
        loadMoreNoticesQuery,
        reset,
        markSeenMutation,
        markSeenAllNotificationsMutation,
        markSeenAllNoticesMutation,
        dangerouslyUpdateNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotifications() {
  const context = useContext(NotificationContext);

  if (typeof context === "string") {
    throw new TypeError(context);
  }

  return context;
}
