import { Divider } from "@mui/material";

import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";

import { DeviceElectronicIcon } from "../icons/device-electronic-icon";
import { StyledMenuItem } from "./account-nav-menu.common";

export function AdminMenuItem({ closeMenu }: { closeMenu: () => void }) {
  const { user } = useAuth();

  if (!user?.admin) {
    return <></>;
  }

  return (
    <>
      <StyledMenuItem
        to={Routes.admin.main()}
        onClick={closeMenu}
        icon={<DeviceElectronicIcon />}
        text="Admin"
      />
      <Divider sx={{ borderColor: "gray.2" }} />
    </>
  );
}
