import { Box, Link, Stack, Toolbar } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Link as RLink } from "@remix-run/react";

import { Routes } from "~/constants/routes";
import { SearchBarDesktop } from "~/modules/search/components/search-bar-desktop";
import type { Auth } from "~/types";
import { themeV2 } from "~/utils/theme";

import { HeaderLogoDesktop, HeaderLogoSmallDesktop } from "../header-logo";
import { AccountNavMenuDesktop } from "./account-nav-menu.desktop";
import { CurrenciesMenu } from "./currencies-menu";
import { HeaderAppBar } from "./header-app-bar";
import {
  AuthActions,
  CartLink,
  HelpCenterLink,
  LeaderboardLinkDesktop,
  NotificationLinkDesktop,
  OrderHistoryLink,
  WishTracker,
} from "./header-links";

function WisherMenu() {
  return (
    <>
      <LeaderboardLinkDesktop />
      <Stack direction="row" spacing={2} sx={{ mx: 1, alignItems: "center" }}>
        <WishTracker />
        <NotificationLinkDesktop />
        <AccountNavMenuDesktop />
      </Stack>
    </>
  );
}

function GifterMenu() {
  return (
    <>
      <LeaderboardLinkDesktop />
      <Stack direction="row" spacing={1} sx={{ mx: 1, alignItems: "center" }}>
        <CartLink />
        <OrderHistoryLink />
        <NotificationLinkDesktop />
        <AccountNavMenuDesktop />
      </Stack>
    </>
  );
}

function GuestMenu() {
  return (
    <>
      <HelpCenterLink />
      <LeaderboardLinkDesktop />
      <Stack direction="row" spacing={1} sx={{ mx: 1, alignItems: "center" }}>
        <CurrenciesMenu />
        <CartLink />
        <AuthActions />
      </Stack>
    </>
  );
}

export function DesktopGeneralHeader({ auth }: { auth: Auth }) {
  let menu = <GuestMenu />;
  if (auth.wisher) {
    menu = <WisherMenu />;
  }
  if (auth.gifter) {
    menu = <GifterMenu />;
  }
  return (
    <ThemeProvider theme={themeV2}>
      <HeaderAppBar screenSize="desktop">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Stack sx={{ alignItems: "flex-start" }}>
            <Link component={RLink} to={Routes.landing.home()}>
              <Box sx={{ display: { xs: "none", xl: "block" } }}>
                <HeaderLogoDesktop />
              </Box>
              <Box sx={{ display: { xs: "none", lg: "block", xl: "none" } }}>
                <HeaderLogoSmallDesktop />
              </Box>
            </Link>
          </Stack>

          <Stack sx={{ flex: 1, alignItems: "center" }}>
            {auth.user?.featureFlags.includes("search") && <SearchBarDesktop />}
          </Stack>

          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "flex-end" }}>
            {menu}
          </Stack>
        </Toolbar>
      </HeaderAppBar>
    </ThemeProvider>
  );
}
