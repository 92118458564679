import { z } from "zod";

import { NotificationType } from "./types";

export const SeenAllNotificationsSchema = z.object({
  type: z.nativeEnum(NotificationType).optional(),
});

export const SeenNotificationSchema = z.object({
  id: z.string(),
});
