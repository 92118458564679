import { Box, Container, ThemeProvider, Typography } from "@mui/material";
import { Outlet } from "@remix-run/react";

import { GeneralLayout } from "~/components/general-layout";
import { themeV2 } from "~/utils/theme";

import { ActivitiesSection } from "../components/referrals/activities-section";
import { TopActiveRefereesSection } from "../components/referrals/top-active-referees-section";

export function ReferralsPage() {
  return (
    <GeneralLayout>
      <ThemeProvider theme={themeV2}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            py: { xs: 5, sm: 6, md: 8 },
            px: { xs: 1.5, md: 3 },
            minHeight: "calc(100vh - 70px)",
          }}
        >
          <Typography variant="bh9" color="neutral.7">
            Referral Dashboard
          </Typography>
          <Box sx={{ mt: { xs: 2, sm: 4 } }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              gap: {
                xs: 3,
                lg: 0,
              },
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                borderRightWidth: { xs: 0, lg: 1 },
                borderRightColor: "neutral.2",
                borderRightStyle: "solid",
                pr: { xs: 0, lg: 3, xl: 3.5 },
              }}
            >
              <Outlet />
            </Box>
            <Box
              sx={{
                flexShrink: 0,
                width: { xs: "100%", lg: "224px", xl: "264px" },
                pl: { xs: 0, lg: 2 },
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                  lg: "column",
                },
                gap: { xs: 3, lg: 4 },
                "& > div": {
                  flex: {
                    xs: 1,
                    lg: "none",
                  },
                },
              }}
            >
              <ActivitiesSection />
              <TopActiveRefereesSection />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </GeneralLayout>
  );
}
