import type { AlertColor, SnackbarProps } from "@mui/material";
import {
  Alert,
  IconButton,
  Snackbar,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  IconAlertCircleFilled,
  IconCircleCheckFilled,
  IconInfoCircleFilled,
  IconInfoTriangleFilled,
  IconX,
} from "@tabler/icons-react";
import type { ReactNode } from "react";

import { themeV2 } from "~/utils/theme";

type SnackBarType = AlertColor;

type ToastProps = Omit<SnackbarProps, "anchorOrigin" | "autoHideDuration" | "onClose"> & {
  onClose: () => void;
  message: ReactNode;
  type: SnackBarType;
};

export function Toast(props: ToastProps) {
  return (
    <ThemeProvider theme={themeV2}>
      <ToastContent {...props} />
    </ThemeProvider>
  );
}

function ToastContent({ onClose, message, type, ...others }: ToastProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: isMobileScreen ? "center" : "right",
      }}
      autoHideDuration={6000}
      onClose={onClose}
      {...others}
    >
      <Alert
        variant="outlined"
        iconMapping={{
          success: (
            <IconCircleCheckFilled
              style={{
                color: theme.palette[type][4],
              }}
              size={20}
            />
          ),
          info: (
            <IconInfoCircleFilled
              style={{
                color: theme.palette[type][4],
              }}
              size={20}
            />
          ),
          warning: (
            <IconInfoTriangleFilled
              style={{
                color: theme.palette[type][4],
              }}
              size={20}
            />
          ),
          error: (
            <IconAlertCircleFilled
              style={{
                color: theme.palette[type][4],
              }}
              size={20}
            />
          ),
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
            sx={{ mb: 0.5 }}
          >
            <IconX size={14} color={theme.palette[type][6]} />
          </IconButton>
        }
        severity={type}
        sx={{
          display: "flex",
          alignItems: "center",
          boxShadow: "0px 12px 20px 0px rgba(52, 87, 140, 0.12)",
          borderRadius: 2,
          bgcolor: theme.palette[type][0],
          maxWidth: 476,
        }}
      >
        <Typography component="span" variant="sh4">
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
}
