import { Box, Stack, Typography } from "@mui/material";
import { Link } from "@remix-run/react";
import { IconAlertTriangleFilled } from "@tabler/icons-react";

import { Routes } from "~/constants/routes";

import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.INSTANT_PAYOUT_FAILED>;
}) {
  return (
    <NotificationContentContainer notification={notification} to={Routes.wisher.balance.history()}>
      <Box
        sx={{
          color: "yellow.4",
          backgroundColor: "yellow.0",
          p: 0.75,
          borderRadius: 6,
          height: 40,
          width: 40,
        }}
      >
        <IconAlertTriangleFilled size={28} />
      </Box>
      <Stack>
        <Typography variant="b5" color="neutral.7" component="span">
          Your instant payout request&nbsp;
          <Typography
            component={Link}
            to={notification.data.stripePayoutLink}
            variant="b5"
            color="blue.4"
            sx={{
              textDecoration: "underline",
              position: "relative",
              zIndex: 2,
            }}
          >
            ran into an error with Stripe
          </Typography>
          &nbsp;and couldn’t be processed. <br />
          Try initiating another payout in your Instant Payouts Dashboard.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
