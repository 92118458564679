import { Box, Typography } from "@mui/material";
import { Link } from "@remix-run/react";
import { IconPlus, IconUserPlus } from "@tabler/icons-react";

import { Button } from "~/components/button";
import { InfoCircleTwoTonesIcon } from "~/components/icons";
import { useAuth } from "~/hooks/use-auth";
import { useSearchParamsNoReload } from "~/hooks/use-search-params-no-reload";
import { ReferralCodeCard } from "~/modules/referrals";

import { useWisherReferralCodes } from "../../hooks/use-wisher-referrals";

export function CodesSection() {
  const auth = useAuth();
  const referralCodes = useWisherReferralCodes();
  const [params, setParams] = useSearchParamsNoReload();

  function handleOpenCreate() {
    const newParams = new URLSearchParams(params);
    newParams.set("create", "code");
    setParams(newParams);
  }

  const canCreateCode = auth.user?.featureFlags?.includes("referral");

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          color="neutral.7"
          component="h3"
          sx={{
            typography: { xs: "h7", sm: "h8" },
          }}
        >
          My Codes
        </Typography>
        {canCreateCode && referralCodes.length > 0 && (
          <Button
            startIcon={<IconPlus />}
            variant="subtle"
            sx={{ ml: "auto" }}
            onClick={handleOpenCreate}
          >
            Create New Code
          </Button>
        )}
      </Box>
      <Box sx={{ mt: "20px" }} />
      {referralCodes.length === 0 && (
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            px: 5,
            py: { xs: 3, md: 5 },
            bgcolor: "neutral.0",
            boxShadow: (theme) => theme.palette.shadows.primary[200],
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              borderRadius: 999,
              bgcolor: "neutral.2",
              width: 64,
              height: 64,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "neutral.6",
            }}
          >
            <IconUserPlus size={32} />
          </Box>
          <Box sx={{ mt: 3 }} />
          <Typography variant="bh7" color="neutral.7" component="div">
            Create your first referral code
          </Typography>
          <Box sx={{ mt: 2 }} />
          <Typography variant="b5" color="neutral.5" component="div">
            You'll get commissions once people sign up using your referral code and be active on
            WishTender.
          </Typography>
          {canCreateCode && (
            <>
              <Box sx={{ mt: 4 }} />
              <Button startIcon={<IconPlus />} onClick={handleOpenCreate}>
                Create New Code
              </Button>
            </>
          )}
        </Box>
      )}
      {referralCodes.length > 0 && (
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(2, minmax(0, 1fr))",
              },
              gap: 1.5,
            }}
          >
            {referralCodes.map((code) => (
              <ReferralCodeCard key={code.id} code={code} />
            ))}
          </Box>
          <Box sx={{ mt: 2 }} />
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography
              color="neutral.7"
              component="span"
              sx={{ height: "24px", mt: "-2px", mr: 1 }}
            >
              <InfoCircleTwoTonesIcon />
            </Typography>
            <Typography color="neutral.6">
              If you'd like to edit an existing code, reach out to{" "}
              <Typography
                component={Link}
                to="mailto:support@wishtender.com"
                sx={{ color: "blue.4", textDecoration: "underline" }}
              >
                WishTender Support
              </Typography>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
