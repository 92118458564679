import {
  Badge,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useMatches } from "@remix-run/react";
import type { MouseEvent } from "react";
import { useState } from "react";

import { RouteIds, Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useCartItemCount } from "~/hooks/use-cart-item-count";
import {
  NotificationButtonDesktop,
  NotificationButtonMobile,
  NotificationButtonTablet,
  useNotifications,
} from "~/modules/notifications";
import { useWisher } from "~/modules/wisher/context/wisher-context";
import type { Auth } from "~/types";
import { themeV2 } from "~/utils/theme";

import { EcommerceGiftIcon, LeaderboardIcon, ShoppingIcon, ShoppingListIcon } from "../icons";
import { UserAvatar } from "../user-avatar";

export function CartLink() {
  const cartItemCount = useCartItemCount();
  const matches = useMatches();

  /* Is in cart page */
  const isSelected = matches.find((match) => match.id === RouteIds.cart.layout());

  return (
    <Tooltip title="Cart" aria-label="Cart">
      <IconButton
        component={Link}
        to={Routes.cart.default()}
        size="large"
        aria-label="go to cart"
        sx={{
          py: 1,
          px: 1,
          borderRadius: 2,
          "&:hover": { bgcolor: "gray.2", textDecoration: "none" },
          bgcolor: isSelected ? "teal.0" : "gray.0",
          color: isSelected ? "teal.4" : "neutral.5",
        }}
      >
        <Badge badgeContent={cartItemCount} color="error">
          <ShoppingIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}

export function OrderHistoryLink() {
  const matches = useMatches();

  /* Is in order history page */
  const isSelected = matches.find((match) => match.id === RouteIds.gifter.order.list());

  return (
    <Tooltip key="Order History" title="Order History">
      <IconButton
        component={Link}
        to={Routes.gifter.orderList()}
        size="large"
        aria-label="Order History"
        sx={{
          px: 1,
          py: 1,
          borderRadius: 2,
          "&:hover": { bgcolor: "gray.2", textDecoration: "none" },
          bgcolor: isSelected ? "teal.0" : "gray.0",
          color: isSelected ? "teal.4" : "neutral.5",
        }}
      >
        <ShoppingListIcon />
      </IconButton>
    </Tooltip>
  );
}

export function LeaderboardLinkDesktop() {
  const matches = useMatches();

  /* Is in leaderboard page */
  const isSelected = matches.find((match) => match.id === RouteIds.leaderboard.layout());

  return (
    <Button
      component={Link}
      to={Routes.leaderboard.main()}
      sx={{
        color: isSelected ? "teal.4" : "gray.6",
        px: 1.5,
        py: 0.75,
        borderRadius: 2,
        "&:hover": { bgcolor: "gray.2", textDecoration: "none" },
        bgcolor: isSelected ? "teal.0" : "gray.0",
      }}
    >
      <Typography variant={isSelected ? "h5" : "b5"}>Leaderboard</Typography>
    </Button>
  );
}

export function LeaderboardLinkMobile() {
  const matches = useMatches();

  /* Is in leaderboard page */
  const isSelected = matches.find((match) => match.id === RouteIds.leaderboard.layout());

  return (
    <Tooltip title="Leaderboard" aria-label="Leaderboard">
      <IconButton
        component={Link}
        to={Routes.leaderboard.main()}
        size="large"
        aria-label="leaderboard"
        sx={{
          px: 1,
          py: 1,
          borderRadius: 2,
          "&:hover": { bgcolor: "gray.2", textDecoration: "none" },
          bgcolor: isSelected ? "teal.0" : "gray.0",
          color: isSelected ? "teal.4" : "neutral.5",
        }}
      >
        <LeaderboardIcon />
      </IconButton>
    </Tooltip>
  );
}

export function HelpCenterLink() {
  return (
    <Button
      component={Link}
      to={Routes.external.intercom()}
      sx={{
        color: "gray.6",
        px: 1.5,
        py: 0.75,
        borderRadius: 2,
        "&:hover": { bgcolor: "gray.2", textDecoration: "none" },
      }}
    >
      <Typography variant="b5">FAQ</Typography>
    </Button>
  );
}

export function GifterProfile({ gifter }: { gifter: NonNullable<Auth["gifter"]> }) {
  return (
    <Tooltip title="Profile" aria-label="profile">
      <IconButton
        component={Link}
        to={Routes.public.gifter({ username: gifter.handle })}
        size="large"
        aria-label="profile"
        sx={{ px: 1, py: 1 }}
      >
        <UserAvatar sx={{ width: 32, height: 32 }} src={gifter.avatarUrl} />
      </IconButton>
    </Tooltip>
  );
}

export function PersonalWishlist({ wisher }: { wisher: NonNullable<Auth["wisher"]> }) {
  return (
    <Tooltip title="Wishlist" aria-label="wishlist">
      <IconButton
        component={Link}
        to={Routes.public.wisher({ username: wisher.handle })}
        size="large"
        aria-label="wishlist"
        sx={{ px: 1, py: 1 }}
      >
        <UserAvatar sx={{ width: 32, height: 32 }} src={wisher.avatarUrl} />
      </IconButton>
    </Tooltip>
  );
}

export function AccountMenu() {
  const auth = useAuth();
  const { reset } = useNotifications();

  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>();
  const isOpen = Boolean(anchorElement);
  function open(event: MouseEvent<HTMLButtonElement>) {
    setAnchorElement(event.currentTarget);
  }
  function close() {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setAnchorElement(undefined);
  }

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          id="account-button"
          onClick={open}
          size="large"
          aria-label="account"
          sx={{ ":hover": { background: "none" } }}
        >
          <UserAvatar
            sx={{ width: 32, height: 32 }}
            src={auth?.wisher?.avatarUrl || auth?.gifter?.avatarUrl}
          />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorElement}
        open={isOpen}
        onClose={close}
        MenuListProps={{ "aria-labelledby": "account-button" }}
      >
        <MenuItem
          component={Link}
          to={auth.gifter ? Routes.gifter.settings.main() : Routes.wisher.settings.main()}
          color="primary"
          onClick={close}
        >
          Account Settings
        </MenuItem>
        <MenuItem
          component={Link}
          to={Routes.auth.logout()}
          color="primary"
          onClick={() => {
            reset();
            close();
          }}
        >
          Log out
        </MenuItem>
      </Menu>
    </>
  );
}

export function LoginAction() {
  const matches = useMatches();

  /* Is in login page */
  const isSelected = matches.find((match) => match.id === RouteIds.login.layout());

  return (
    <Button
      component={Link}
      to={Routes.auth.login()}
      color="primary"
      sx={{
        px: 1.5,
        color: isSelected ? "teal.4" : "gray.6",
        borderRadius: 2,
        "&:hover": {
          bgcolor: "gray.2",
          textDecoration: "none",
        },
        bgcolor: isSelected ? "teal.0" : "gray.0",
      }}
    >
      <Typography variant={isSelected ? "h5" : "b5"}>Log In</Typography>
    </Button>
  );
}

export function LoginActionMobile() {
  const matches = useMatches();

  /* Is in login page */
  const isSelected = matches.find((match) => match.id === RouteIds.login.layout());

  return (
    <Button
      component={Link}
      to={Routes.auth.login()}
      color="primary"
      sx={{
        px: 1.5,
        color: "teal.4",
        borderRadius: 2,
        "&:hover": {
          bgcolor: "gray.2",
          textDecoration: "none",
        },
        bgcolor: isSelected ? "teal.0" : "gray.0",
      }}
    >
      <Typography variant="h4">Log In</Typography>
    </Button>
  );
}

export function SignUpAction() {
  return (
    <Button
      component={Link}
      to={Routes.auth.signUp()}
      variant="outlined"
      color="primary"
      sx={{ whiteSpace: "nowrap", px: 1.75 }}
    >
      <Typography variant="h5">Sign Up</Typography>
    </Button>
  );
}

export function AuthActions() {
  return (
    <>
      <LoginAction />
      <SignUpAction />
    </>
  );
}

export function WishTracker() {
  const matches = useMatches();
  const { totalNewOrders } = useWisher();

  /* Is in wish tracker page */
  const isSelected = matches.find((match) => match.id === RouteIds.wisher.order.list());
  return (
    <Tooltip key="Wish-Tracker" title="Wish-Tracker">
      <IconButton
        component={Link}
        to={Routes.wisher.orderList()}
        size="large"
        aria-label="Wish-Tracker"
        sx={{
          py: 1,
          px: 1,
          borderRadius: 2,
          "&:hover": { bgcolor: "gray.2", textDecoration: "none" },
          bgcolor: isSelected ? "teal.0" : "gray.0",
          color: isSelected ? "teal.4" : "neutral.5",
        }}
      >
        <Badge
          badgeContent={totalNewOrders}
          sx={{
            "& .MuiBadge-badge": {
              color: "neutral.0",
              background:
                "radial-gradient(222.19% 110.71% at 53.57% 110.71%, #005CE5 17.71%, #00C8F3 100%)",
            },
          }}
        >
          <EcommerceGiftIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}

export function NotificationLinkMobile() {
  return (
    <ThemeProvider theme={themeV2}>
      <NotificationButtonMobile />
    </ThemeProvider>
  );
}

export function NotificationLinkDesktop() {
  return (
    <ThemeProvider theme={themeV2}>
      <NotificationButtonDesktop />
    </ThemeProvider>
  );
}

export function NotificationLinkTablet() {
  return (
    <ThemeProvider theme={themeV2}>
      <NotificationButtonTablet />
    </ThemeProvider>
  );
}
