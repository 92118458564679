import CheckIcon from "@mui/icons-material/Check";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Divider, IconButton, Menu, Stack, Tooltip, Typography } from "@mui/material";
import { useRevalidator } from "@remix-run/react";
import type { MouseEvent } from "react";
import { useMemo, useState } from "react";

import { useLocale } from "~/hooks/use-locale";
import {
  getCurrencyList,
  getPreferredCurrencyList,
  usePreferredCurrency,
} from "~/modules/currency";
import type { PreferredCurrency } from "~/types";

import { CurrenciesMenuItems } from "../form/currencies-menu-items";
import { FinanceIcon } from "../icons/finance-icon";

export function CurrenciesMenu() {
  const revalidator = useRevalidator();
  const locale = useLocale();
  const currency = usePreferredCurrency();
  const currencyList = useMemo(() => getCurrencyList(undefined, false), []);
  const selectedCurrency = currencyList?.find((currencyItem) => currencyItem.code === currency);
  const suggestedCurrencies = useMemo(() => getPreferredCurrencyList(locale, false), [locale]);
  const otherCurrencies = currencyList.filter(
    (currencyItem) => currencyItem.code !== "noConversion",
  );

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>();
  const isOpen = Boolean(anchorElement);
  function open(event: MouseEvent<HTMLButtonElement>) {
    setAnchorElement(event.currentTarget);
  }
  function close() {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setAnchorElement(undefined);
  }

  function handleSelectCurrency(selectedCurrency: PreferredCurrency) {
    // copied cookie logic from legacy code
    // TODO: refactor using Remix cookie later
    const cookie = `currency=${selectedCurrency}${
      window.env.REACT_APP_BASE_URL.includes("wishtender.com") ? "; domain=wishtender.com" : ""
    }; path=/;`;

    // eslint-disable-next-line unicorn/no-document-cookie
    document.cookie = cookie;

    revalidator.revalidate();
    close();
  }

  return (
    <>
      <Tooltip title="Currency">
        <>
          {/* For desktop only */}
          <Button
            onClick={isOpen ? close : open}
            color="secondary"
            sx={{
              minWidth: "40px",
              color: isOpen ? "teal.4" : "gray.6",
              pl: 1.5,
              pr: 1.25,
              py: 0.75,
              display: {
                xs: "none",
                lg: "inline-flex",
              },
              bgcolor: isOpen ? "teal.0" : "gray.0",
              borderRadius: 2,
              ":hover": {
                textDecoration: "none",
                bgcolor: "neutral.2",
              },
            }}
          >
            <Typography variant="b5">
              {currency === "noConversion" ? "$N/A" : selectedCurrency?.code}
            </Typography>
            {isOpen ? (
              <ExpandLessIcon sx={{ fill: (theme) => theme.palette.teal[4] }} />
            ) : (
              <ExpandMoreIcon sx={{ fill: (theme) => theme.palette.neutral[5] }} />
            )}
          </Button>

          {/* For mobile and tablet */}
          <IconButton
            onClick={isOpen ? close : open}
            size="large"
            aria-label="currency"
            sx={{
              color: isOpen ? "teal.4" : "gray.5",
              display: {
                xs: "inline-flex",
                lg: "none",
              },
              px: 1,
              py: 0.75,
              borderRadius: 2,
              "&:hover": { bgcolor: "gray.2", textDecoration: "none" },
            }}
          >
            <FinanceIcon />
          </IconButton>
        </>
      </Tooltip>

      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "center",
        }}
        sx={{
          ".MuiMenu-paper": {
            boxShadow:
              "0px 0px 24px 0px rgba(48, 49, 51, 0.09), 0px 0px 1px 0px rgba(48, 49, 51, 0.10)",
            px: 1,
            py: 1,
          },
          ".MuiList-root": {
            px: 0,
            py: 0,
          },
        }}
        onClose={close}
        anchorEl={anchorElement}
        open={isOpen}
      >
        {selectedCurrency && (
          <Box
            sx={{
              px: 1.5,
              py: 1,
              borderRadius: 1.5,
              bgcolor: "teal.0",
              border: 1,
              borderColor: "teal.3",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Stack direction="row" spacing={2}>
                <Typography variant="h5" color="green.4" sx={{ minWidth: 40 }}>
                  {selectedCurrency.symbol}
                </Typography>
                <Typography variant="h5" color="gray.7">
                  {selectedCurrency.name}
                </Typography>
                <Typography variant="h5" color="gray.5">
                  {selectedCurrency.code !== "noConversion" && selectedCurrency.code}
                </Typography>
              </Stack>
              <Box sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
                <CheckIcon sx={{ fill: (theme) => theme.palette.teal[4] }} />
              </Box>
            </Box>
          </Box>
        )}
        <Box>
          <Box sx={{ px: 1.5, pt: 2, pb: 1 }}>
            <Typography variant="sh35" color="gray.5">
              SUGGESTED
            </Typography>
          </Box>
          <CurrenciesMenuItems
            selectedCurrency={currency}
            currencies={suggestedCurrencies}
            onClick={handleSelectCurrency}
          />
        </Box>
        <Divider sx={{ borderColor: "gray.2" }} />
        <Box>
          <Box sx={{ px: 1.5, pt: 2, pb: 1 }}>
            <Typography variant="sh35" color="gray.5">
              OTHER
            </Typography>
          </Box>
          <CurrenciesMenuItems
            selectedCurrency={currency}
            currencies={otherCurrencies}
            onClick={handleSelectCurrency}
          />
        </Box>
      </Menu>
    </>
  );
}
