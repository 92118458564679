import type { ComponentType } from "react";

import type { Banner } from "../types";
import { BannerType } from "../types";
import * as inBetaTesting from "./in-beta-testing";
import * as paymentProviderChanged from "./payment-provider-changed";
import * as underCheckoutMaintenance from "./under-checkout-maintenance-banner";
import * as upcomingCheckoutMaintenance from "./upcoming-checkout-maintenance-banner";
import * as wishtenderIsShuttingDown from "./wishtender-is-shutting-down";

type BannerUI<T extends BannerType> = {
  BannerContent: ComponentType<{ banner: Banner<T> }>;
};

type Items = Record<BannerType, BannerUI<BannerType>>;

const items = {
  [BannerType.UNDER_CHECKOUT_MAINTENANCE]: underCheckoutMaintenance,
  [BannerType.UPCOMING_CHECKOUT_MAINTENANCE]: upcomingCheckoutMaintenance,
  [BannerType.PAYMENT_PROVIDER_CHANGED]: paymentProviderChanged,
  [BannerType.IN_BETA_TESTING]: inBetaTesting,
  [BannerType.WISHTENDER_IS_SHUTTING_DOWN]: wishtenderIsShuttingDown,
} as Items;

export function getBannerUI(type: BannerType) {
  return items[type];
}
