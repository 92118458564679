import type { ChipProps } from "@mui/material";
import { Box, Chip, Typography } from "@mui/material";
import { Link, useLocation, useNavigation, useSearchParams } from "@remix-run/react";
import type { ElementType, Ref } from "react";
import { forwardRef } from "react";

import { DateRangeSelectPopover } from "~/components/date-range-select-popover";
import { CalendarSearchIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";
import { useFormatCurrency } from "~/modules/currency";
import { ReferralStatCard } from "~/modules/referrals";
import { dayjs } from "~/utils/dayjs";

import { useWisherReferralStats } from "../../hooks/use-wisher-referrals";

type ReferralStatsType = "thisMonth" | "lastMonth" | "allTime" | "custom";

export function StatsSection() {
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const navigation = useNavigation();

  const type = (params.get("stats") as ReferralStatsType) || "thisMonth";
  const startDate = params.has("statsFrom") ? dayjs(params.get("statsFrom")) : undefined;
  const endDate = params.has("statsTo") ? dayjs(params.get("statsTo")) : undefined;

  const stats = useWisherReferralStats();

  const formatCurrency = useFormatCurrency(stats.yourEarnings.currency);

  function getTitle() {
    if (type === "thisMonth") return dayjs().format("MMMM YYYY");
    if (type === "lastMonth") return dayjs().subtract(1, "month").format("MMMM YYYY");
    if (type === "allTime") return "All time";
    if (startDate && endDate) {
      const start = startDate.isSame(endDate, "year")
        ? startDate.format("MMMM DD")
        : startDate.format("MMMM DD YYYY");
      const end = endDate.format("MMMM DD YYYY");
      return `${start} - ${end}`;
    }
    return "Custom";
  }

  function buildPath(timeFrame: ReferralStatsType) {
    const params = new URLSearchParams(location.search);
    params.set("stats", timeFrame);
    if (timeFrame !== "custom") {
      params.delete("statsFrom");
      params.delete("statsTo");
    }
    return `${location.pathname}?${params.toString()}`;
  }

  const nextParams = new URLSearchParams(navigation.location?.search);
  const isLoading =
    navigation.state === "loading" &&
    navigation.location?.pathname.startsWith(Routes.wisher.referrals.main()) &&
    params.get("stats") !== nextParams.get("stats");

  const totalCard = stats.refereesEarnings ? 4 : 3;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexWrap: "nowrap",
          py: 1,
          my: -1,
          overflowY: "auto",
          px: { xs: 1.5, sm: 0 },
          mx: { xs: -1.5, sm: 0 },
        }}
      >
        <TimeFrameChip
          size="large"
          label="This month"
          selected={type === "thisMonth"}
          component={Link}
          to={buildPath("thisMonth")}
          clickable
        />
        <TimeFrameChip
          size="large"
          label="Last month"
          selected={type === "lastMonth"}
          component={Link}
          to={buildPath("lastMonth")}
          clickable
        />
        <TimeFrameChip
          size="large"
          label="All time"
          selected={type === "allTime"}
          component={Link}
          to={buildPath("allTime")}
          clickable
        />
        <DateRangeSelectPopover
          value={[startDate, endDate]}
          onChange={(dates) => {
            params.set("stats", "custom");
            params.set("statsFrom", dates[0]?.format("YYYY-MM-DD"));
            params.set("statsTo", dates[1]?.format("YYYY-MM-DD"));
            setParams(params);
          }}
          renderControl={(props) => (
            <TimeFrameChip
              ref={props.anchorRef as Ref<HTMLDivElement>}
              size="large"
              label={
                startDate && endDate
                  ? `${startDate.format("MMM DD")} - ${endDate.format("MMM DD")}`
                  : "Custom Dates"
              }
              icon={<CalendarSearchIcon />}
              selected={type === "custom"}
              clickable
              onClick={props.toggle}
              sx={{
                outlineStyle: {
                  xs: "none",
                  md: props.open ? "solid" : "none",
                },
              }}
            />
          )}
        />
      </Box>
      <Box sx={{ mt: 4 }} />
      <Typography
        color="neutral.7"
        component="h3"
        sx={{
          typography: { xs: "h7", sm: "h8" },
        }}
      >
        {getTitle()}
      </Typography>
      <Box sx={{ mt: "20px" }} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, minmax(0, 2fr))",
            md: `repeat(${totalCard === 3 ? 3 : 2}, minmax(0, 1fr))`,
            lg: `repeat(${totalCard}, minmax(0, 1fr))`,
          },
          gap: { xs: 1, sm: 3, lg: 3.5 },
        }}
      >
        <Box
          sx={{
            gridColumn: {
              xs: totalCard === 3 ? "span 2 / span 2" : "auto",
              md: "auto",
            },
          }}
        >
          <ReferralStatCard
            title="Your Earnings"
            value={formatCurrency(stats.yourEarnings.amount, { style: "compact" })}
            percent={stats.yourEarningsTrend}
            helperText="The total amount you've earned from commissions"
            loading={isLoading}
          />
        </Box>
        <ReferralStatCard
          title="Total Signups"
          variant="subtle"
          value={stats.totalSignups}
          percent={stats.totalSignupsTrend}
          helperText="The total amount of users that signed up using your referral code"
          loading={isLoading}
        />
        {stats.refereesEarnings && (
          <ReferralStatCard
            title="Referees Earnings"
            value={formatCurrency(stats.refereesEarnings.amount, { style: "compact" })}
            percent={stats.refereesEarningsTrend}
            helperText="How much your referees earned on WishTender"
            loading={isLoading}
          />
        )}
        <ReferralStatCard
          title="Active Referees"
          variant="subtle"
          value={stats.activeReferees}
          percent={stats.activeRefereesTrend}
          helperText="The number of referees that are still active on WishTender"
          loading={isLoading}
        />
      </Box>
    </Box>
  );
}

type TimeFrameChipProps<C extends ElementType> = ChipProps<C, { component?: C }> & {
  selected?: boolean;
};

export const TimeFrameChip = forwardRef(function TimeFrameChip<C extends ElementType>(
  { selected, sx, ...props }: TimeFrameChipProps<C>,
  ref: Ref<HTMLDivElement>,
) {
  return (
    <Chip
      ref={ref}
      size="large"
      clickable
      sx={{
        bgcolor: selected ? "neutral.6" : "neutral.2",
        color: selected ? "neutral.0" : "neutral.6",
        "&:hover, &:focus-visible": {
          bgcolor: selected ? "neutral.7" : "neutral.3",
          color: selected ? "neutral.0" : "neutral.6",
        },
        outlineWidth: "3px",
        // Outline color cannot be inferred by a string
        outlineColor: (theme) => theme.palette.neutral[3],
        "& .MuiChip-icon": {
          color: selected ? "neutral.0" : "neutral.6",
        },
        ...sx,
      }}
      {...props}
    />
  );
});
