import ArrowRight from "@mui/icons-material/ArrowRight";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useToggle } from "usehooks-ts";

import { ChangeStatementDescriptorDialog } from "./change-dialog";

export function ChangeStatementDescriptor() {
  const [openDialog, toggleOpenDialog] = useToggle(false);

  return (
    <>
      <MenuItem sx={{ py: 1 }} onClick={() => toggleOpenDialog()}>
        <ListItemText>Change Statement Descriptor</ListItemText>
        <ListItemIcon>
          <ArrowRight />
        </ListItemIcon>
      </MenuItem>
      <ChangeStatementDescriptorDialog open={openDialog} toggleOpen={toggleOpenDialog} />
    </>
  );
}
