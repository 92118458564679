import Instagram from "@mui/icons-material/Instagram";
import LinkRounded from "@mui/icons-material/LinkRounded";
import Reddit from "@mui/icons-material/Reddit";
import Twitter from "@mui/icons-material/Twitter";
import type React from "react";

import { DiscordIcon } from "~/components/icons/discord-icon";
import { FanslyIcon } from "~/components/icons/fansly-icon";
import { LoyalFansIcon } from "~/components/icons/loyal-fans-icon";
import { ManyvidsIcon } from "~/components/icons/manyvids-icon";
import { OnlyFansIcon } from "~/components/icons/only-fans-icon";

export const SOCIAL_PLATFORMS: Record<
  string,
  { Icon: React.ElementType; name: string; baseUrl?: string }
> = {
  twitter: {
    Icon: Twitter,
    name: "Twitter",
    baseUrl: "https://twitter.com/",
  },
  instagram: {
    Icon: Instagram,
    name: "Instagram",
    baseUrl: "https://www.instagram.com/",
  },
  reddit: {
    Icon: Reddit,
    name: "Reddit",
    baseUrl: "https://www.reddit.com/",
  },
  discord: {
    Icon: DiscordIcon,
    name: "Discord",
    baseUrl: "https://discord.com/",
  },
  onlyfans: {
    Icon: OnlyFansIcon,
    name: "OnlyFans",
    baseUrl: "https://onlyfans.com/",
  },
  loyalFans: {
    Icon: LoyalFansIcon,
    name: "LoyalFans",
    baseUrl: "https://www.loyalfans.com/",
  },
  fansly: {
    Icon: FanslyIcon,
    name: "Fansly",
    baseUrl: "https://fansly.com/",
  },
  manyvids: {
    Icon: ManyvidsIcon,
    name: "Manyvids",
    baseUrl: "https://www.manyvids.com/",
  },
  other: {
    Icon: LinkRounded,
    name: "Other",
  },
};

const MS_IN_DAY = 1000 * 60 * 60 * 24;

export const SUBSCRIPTION_INTERVALS = {
  daily: MS_IN_DAY,
  weekly: MS_IN_DAY * 7,
  biweekly: MS_IN_DAY * 7 * 2,
  monthly: MS_IN_DAY * 30,
};

export const ADD_AND_GO_TO_CART_BUTTON_ID = "button-add-and-go-to-cart";

export const COVER_DIMENSIONS = { width: 1200, height: 360 };
export const AVATAR_DIMENSIONS = { width: 300, height: 300 };
export const ITEM_IMAGE_DIMENSIONS = { width: 300, height: 300 };
export const MAX_BIO_LENGTH = 160;
