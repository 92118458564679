import { Box, Stack, Typography } from "@mui/material";
import { IconCircleCheckFilled } from "@tabler/icons-react";

export function ChangeStatementDescriptorSuccessNote({
  statementDescriptorContent,
}: {
  statementDescriptorContent?: string;
}) {
  const note = `Your preferences have been saved. Your purchases going forward will have the 
  ${
    statementDescriptorContent
      ? `“${statementDescriptorContent}” descriptor.`
      : "default WishTender Descriptor."
  }`;

  return (
    <Stack
      direction="row"
      sx={{
        px: 2,
        py: 1.5,
        borderRadius: 2,
        gap: 1,
        background: (theme) => theme.palette.green[0],
        border: (theme) => `2px solid ${theme.palette.green[4]}`,
        alignItems: "center",
      }}
    >
      <Box sx={{ color: "green.4" }}>
        <IconCircleCheckFilled />
      </Box>
      <Typography color="green.6" variant="sh4" sx={{ textAlign: "left" }}>
        {note}
      </Typography>
    </Stack>
  );
}
