import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useSearchParams } from "@remix-run/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { NumberFormatInput } from "~/components/form/number-format-input";
import { TextField } from "~/components/form/text-field";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import {
  currencyInfo,
  parsePrice,
  toSmallestUnit,
  usePresentmentCurrency,
} from "~/modules/currency";
import type { TwitterSettings as TwitterSettingsType } from "~/types";

import { UpdateTweetSettingsSchema } from "../../schema";
import { TweetExample } from "./twitter-example";

export function TwitterSettingsForm({
  onFinished,
  twitterSettings,
  twitterName,
  twitterUsername,
}: {
  twitterSettings?: TwitterSettingsType;
  twitterName?: string;
  twitterUsername?: string;
  onFinished: () => void;
}) {
  const currency = usePresentmentCurrency("USD");
  const [searchParams] = useSearchParams();
  const setDialog = searchParams.get("setDialog") ?? "";

  const [updateTwitterSettings, updateTwitterSettingsState] = useWtFetcher({
    onError: (response) => alert(response.error),
    onSuccess: () => onFinished(),
  });

  const form = useForm<z.infer<typeof UpdateTweetSettingsSchema>>({
    resolver: zodResolver(UpdateTweetSettingsSchema),
    defaultValues: {
      blockPseudonym: twitterSettings?.blockPseudonym ?? false,
      autoTweet: twitterSettings?.autoTweet ?? false,
      includeImages: twitterSettings?.includeImages ?? false,
      includeLink: twitterSettings?.includeLink ?? false,
      totalThreshold: parsePrice(twitterSettings?.totalThreshold ?? 0, currency),
    },
  });

  const watchedBlockPseudonym = form.watch("blockPseudonym");
  const watchedIncludeImages = form.watch("includeImages");
  const watchedIncludeLink = form.watch("includeLink");
  const watchedTotalThreshold = form.watch("totalThreshold");

  function handleUpdateTweetSettings(data: z.infer<typeof UpdateTweetSettingsSchema>) {
    const autoTweet = String(setDialog === "autoTweetsStepper" || data.autoTweet);

    updateTwitterSettings.submit(
      {
        blockPseudonym: String(data.blockPseudonym),
        autoTweet,
        includeImages: String(data.includeImages),
        includeLink: String(data.includeLink),
        totalThreshold: toSmallestUnit(data.totalThreshold ?? "", currency),
      },
      {
        method: "patch",
        action: Routes.actions.settings.updateTwitterSettings(),
      },
    );
  }

  useEffect(() => {
    if (twitterSettings)
      form.reset({
        blockPseudonym: twitterSettings.blockPseudonym,
        autoTweet: twitterSettings.autoTweet,
        includeImages: twitterSettings.includeImages,
        includeLink: twitterSettings.includeLink,
        totalThreshold: parsePrice(twitterSettings.totalThreshold, currency),
      });
  }, [currency, form, twitterSettings]);

  return (
    <Stack component="form" onSubmit={form.handleSubmit(handleUpdateTweetSettings)} spacing={3}>
      <FormGroup>
        <Controller
          name="blockPseudonym"
          control={form.control}
          render={({ field }) => {
            return (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Always hide pseudonym"
                />
              </FormControl>
            );
          }}
        />
        <Controller
          name="includeImages"
          control={form.control}
          render={({ field }) => {
            return (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Include gift image"
                />
              </FormControl>
            );
          }}
        />
        <Controller
          name="includeLink"
          control={form.control}
          render={({ field }) => {
            return (
              <FormControl>
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Include wishlist link "
                />
              </FormControl>
            );
          }}
        />
      </FormGroup>
      <Controller
        name="totalThreshold"
        control={form.control}
        render={({ field, fieldState: { error } }) => {
          return (
            <TextField.Root error={!!error} fullWidth>
              <TextField.Label>Min Amount</TextField.Label>
              <TextField.Input
                {...field}
                startAdornment={
                  <Typography component="span" sx={{ mr: 1 }}>
                    {currencyInfo(currency).symbol}
                  </Typography>
                }
                inputProps={{
                  decimalPlaces: currencyInfo(currency).decimalPlaces,
                }}
                inputComponent={NumberFormatInput}
              />
              {error && <TextField.Helper error>{error.message}</TextField.Helper>}
            </TextField.Root>
          );
        }}
      />
      <TweetExample
        currency={currency}
        userName={twitterUsername}
        name={twitterName}
        blockPseudonym={watchedBlockPseudonym}
        includeImages={watchedIncludeImages}
        includeLink={watchedIncludeLink}
        totalThreshold={watchedTotalThreshold}
      />
      <LoadingButton
        type="submit"
        variant="contained"
        loading={updateTwitterSettingsState.isFetching}
      >
        {setDialog === "autoTweetsStepper" ? <span>Save and Activate</span> : <span>Save</span>}
      </LoadingButton>
    </Stack>
  );
}
