import { Stack, Typography } from "@mui/material";
import { useState } from "react";

import { MedalIcon } from "~/components/icons/medal-icon";
import { dayjs } from "~/utils/dayjs";

import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";
import { TopRankAlert } from "../top-rank-alert";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.MONTHLY_LEADERBOARD>;
}) {
  const [shouldOpenAlert, setShouldOpenAlert] = useState(false);

  return (
    <NotificationContentContainer
      notification={notification}
      onClick={() => setShouldOpenAlert(true)}
      sx={{
        bgcolor: "neutral.0",
        ":hover": {
          bgcolor: notification.seen ? "neutral.2" : "neutral.0",
          "& .monthly-leaderboard-card": {
            transform: `translateY(${notification.seen ? "0px" : "-3px"})`,
            boxShadow: notification.seen
              ? "none"
              : "0px 0px 0px 3px rgba(9, 172, 255, 0.52), 0px 4px 0px 3px rgba(9, 172, 255, 0.25), 0px 9px 0px 1px rgba(9, 172, 255, 0.25)",
          },
        },
      }}
    >
      <Stack
        className="monthly-leaderboard-card"
        direction="row"
        sx={{
          px: notification.seen ? 0 : 1.5,
          py: notification.seen ? 0 : 1.5,
          borderRadius: 4,
          position: "relative",
          transition: "box-shadow 0.2s ease, transform 0.2s ease, padding 0.2s ease",
          boxShadow: notification.seen
            ? "none"
            : "0px 0px 0px 3px rgba(9, 172, 255, 0.52), 0px 3px 0px 3px rgba(9, 172, 255, 0.25), 0px 7px 0px 1px rgba(9, 172, 255, 0.25)",
        }}
      >
        <MedalIcon sx={{ fontSize: 66 }} viewBox="0 0 66 66" />

        <Stack spacing={0.5}>
          <Typography>
            <Typography
              component="span"
              variant="h5"
              sx={{
                background:
                  "radial-gradient(116.04% 233.06% at 107.29% 56.82%, #005CE6 17.71%, #00C8F3 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {`See your ${
                dayjs().localeData().months()[notification.data.month - 1]
              } ranking now `}
            </Typography>
            <span>🎉</span>
          </Typography>

          <Typography component="span" variant="b5">
            {`Congratulations, you're in the ${
              dayjs().localeData().months()[notification.data.month - 1]
            } top ranked WishTender wishers! `}
          </Typography>
        </Stack>
      </Stack>

      <TopRankAlert
        notification={notification}
        open={shouldOpenAlert}
        onClose={() => setShouldOpenAlert(false)}
      />
    </NotificationContentContainer>
  );
}
