import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { SparkleMoneyIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";
import { NotificationContentContainer } from "~/modules/notifications/components/notification-content-container";
import type { NotificationType, NotificationV2 } from "~/modules/notifications/types";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.FIRST_PAYOUT_PAID>;
}) {
  return (
    <NotificationContentContainer
      notification={notification}
      to={Routes.wisher.paymentDashboard.main()}
      rel="noopener noreferrer"
      sx={{
        "& .MuiLink-root": {
          ml: "0 !important",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "teal.0",
          borderRadius: 6,
          height: 40,
          minWidth: 40,
          color: "teal.4",
        }}
      >
        <SparkleMoneyIcon sx={{ fontSize: 28 }} />
      </Box>
      <Stack sx={{ color: "neutral.7" }}>
        <Typography variant="h5">Your first payout has been completed</Typography>
        <Typography component="span" variant="b5">
          You can now dismiss our guide and use the full Payment Dashboard.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
