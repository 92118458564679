import type { SxProps } from "@mui/material";
import { Box, keyframes } from "@mui/material";
import type { ReactNode } from "react";

export function BounceInScale({
  children,
  sx,
  duration,
}: {
  children: ReactNode;
  sx?: SxProps;
  duration?: string;
}) {
  return (
    <Box
      sx={{
        animation: `${bounceIn} ${duration || "0.5s"} ease-in-out`,
        ...sx,
      }}
      component="span"
    >
      {children}
    </Box>
  );
}

const bounceIn = keyframes`
  from, 30%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    transform: scale3d(0.5, 0.5, 0.5);
  }

  30% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  80% {
    transform: scale3d(0.95, 0.95, 0.95);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
`;
