import { Box, Stack, Typography } from "@mui/material";

import { SparkleIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";

import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.INVITED_TO_BETA>;
}) {
  return (
    <NotificationContentContainer to={Routes.wisher.activeWishlist()} notification={notification}>
      <Box sx={{ backgroundColor: "blue.1", p: 0.75, borderRadius: 6, height: 40, width: 40 }}>
        <SparkleIcon sx={{ fontSize: 28, color: "teal.4" }} />
      </Box>
      <Stack>
        <Typography color="neutral.6" variant="h5">
          Your wishlist is ready to be activated.
        </Typography>
        <Typography color="neutral.7" variant="b5">
          Review our new terms and conditions to proceed with activating your wishlist.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
