import { Box, Typography } from "@mui/material";

export function BannerContent() {
  return (
    <Box sx={{ px: 2, py: 1.5, bgcolor: "teal.4" }}>
      <Typography variant="h5" color="neutral.1" textAlign="center">
        Now in Beta Testing. Make sure you follow our beta agreement 💛
      </Typography>
    </Box>
  );
}
