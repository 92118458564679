import { SvgIcon, type SvgIconProps } from "@mui/material";

export function CalendarRemoveIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0321 4.16675H5.96788H5.96785C5.52857 4.16673 5.14961 4.16672 4.83748 4.19222C4.50802 4.21914 4.18034 4.27857 3.86503 4.43923C3.39462 4.67892 3.01217 5.06137 2.77249 5.53177C2.61182 5.84709 2.55239 6.17477 2.52548 6.50423C2.49997 6.81636 2.49999 7.19532 2.5 7.6346V7.63463V14.0322V14.0322C2.49999 14.4715 2.49997 14.8505 2.52548 15.1626C2.55239 15.4921 2.61182 15.8197 2.77249 16.1351C3.01217 16.6055 3.39462 16.9879 3.86503 17.2276C4.18034 17.3883 4.50802 17.4477 4.83748 17.4746C5.1496 17.5001 5.52854 17.5001 5.9678 17.5001H5.9679H14.0321H14.0322C14.4715 17.5001 14.8504 17.5001 15.1625 17.4746C15.492 17.4477 15.8197 17.3883 16.135 17.2276C16.6054 16.9879 16.9878 16.6055 17.2275 16.1351C17.3882 15.8197 17.4476 15.4921 17.4745 15.1626C17.5 14.8505 17.5 14.4715 17.5 14.0322V7.63461C17.5 7.19533 17.5 6.81637 17.4745 6.50423C17.4476 6.17477 17.3882 5.84709 17.2275 5.53177C16.9878 5.06137 16.6054 4.67892 16.135 4.43923C15.8197 4.27857 15.492 4.21914 15.1625 4.19222C14.8504 4.16672 14.4714 4.16673 14.0322 4.16675H14.0321Z"
        fill="#0CB4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.74408 8.57733C8.06951 8.25189 8.59715 8.25189 8.92259 8.57733L10 9.65474L11.0774 8.57733C11.4028 8.25189 11.9305 8.25189 12.2559 8.57733C12.5814 8.90277 12.5814 9.4304 12.2559 9.75584L11.1785 10.8333L12.2559 11.9107C12.5814 12.2361 12.5814 12.7637 12.2559 13.0892C11.9305 13.4146 11.4028 13.4146 11.0774 13.0892L10 12.0118L8.92259 13.0892C8.59715 13.4146 8.06951 13.4146 7.74408 13.0892C7.41864 12.7637 7.41864 12.2361 7.74408 11.9107L8.82149 10.8333L7.74408 9.75584C7.41864 9.4304 7.41864 8.90277 7.74408 8.57733Z"
        fill="#0CB4E9"
      />
      <path
        d="M7.49992 3.33333C7.49992 2.8731 7.12682 2.5 6.66659 2.5C6.20635 2.5 5.83325 2.8731 5.83325 3.33333V5C5.83325 5.46024 6.20635 5.83333 6.66659 5.83333C7.12682 5.83333 7.49992 5.46024 7.49992 5V3.33333Z"
        fill="#0CB4E9"
      />
      <path
        d="M14.1666 3.33333C14.1666 2.8731 13.7935 2.5 13.3333 2.5C12.873 2.5 12.4999 2.8731 12.4999 3.33333V5C12.4999 5.46024 12.873 5.83333 13.3333 5.83333C13.7935 5.83333 14.1666 5.46024 14.1666 5V3.33333Z"
        fill="#0CB4E9"
      />
    </SvgIcon>
  );
}
