import { Box, Stack, Typography } from "@mui/material";
import { Link } from "@remix-run/react";
import { IconAlertTriangleFilled } from "@tabler/icons-react";

import type { Banner, BannerType } from "../types";

export function BannerContent({
  banner,
}: {
  banner: Banner<BannerType.WISHTENDER_IS_SHUTTING_DOWN>;
}) {
  return (
    <Box sx={{ px: 2, py: 1.5, bgcolor: "blue.4" }}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 1 }}>
        <Stack sx={{ color: "yellow.3", justifyContent: "center" }}>
          <IconAlertTriangleFilled />
        </Stack>
        <Typography variant="h5" color="neutral.0">
          WishTender is shutting down.{" "}
          <Link to={banner.data.url} target="_blank" rel="noreferrer noopener">
            <Typography
              variant="h5"
              color="neutral.0"
              component="span"
              sx={{ textDecoration: "underline" }}
            >
              Find information about what happened here.
            </Typography>
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
