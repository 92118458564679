import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { IconReceiptRefund } from "@tabler/icons-react";

import { Routes } from "~/constants/routes";
import { useFormatCurrency } from "~/modules/currency";
import { NotificationContentContainer } from "~/modules/notifications/components/notification-content-container";
import type { NotificationType, NotificationV2 } from "~/modules/notifications/types";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.GIFTER_REFUND_SUCCEEDED>;
}) {
  const totalFormatCurrency = useFormatCurrency(notification.data.total.currency);
  const estTotalFormatCurrency = useFormatCurrency(
    notification.data.estTotal?.currency || notification.data.total.currency,
  );
  const estTotal = notification.data.estTotal
    ? estTotalFormatCurrency(notification.data.estTotal.amount)
    : undefined;

  return (
    <NotificationContentContainer
      to={Routes.gifter.orderList()}
      notification={notification}
      rel="noopener noreferrer"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "teal.0",
          borderRadius: 6,
          height: 40,
          minWidth: 40,
          color: "teal.4",
        }}
      >
        <IconReceiptRefund size={28} />
      </Box>
      <Stack sx={{ color: "neutral.6" }}>
        <Typography variant="h5">A refund for your gift has been processed</Typography>
        <Typography variant="b5">
          A refund of {totalFormatCurrency(notification.data.total.amount)}
          {estTotal ? ` (est ${estTotal})` : ""} for your gift has been processed and sent back to
          your original payment method. View details in your orders.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
