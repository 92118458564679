import { Box, Link, Stack, Typography } from "@mui/material";
import { Link as RLink } from "@remix-run/react";

import { DefaultStatementDescriptorIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";

import { useNotifications } from "../..";
import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.STATEMENT_DESCRIPTOR_ENABLED>;
}) {
  const { toggleNotificationHub } = useNotifications();

  return (
    <NotificationContentContainer
      to={Routes.gifter.settings.main()}
      notification={notification}
      sx={{
        "& .MuiLink-root": {
          ml: "0 !important",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "blue.1",
          borderRadius: 6,
          height: 40,
          minWidth: 40,
          color: "blue.5",
        }}
      >
        <DefaultStatementDescriptorIcon />
      </Box>
      <Stack sx={{ color: "neutral.7" }}>
        <Typography variant="h5" color="neutral.7">
          Alternative statement descriptor now available
        </Typography>
        <Typography component="span" color="neutral.7" variant="b5">
          Want your bank statements to look more discreet 👀? Navigate to{" "}
          <Link
            component={RLink}
            to={Routes.gifter.settings.main()}
            sx={{ textDecoration: "none" }}
            onClick={() => toggleNotificationHub(false)}
          >
            <Typography
              component="span"
              variant="b5"
              color="blue.4"
              sx={{
                textDecoration: "underline",
                textDecorationColor: "blue.4",
                position: "relative",
                zIndex: 2,
              }}
            >
              Account Settings
            </Typography>
          </Link>{" "}
          to turn on this feature.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
