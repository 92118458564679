import { Box, Stack, Typography } from "@mui/material";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { formatTime } from "~/utils/time";

import type { Banner, BannerType } from "../types";
import { BannerContent as UnderCheckoutMaintenanceBanner } from "./under-checkout-maintenance-banner";

// FIXME: for now we just handle date time for 1-2 hours maintenance.
// We need to account to show date time in case we have long maintenance
export function BannerContent({
  banner,
}: {
  banner: Banner<BannerType.UPCOMING_CHECKOUT_MAINTENANCE>;
}) {
  const remainingSecond = dayjs(banner.data.startAt).diff(new Date(), "seconds");
  const [seconds, setSeconds] = useState(remainingSecond);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((pre) => pre - 1);
      }
    }, 1000); // every second

    return () => clearInterval(interval);
  });

  if (seconds < 1) {
    return (
      <UnderCheckoutMaintenanceBanner
        banner={banner as unknown as Banner<BannerType.UNDER_CHECKOUT_MAINTENANCE>}
      />
    );
  }

  return (
    <Box sx={{ px: 2, py: 1.5, bgcolor: "blue.4" }}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 1 }}>
        <Stack sx={{ color: "yellow.3", justifyContent: "center" }}>
          <IconAlertTriangleFilled />
        </Stack>
        <Typography variant="h5" color="neutral.0">
          WishTender’s Checkout will be down for maintenance between{" "}
          {formatTime(banner.data.startAt, "time")} - {formatTime(banner.data.endAt, "time")} on{" "}
          {formatTime(banner.data.startAt, "shortDate")}
        </Typography>
      </Stack>
    </Box>
  );
}
