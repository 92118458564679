import type { WishlistItem } from "~/types";

import { SUBSCRIPTION_INTERVALS } from "../constants";

export function getIntervalLabel(interval: number) {
  switch (interval) {
    case SUBSCRIPTION_INTERVALS.daily:
      return "Daily";
    case SUBSCRIPTION_INTERVALS.weekly:
      return "Weekly";
    case SUBSCRIPTION_INTERVALS.biweekly:
      return "Bi Weekly";
    case SUBSCRIPTION_INTERVALS.monthly:
      return "Monthly";
    default:
      return "";
  }
}

export function getIntervalSelectLabel(interval: number) {
  switch (interval) {
    case 0:
      return "One time purchase";
    case SUBSCRIPTION_INTERVALS.daily:
      return "Paid every day";
    case SUBSCRIPTION_INTERVALS.weekly:
      return "Paid every week";
    case SUBSCRIPTION_INTERVALS.biweekly:
      return "Paid every 2 weeks";
    case SUBSCRIPTION_INTERVALS.monthly:
      return "Paid every month";
    default:
      return "";
  }
}

export function isSubscriptionItem(item: WishlistItem) {
  if (item.type !== "subscription" || !item.subscriptionIntervals) return false;
  if (item.subscriptionIntervals.length === 0) return false;
  return !(item.subscriptionIntervals.length === 1 && item.subscriptionIntervals[0] === 0);
}
