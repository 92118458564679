import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { IconReceiptRefund } from "@tabler/icons-react";

import { Routes } from "~/constants/routes";
import { useFormatCurrency } from "~/modules/currency";
import { NotificationContentContainer } from "~/modules/notifications/components/notification-content-container";
import type { NotificationType, NotificationV2 } from "~/modules/notifications/types";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.WISHER_REFUND_SUCCEEDED>;
}) {
  const formatCurrency = useFormatCurrency(notification.data.total.currency);

  return (
    <NotificationContentContainer
      to={Routes.wisher.paymentDashboard.activityList()}
      notification={notification}
      rel="noopener noreferrer"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "teal.0",
          borderRadius: 6,
          height: 40,
          minWidth: 40,
          color: "teal.4",
        }}
      >
        <IconReceiptRefund size={28} />
      </Box>
      <Stack sx={{ color: "neutral.6" }}>
        <Typography variant="h5">Your balance has been deducted for a refund.</Typography>
        <Typography variant="b5">
          A total amount of {formatCurrency(notification.data.total.amount)} has been deducted from
          your account balance.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
