import Search from "@mui/icons-material/Search";
import {
  AppBar,
  Dialog,
  IconButton,
  Link,
  Slide,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import type { TransitionProps } from "@mui/material/transitions";
import { useLocation, useNavigation } from "@remix-run/react";
import type { ReactElement, Ref } from "react";
import { forwardRef, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";

import { themeV2 } from "~/utils/theme";

import { useSearch } from "../hooks/use-search";
import { SearchBarResults } from "./search-bar-results";
import { SearchInputForm } from "./search-input-form";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  // eslint-disable-next-line unicorn/prevent-abbreviations
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function SearchDialogMobile({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (value_: boolean) => void;
}) {
  const { data, form, isFetching } = useSearch();

  const searchText = form.watch("searchText");

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
      sx={{
        "& .MuiPaper-root": {
          bgcolor: "neutral.2",
          borderRadius: 0,
        },
      }}
    >
      <AppBar
        sx={{
          position: "relative",
          bgcolor: (theme) => `${theme.palette.neutral[0]} !important`,
          pt: 1,
        }}
      >
        <Toolbar sx={{ p: 2, gap: 2 }}>
          <Stack sx={{ flex: 1 }}>
            <FormProvider {...form}>
              <SearchInputForm autoFocus onSubmitted={() => setOpen(false)} loading={isFetching} />
            </FormProvider>
          </Stack>
          <Link
            component="button"
            underline="none"
            onClick={() => setOpen(false)}
            sx={{ color: "neutral.5" }}
          >
            Back
          </Link>
        </Toolbar>

        {searchText && (
          <SearchBarResults
            data={data}
            queryString={searchText}
            loading={isFetching}
            onClickShowAll={() => setOpen(false)}
          />
        )}
      </AppBar>
      {(!searchText || !data) && (
        <Stack sx={{ p: 5, alignItems: "center" }}>
          <Typography
            component="span"
            variant="b5"
            sx={{ textAlign: "center", color: "neutral.5" }}
          >
            Search for wishers, gifters, wishlist items
          </Typography>
        </Stack>
      )}
    </Dialog>
  );
}

export function SearchBarMobile() {
  const [shouldOpenSearchDialog, setShouldOpenSearchDialog] = useState(false);
  const navigation = useNavigation();
  const location = useLocation();

  useEffect(() => {
    if (
      navigation.state === "loading" &&
      location.pathname === navigation.location.pathname &&
      shouldOpenSearchDialog
    )
      setShouldOpenSearchDialog(false);
  }, [location.pathname, navigation, shouldOpenSearchDialog]);

  return (
    <ThemeProvider theme={themeV2}>
      <IconButton
        size="large"
        aria-label="search"
        sx={{
          color: "gray.5",
          px: 1,
          py: 1,
          borderRadius: 2,
          "&:hover": { bgcolor: "gray.2", textDecoration: "none" },
        }}
        onClick={() => setShouldOpenSearchDialog(true)}
      >
        <Search />
      </IconButton>
      <SearchDialogMobile open={shouldOpenSearchDialog} setOpen={setShouldOpenSearchDialog} />
    </ThemeProvider>
  );
}
