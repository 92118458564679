import { Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/remix";
import type { ComponentProps } from "react";

import type { NotificationType, NotificationV2 } from "../types";
import { getNotificationUI } from "./notifications";

export function NotificationItem<T extends NotificationType>({
  notification,
}: {
  notification: NotificationV2<T>;
}) {
  const notificationUI = getNotificationUI(notification.type);

  return (
    <Sentry.ErrorBoundary fallback={FallbackNotificationItemError}>
      <notificationUI.NotificationContent notification={notification} />
    </Sentry.ErrorBoundary>
  );
}

function FallbackNotificationItemError({ eventId }: ComponentProps<Sentry.FallbackRender>) {
  return (
    <Stack
      spacing={1}
      component="article"
      sx={{
        px: {
          xs: 2,
          sm: 3,
        },
        pt: 1.5,
        pb: 2,
      }}
    >
      <Typography component="p" variant="body2" color="error">
        Oops! Something went wrong while displaying this notification. Please try again later.
      </Typography>
      <Typography component="p" variant="body2" color="text.secondary">
        If the issue persists, please contact support for further assistance. Error ID:{" "}
        <code>{eventId}</code>
      </Typography>
    </Stack>
  );
}
