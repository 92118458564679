import { zodResolver } from "@hookform/resolvers/zod";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { Dialog } from "~/components/dialog";
import { NumberFormatInput } from "~/components/form/number-format-input";
import { TextField } from "~/components/form/text-field";
import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import {
  currencyInfo,
  parsePrice,
  toSmallestUnit,
  usePresentmentCurrency,
} from "~/modules/currency";
import { useWishlist } from "~/modules/wisher/hooks/use-wishlist";

import { UpdateSurpriseGiftSettingsSchema } from "../schema";

export function SurpriseGiftSettingsMenuItem({ wishlistId }: { wishlistId?: string }) {
  const [shouldOpenSurpriseGiftDialog, setShouldOpenSurpriseGiftDialog] = useState(false);
  const currency = usePresentmentCurrency("USD");
  const wishlist = useWishlist();

  const [updateSurpriseGiftSettings, updateSurpriseGiftSettingsState] = useWtFetcher({
    onError: (response) => alert(response.error),
    onSuccess: () => setShouldOpenSurpriseGiftDialog(false),
  });

  const form = useForm<z.infer<typeof UpdateSurpriseGiftSettingsSchema>>({
    resolver: zodResolver(UpdateSurpriseGiftSettingsSchema),
    defaultValues: {
      enabled: wishlist.surpriseGift.enabled,
      minAmount: parsePrice(wishlist.surpriseGift.minAmount?.amount ?? 0, currency),
      wishlistId,
    },
  });

  const watchedEnabled = form.watch("enabled");

  async function handleUpdateSurpriseGiftSettings(
    data: z.infer<typeof UpdateSurpriseGiftSettingsSchema>,
  ) {
    updateSurpriseGiftSettings.submit(
      {
        ...data,
        enabled: String(data.enabled),
        minAmount: toSmallestUnit(data.minAmount, currency),
      },
      {
        method: "patch",
        action: Routes.actions.settings.updateSurpriseGift(),
      },
    );
  }
  return (
    <>
      <MenuItem sx={{ py: 1 }} onClick={() => setShouldOpenSurpriseGiftDialog(true)}>
        <ListItemText>Surprise Gift Settings</ListItemText>
        <ListItemIcon>
          <ArrowRight />
        </ListItemIcon>
      </MenuItem>
      <Dialog.Root
        maxWidth="xs"
        fullWidth
        variant="normal"
        open={shouldOpenSurpriseGiftDialog}
        onClose={() => setShouldOpenSurpriseGiftDialog(false)}
      >
        <Dialog.Title sx={{ textAlign: "center" }}>Update Surprise Gift Settings </Dialog.Title>
        <Dialog.Content>
          <Box component="form" onSubmit={form.handleSubmit(handleUpdateSurpriseGiftSettings)}>
            <Controller
              name="enabled"
              control={form.control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(event) => {
                            field.onChange(event);
                            form.setValue("minAmount", parsePrice(0, currency));
                          }}
                        />
                      }
                      label="Enable surprise gift"
                    />
                  </FormControl>
                );
              }}
            />
            <Box mt={2} />
            {watchedEnabled && (
              <Controller
                name="minAmount"
                control={form.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <TextField.Root error={!!error} fullWidth>
                      <TextField.Label>Min Amount</TextField.Label>
                      <TextField.Input
                        {...field}
                        startAdornment={
                          <Typography component="span" sx={{ mr: 1 }}>
                            {currencyInfo(currency).symbol}
                          </Typography>
                        }
                        inputProps={{
                          decimalPlaces: currencyInfo(currency).decimalPlaces,
                        }}
                        inputComponent={NumberFormatInput}
                      />
                      {error && <TextField.Helper error>{error.message}</TextField.Helper>}
                    </TextField.Root>
                  );
                }}
              />
            )}
            <Box mt={4} />
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              loading={updateSurpriseGiftSettingsState.isFetching}
              disabled={updateSurpriseGiftSettingsState.isFetching}
            >
              <span>Save</span>
            </LoadingButton>
          </Box>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
