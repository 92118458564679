import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { RemoveCircleFilledIcon } from "~/components/icons/remove-circle-filled-icon";
import { Routes } from "~/constants/routes";
import { NotificationContentContainer } from "~/modules/notifications/components/notification-content-container";
import type { NotificationType, NotificationV2 } from "~/modules/notifications/types";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.USER_IDENTITY_DECLINED>;
}) {
  return (
    <NotificationContentContainer
      notification={notification}
      to={Routes.wisher.paymentDashboard.main()}
      rel="noopener noreferrer"
      sx={{
        "& .MuiLink-root": {
          ml: "0 !important",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "red.0",
          borderRadius: 6,
          height: 40,
          minWidth: 40,
        }}
      >
        <RemoveCircleFilledIcon sx={{ fontSize: 28 }} />
      </Box>
      <Stack sx={{ color: "neutral.7" }}>
        <Typography variant="h5" color="neutral.7">
          Your ID Verification was declined
        </Typography>
        <Typography component="span" color="neutral.7" variant="b5">
          Visit the Payment Dashboard to see why and try again with verification.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
