import { SvgIcon, type SvgIconProps } from "@mui/material";

export function RemoveCircleFilledIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.3335 14C2.3335 7.55666 7.55684 2.33331 14.0002 2.33331C20.4435 2.33331 25.6668 7.55666 25.6668 14C25.6668 20.4433 20.4435 25.6666 14.0002 25.6666C7.55684 25.6666 2.3335 20.4433 2.3335 14ZM11.3251 9.67502C10.8695 9.21941 10.1308 9.21941 9.6752 9.67502C9.21959 10.1306 9.21959 10.8693 9.6752 11.3249L12.3502 14L9.6752 16.675C9.21959 17.1306 9.21959 17.8693 9.6752 18.3249C10.1308 18.7806 10.8695 18.7806 11.3251 18.3249L14.0002 15.6499L16.6752 18.3249C17.1308 18.7806 17.8695 18.7806 18.3251 18.3249C18.7807 17.8693 18.7807 17.1306 18.3251 16.675L15.6501 14L18.3251 11.3249C18.7807 10.8693 18.7807 10.1306 18.3251 9.67502C17.8695 9.21941 17.1308 9.21941 16.6752 9.67502L14.0002 12.3501L11.3251 9.67502Z"
        fill="#D14343"
      />
    </SvgIcon>
  );
}
