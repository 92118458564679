import type { CartRequestStatus, Currency, Money } from "~/types";

/* eslint-disable no-unused-vars */
export type Notification = {
  id: string;
  seen: boolean;
  message: string;
  code: string;
  type: "top";
};

export enum NotificationType {
  ORDER_PAID = "orderPaid",
  REQUEST_SENT = "requestSent",
  REQUEST_DECLINED = "requestDeclined",
  CART_REQUEST_CANCELED = "cartRequestCanceled",
  MONTHLY_LEADERBOARD = "monthlyLeaderboard",
  NEW_NOTICE = "newNotice",
  SUBSCRIPTION_REMINDED = "subscriptionReminded",
  SUBSCRIPTION_CANCELLED = "subscriptionCancelled",
  SUBSCRIPTION_CREATED = "subscriptionCreated",
  ORDER_REPLIED = "orderReplied",
  INSTANT_PAYOUT_ENABLED = "instantPayoutEnabled",
  STATEMENT_DESCRIPTOR_ENABLED = "statementDescriptorEnabled",
  STATEMENT_DESCRIPTOR_UPDATED = "statementDescriptorUpdated",
  STATEMENT_DESCRIPTOR_DELETED = "statementDescriptorDeleted",
  STRIPE_ACCOUNT_INFO_UNACTIVATED = "stripeAccountInfoUnactivated",
  YEARLY_RECAP_WRAPPED = "yearlyRecapWrapped",
  INCORRECT_ACTIVATED_STRIPE_ACCOUNT_NOTIFICATION = "incorrectActivatedStripeAccountNotification",
  INCORRECT_DEACTIVATED_STRIPE_ACCOUNT_NOTIFICATION = "incorrectDeactivatedStripeAccountNotification",
  INSTANT_PAYOUT_FAILED = "instantPayoutFailed",
  PAYMENT_TRANSITIONED = "paymentTransitioned",
  PAYMENT_TRANSITION_UPDATED = "paymentTransitionUpdated",
  WISHER_DEACTIVATED = "wisherDeactivated",
  PAYOUT_FAILED = "payout:failed",
  FIRST_PAYOUT_PAID = "firstPayoutPaid",
  USER_IDENTITY_VERIFIED = "userIdentityVerified",
  USER_IDENTITY_DECLINED = "userIdentityDeclined",
  USER_IDENTITY_RESUBMISSION_REQUESTED = "userIdentityResubmissionRequested",
  GIFTER_REFUND_SUCCEEDED = "refundSucceeded:gifter",
  WISHER_REFUND_SUCCEEDED = "refundSucceeded:wisher",
  PAYOUT_AVAILABLE_TO_WITHDRAW = "payout:availableToWithdraw",
  FIRST_GIFT = "firstGift",
  INVITED_TO_BETA = "beta:invited",
  CAPABILITY_PAYOUT_FROZEN = "capability:payoutFrozen",
  NEW_PAYMENT_UPDATE = "payment:newUpdate",
  PAYOUT_FROZEN_DUE_TO_CARD_TESTING = "payout:payoutFrozenDueToCardTesting",
  PAYOUT_AVAILABLE_FOR_REDUCED_MINIMUM = "payout:payoutAvailableForReducedMinimum",
  SETUP_PAYOUT_METHOD_REMIND = "payout:setupPayoutMethodRemind",
  WAVING_VERIFICATION_FEE = "payout:wavingVerificationFee",
}

export type RequestSentNotificationData = {
  wisher: {
    id: string;
    handle: string;
    name: string;
    avatarUrl: string;
  };
  request: {
    id: string;
    status: CartRequestStatus;
  };
};

export type RequestDeclinedNotificationData = {
  gifter: {
    id?: string;
    handle?: string;
    name?: string;
    pseudonym?: string;
    avatarUrl: string;
  };
  request: {
    id: string;
    tipAmount: number;
    tipNote?: string;
  };
};

export type CartRequestCanceledNotificationData = {
  wisher: {
    id: string;
    handle: string;
    name: string;
    avatarUrl: string;
  };
  request: string;
};

export type OrderPaidNotificationData = {
  gifter: {
    id?: string;
    handle?: string;
    name?: string;
    pseudonym?: string;
    avatarUrl: string;
  };
  request?: {
    id: string;
    tipAmount: Money;
    tipNote: string;
  };
};

export type MonthlyLeaderboardNotificationData = {
  rank: number;
  code: string;
  year: number;
  month: number;
};

export type NewNoticeNotificationData = {
  title: string;
  description: string;
  url: string;
};

export type SubscriptionCancelledNotificationData = {
  wisher: {
    id: string;
    handle: string;
    name: string;
    avatarUrl: string;
    currency: Currency;
  };
  gifter: {
    id?: string;
    handle?: string;
    name?: string;
    currency?: Currency;
    pseudonym?: string;
    avatarUrl: string;
  };
  subscription: {
    id: string;
    wishItem: {
      id: string;
      name: string;
    };
    interval: number;
    price: number;
  };
};

export type SubscriptionCreatedNotificationData = {
  wisher: {
    id: string;
    handle: string;
    name: string;
    avatarUrl: string;
    currency: Currency;
  };
  gifter: {
    id?: string;
    handle?: string;
    name?: string;
    currency?: Currency;
    pseudonym?: string;
    avatarUrl: string;
  };
  subscription: {
    id: string;
    wishItem: {
      id: string;
      name: string;
    };
    interval: number;
    price: number;
  };
};

export type OrderRepliedNotificationData = {
  wisher: {
    id: string;
    handle: string;
    name: string;
    avatarUrl: string;
  };
};

export type SubscriptionRemindedNotificationData = {
  wisher: {
    id: string;
    handle: string;
    name: string;
    avatarUrl: string;
  };
  subscription: {
    id: string;
    wishItem: {
      id: string;
      name: string;
    };
  };
};

export type StatementDescriptorUpdatedData = {
  previousContent: string;
  nextContent: string;
};

export type StatementDescriptorDeletedData = {
  content: string;
};

export type YearlyRecapWrappedNotificationData = {
  wisher: {
    id: string;
    handle: string;
    name: string;
    avatarUrl: string;
  };
  recapCardUrls: string[];
  archetype: string;
};

export type InstantPayoutFailedNotificationData = {
  stripePayoutLink: string;
};

export type PaymentTransitionedNotificationData = {
  detailUrl: string;
};

export type PaymentTransitionUpdatedNotificationData = {
  detailUrl: string;
};

export type WisherDeactivatedNotificationData = {
  detailUrl: string;
};

export type PayoutFailedNotification = {
  payoutAmount: Money;
  reason: string;
  activity?: string;
};

export type UserIdentityResubmissionRequested = {
  resubmissionUrl: string;
};

export type GifterRefundSucceededData = {
  total: Money;
  estTotal?: Money;
};

export type WisherRefundSucceededData = {
  total: Money;
};

export type PayoutAvailableToWithdraw = {
  total: Money;
};

export type SetupPayoutMethodRemind = {
  recommendedCurrency: Currency;
};

export type NotificationV2<T extends NotificationType> = {
  id: string;
  type: T;
  seen: boolean;
  createdAt: Date;
  data: T extends NotificationType.USER_IDENTITY_RESUBMISSION_REQUESTED
    ? UserIdentityResubmissionRequested
    : T extends NotificationType.PAYOUT_FAILED
    ? PayoutFailedNotification
    : T extends NotificationType.ORDER_PAID
    ? OrderPaidNotificationData
    : T extends NotificationType.REQUEST_SENT
    ? RequestSentNotificationData
    : T extends NotificationType.REQUEST_DECLINED
    ? RequestDeclinedNotificationData
    : T extends NotificationType.CART_REQUEST_CANCELED
    ? CartRequestCanceledNotificationData
    : T extends NotificationType.NEW_NOTICE
    ? NewNoticeNotificationData
    : T extends NotificationType.SUBSCRIPTION_CREATED
    ? SubscriptionCreatedNotificationData
    : T extends NotificationType.MONTHLY_LEADERBOARD
    ? MonthlyLeaderboardNotificationData
    : T extends NotificationType.SUBSCRIPTION_REMINDED
    ? SubscriptionRemindedNotificationData
    : T extends NotificationType.MONTHLY_LEADERBOARD
    ? MonthlyLeaderboardNotificationData
    : T extends NotificationType.SUBSCRIPTION_CANCELLED
    ? SubscriptionCancelledNotificationData
    : T extends NotificationType.ORDER_REPLIED
    ? OrderRepliedNotificationData
    : T extends NotificationType.STATEMENT_DESCRIPTOR_UPDATED
    ? StatementDescriptorUpdatedData
    : T extends NotificationType.STATEMENT_DESCRIPTOR_DELETED
    ? StatementDescriptorDeletedData
    : T extends NotificationType.YEARLY_RECAP_WRAPPED
    ? YearlyRecapWrappedNotificationData
    : T extends NotificationType.INSTANT_PAYOUT_FAILED
    ? InstantPayoutFailedNotificationData
    : T extends NotificationType.PAYMENT_TRANSITIONED
    ? PaymentTransitionedNotificationData
    : T extends NotificationType.PAYMENT_TRANSITION_UPDATED
    ? PaymentTransitionUpdatedNotificationData
    : T extends NotificationType.WISHER_DEACTIVATED
    ? WisherDeactivatedNotificationData
    : T extends NotificationType.GIFTER_REFUND_SUCCEEDED
    ? GifterRefundSucceededData
    : T extends NotificationType.WISHER_REFUND_SUCCEEDED
    ? WisherRefundSucceededData
    : T extends NotificationType.PAYOUT_AVAILABLE_TO_WITHDRAW
    ? PayoutAvailableToWithdraw
    : T extends NotificationType.SETUP_PAYOUT_METHOD_REMIND
    ? SetupPayoutMethodRemind
    : never;
};
