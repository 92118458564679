import AccountCircle from "@mui/icons-material/AccountCircle";
import Twitter from "@mui/icons-material/Twitter";
import { Box, Stack, Typography } from "@mui/material";

import { currencyInfo } from "~/modules/currency";
import type { Currency } from "~/types";

export function TweetExample({
  name,
  userName,
  blockPseudonym = false,
  includeImages = false,
  includeLink = false,
  totalThreshold,
  currency,
}: {
  name?: string;
  userName?: string;
  blockPseudonym?: boolean;
  includeImages?: boolean;
  includeLink?: boolean;
  totalThreshold?: string;
  currency: Currency;
}) {
  const pseudonym = blockPseudonym ? "Someone" : `"Gifter name"`;

  return (
    <Stack
      spacing={2}
      sx={{
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: "blue.4",
        borderRadius: 4,
        p: 2,
      }}
    >
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Stack direction="row" sx={{ alignItems: "center" }} spacing={1}>
          <AccountCircle sx={{ color: "neutral.3", fontSize: 48 }} />
          <Stack>
            <Typography component="span" variant="h4">
              {name}
            </Typography>
            <Typography component="span" variant="b4">
              @{userName}
            </Typography>
          </Stack>
        </Stack>
        <Twitter sx={{ color: "teal.4" }} />
      </Stack>
      <Typography component="span" variant="b4">
        {`${pseudonym} just bought a gift off my wishlist worth ${
          currencyInfo(currency).symbol
        }${totalThreshold} 🌟✨🌟`}
      </Typography>
      <Typography component="span" variant="b4">
        Check out my wishlist here:{" "}
        {includeLink ? (
          <Typography component="span" variant="b4" sx={{ color: "teal.4" }}>
            wishtender.com/yourwishlisturl
          </Typography>
        ) : (
          "wishtender dot com /yourwishlisturl"
        )}{" "}
        via WishTender
      </Typography>
      {includeLink && (
        <>
          {includeImages ? undefined : (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "neutral.3",
                borderRadius: 3,
                height: 96,
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src="/images/twitter_card.png"
                sx={{ height: "100%", overflow: "hidden", borderRadius: 3 }}
              />
              <Stack spacing={0.5}>
                <Typography component="span" variant="b3" sx={{ color: "neutral.5" }}>
                  wishtender.com
                </Typography>
                <Typography component="span" variant="b4">
                  WishTender Wishlists
                </Typography>
                <Typography component="span" variant="b3" sx={{ color: "neutral.4" }}>
                  Receive or give gifts safely and easily.
                </Typography>
              </Stack>
            </Stack>
          )}
        </>
      )}
      {includeImages && (
        <Stack
          sx={{
            width: "100%",
            placeContent: "center",
            bgcolor: "neutral.2",
            borderRadius: 2,
          }}
        >
          <Box
            component="img"
            alt="placeholder"
            src="https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
            sx={{ width: "100%" }}
          />
        </Stack>
      )}
    </Stack>
  );
}
