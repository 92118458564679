import { SvgIcon, type SvgIconProps } from "@mui/material";

export function MailIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        opacity="0.25"
        d="M1.66675 7.33325C1.66675 5.93312 1.66675 5.23305 1.93923 4.69828C2.17892 4.22787 2.56137 3.84542 3.03177 3.60574C3.56655 3.33325 4.26662 3.33325 5.66675 3.33325H14.3334C15.7335 3.33325 16.4336 3.33325 16.9684 3.60574C17.4388 3.84542 17.8212 4.22787 18.0609 4.69828C18.3334 5.23305 18.3334 5.93312 18.3334 7.33325V12.6666C18.3334 14.0667 18.3334 14.7668 18.0609 15.3016C17.8212 15.772 17.4388 16.1544 16.9684 16.3941C16.4336 16.6666 15.7335 16.6666 14.3334 16.6666H5.66675C4.26662 16.6666 3.56655 16.6666 3.03177 16.3941C2.56137 16.1544 2.17892 15.772 1.93923 15.3016C1.66675 14.7668 1.66675 14.0667 1.66675 12.6666V7.33325Z"
        fill="#0CB4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.14623 7.02851C5.40658 6.64899 5.9253 6.55238 6.30482 6.81273L9.52867 9.0243C9.81273 9.21916 10.1874 9.21916 10.4715 9.0243L13.6953 6.81273C14.0749 6.55238 14.5936 6.64899 14.8539 7.02851C15.1143 7.40803 15.0177 7.92675 14.6382 8.1871L11.4143 10.3987C10.5621 10.9833 9.43805 10.9833 8.58586 10.3987L5.362 8.1871C4.98248 7.92675 4.88588 7.40803 5.14623 7.02851Z"
        fill="#0CB4E9"
      />
    </SvgIcon>
  );
}
