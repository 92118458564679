import ArrowRight from "@mui/icons-material/ArrowRight";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useToggle } from "usehooks-ts";

import { Button } from "~/components/button";
import { Dialog } from "~/components/dialog";
import { Toast } from "~/components/toast";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

export function RequestSettingsItem() {
  const { gifter } = useAuth();
  const [openDialog, toggleDialog] = useToggle(false);
  const [openSuccessToast, toggleSuccessToast] = useToggle(false);
  const [allowReceiveRequests, setAllowReceiveRequestsValue] = useState<boolean>(
    gifter?.allowReceiveRequests ?? true, // default is true
  );

  const [updateUserSettings, updateUserSettingsState] = useWtFetcher({
    onError: (response) => {
      alert(response.error);
      setAllowReceiveRequestsValue(!allowReceiveRequests);
    },
    onSuccess: toggleSuccessToast,
  });

  function handleChange(allowed: boolean, gifterId: string) {
    setAllowReceiveRequestsValue(allowed);
    updateUserSettings.submit(
      {
        allowReceiveRequests: allowed.toString(),
        gifterId,
      },
      { method: "patch", action: Routes.actions.gifter.settings.update() },
    );
  }

  if (!gifter) {
    return <></>;
  }

  return (
    <>
      <MenuItem sx={{ py: 1 }} onClick={toggleDialog}>
        <ListItemText>Requests</ListItemText>
        <ListItemIcon>
          <ArrowRight />
        </ListItemIcon>
      </MenuItem>
      <Toast
        type="success"
        message="Request settings successfully updated"
        onClose={toggleSuccessToast}
        open={openSuccessToast}
      />
      <Dialog.Root
        maxWidth="xs"
        fullWidth
        variant="normal"
        open={openDialog}
        onClose={toggleDialog}
      >
        <Dialog.Title sx={{ textAlign: "center" }}>Update Requests Settings</Dialog.Title>
        <Dialog.Content>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Switch
              checked={allowReceiveRequests}
              onChange={(event) => setAllowReceiveRequestsValue(event.target.checked)}
              id="switch-allow-receive-requests"
              sx={{
                ml: "-12px",
              }}
            />
            <Typography
              component="label"
              htmlFor="switch-allow-receive-requests"
              variant="h5"
              color="neutral.7"
            >
              Receive Requests
            </Typography>
          </Stack>
          <Box sx={{ mt: 2 }} />
          <Typography variant="b5" color="neutral.6">
            {allowReceiveRequests
              ? "Wishers can send you wish requests, except for those you’ve blocked."
              : "Wishers cannot send you wish requests."}
          </Typography>
          <Box sx={{ mt: 4 }} />
          <Button
            variant="contained"
            fullWidth
            loading={updateUserSettingsState.isFetching}
            onClick={() => handleChange(allowReceiveRequests, gifter.id)}
            disabled={updateUserSettingsState.isFetching}
          >
            Update
          </Button>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
