import type { ComponentType } from "react";

import type { NotificationV2 } from "../../types";
import { NotificationType } from "../../types";
import * as yearlyRecapWrapped from "./2023-wrapped";
import * as cartRequestCanceledNotification from "./cart-request-canceled";
import * as firstGiftNotification from "./first-gift";
import * as firstPayoutPaidNotification from "./first-payout-paid";
import * as gifterRefundSucceededNotification from "./gifter-refund-succeeded";
import * as incorrectActivatedStripeAccountNotification from "./incorrect-activated-stripe-account";
import * as incorrectDeactivatedStripeAccountNotification from "./incorrect-deactivated-stripe-account";
import * as instantPayoutEnabledNotification from "./instant-payout-enabled";
import * as instantPayoutFailedNotification from "./instant-payout-failed";
import * as invitedToBetaNotification from "./invited-to-beta";
import * as monthlyLeaderboardNotification from "./monthly-leaderboard";
import * as newNoticeNotification from "./new-notice";
import * as newPaymentUpdateNotification from "./new-payment-update";
import * as orderPaidNotification from "./order-paid";
import * as orderRepliedNotification from "./order-replied";
import * as paymentTransitionUpdatedNotification from "./payment-transition-updated";
import * as paymentTransitionedNotification from "./payment-transitioned";
import * as payoutAvailableForReducedMinimum from "./payout-available-for-reduced-minimum";
import * as payoutAvailableToWithdraw from "./payout-available-to-withdraw";
import * as payoutFailed from "./payout-failed";
import * as payoutFrozenNotification from "./payout-frozen";
import * as payoutFrozenDueToCardTestingNotification from "./payout-frozen-due-to-card-testing";
import * as requestDeclinedNotification from "./request-declined";
import * as requestSentNotification from "./request-sent";
import * as setupPayoutMethodRemindNotification from "./setup-payout-method-remind";
import * as statementDescriptorDeleted from "./statement-descriptor-deleted";
import * as statementDescriptorEnabled from "./statement-descriptor-enabled";
import * as statementDescriptorUpdated from "./statement-descriptor-updated";
import * as stripeAccountInfoUnactivatedNotification from "./stripe-account-info-unactivated";
import * as subscriptionCancelledNotification from "./subscription-cancelled";
import * as subscriptionCreatedNotification from "./subscription-created";
import * as subscriptionRemindedNotification from "./subscription-reminded";
import * as userIdentityDeclined from "./user-identity-declined";
import * as userIdentityResubmissionRequested from "./user-identity-resubmission-requested";
import * as userIdentityVerifiedNotification from "./user-identity-verified";
import * as wavingVerificationFeeNotification from "./waving-verification-fee";
import * as wisherDeactivatedNotification from "./wisher-deactivated";
import * as wisherRefundSucceededNotification from "./wisher-refund-succeeded";

type NotificationUI<T extends NotificationType> = {
  NotificationContent: ComponentType<{ notification: NotificationV2<T> }>;
};
type Items = Record<NotificationType, NotificationUI<NotificationType>>;

const items: Items = {
  [NotificationType.ORDER_PAID]: orderPaidNotification,
  [NotificationType.REQUEST_SENT]: requestSentNotification,
  [NotificationType.REQUEST_DECLINED]: requestDeclinedNotification,
  [NotificationType.CART_REQUEST_CANCELED]: cartRequestCanceledNotification,
  [NotificationType.MONTHLY_LEADERBOARD]: monthlyLeaderboardNotification,
  [NotificationType.NEW_NOTICE]: newNoticeNotification,
  [NotificationType.SUBSCRIPTION_REMINDED]: subscriptionRemindedNotification,
  [NotificationType.SUBSCRIPTION_CANCELLED]: subscriptionCancelledNotification,
  [NotificationType.SUBSCRIPTION_CREATED]: subscriptionCreatedNotification,
  [NotificationType.ORDER_REPLIED]: orderRepliedNotification,
  [NotificationType.INSTANT_PAYOUT_ENABLED]: instantPayoutEnabledNotification,
  [NotificationType.STATEMENT_DESCRIPTOR_ENABLED]: statementDescriptorEnabled,
  [NotificationType.STATEMENT_DESCRIPTOR_UPDATED]: statementDescriptorUpdated,
  [NotificationType.STATEMENT_DESCRIPTOR_DELETED]: statementDescriptorDeleted,
  [NotificationType.STRIPE_ACCOUNT_INFO_UNACTIVATED]: stripeAccountInfoUnactivatedNotification,
  [NotificationType.YEARLY_RECAP_WRAPPED]: yearlyRecapWrapped,
  [NotificationType.INCORRECT_ACTIVATED_STRIPE_ACCOUNT_NOTIFICATION]:
    incorrectActivatedStripeAccountNotification,
  [NotificationType.INCORRECT_DEACTIVATED_STRIPE_ACCOUNT_NOTIFICATION]:
    incorrectDeactivatedStripeAccountNotification,
  [NotificationType.INSTANT_PAYOUT_FAILED]: instantPayoutFailedNotification,
  [NotificationType.PAYMENT_TRANSITIONED]: paymentTransitionedNotification,
  [NotificationType.PAYMENT_TRANSITION_UPDATED]: paymentTransitionUpdatedNotification,
  [NotificationType.WISHER_DEACTIVATED]: wisherDeactivatedNotification,
  [NotificationType.USER_IDENTITY_VERIFIED]: userIdentityVerifiedNotification,
  [NotificationType.USER_IDENTITY_DECLINED]: userIdentityDeclined,
  [NotificationType.USER_IDENTITY_RESUBMISSION_REQUESTED]: userIdentityResubmissionRequested,
  [NotificationType.PAYOUT_FAILED]: payoutFailed,
  [NotificationType.FIRST_PAYOUT_PAID]: firstPayoutPaidNotification,
  [NotificationType.GIFTER_REFUND_SUCCEEDED]: gifterRefundSucceededNotification,
  [NotificationType.WISHER_REFUND_SUCCEEDED]: wisherRefundSucceededNotification,
  [NotificationType.PAYOUT_AVAILABLE_TO_WITHDRAW]: payoutAvailableToWithdraw,
  [NotificationType.FIRST_GIFT]: firstGiftNotification,
  [NotificationType.INVITED_TO_BETA]: invitedToBetaNotification,
  [NotificationType.CAPABILITY_PAYOUT_FROZEN]: payoutFrozenNotification,
  [NotificationType.NEW_PAYMENT_UPDATE]: newPaymentUpdateNotification,
  [NotificationType.PAYOUT_FROZEN_DUE_TO_CARD_TESTING]: payoutFrozenDueToCardTestingNotification,
  [NotificationType.PAYOUT_AVAILABLE_FOR_REDUCED_MINIMUM]: payoutAvailableForReducedMinimum,
  [NotificationType.SETUP_PAYOUT_METHOD_REMIND]: setupPayoutMethodRemindNotification,
  [NotificationType.WAVING_VERIFICATION_FEE]: wavingVerificationFeeNotification,
} as Items;

export function getNotificationUI(type: NotificationType) {
  return items[type];
}
