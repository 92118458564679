import { ListItemText, MenuItem, Switch } from "@mui/material";
import { useState } from "react";

import { Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";

export function UpdateDetailedGiftNotificationMenuItem({
  detailedGiftNotification,
}: {
  detailedGiftNotification?: boolean;
}) {
  const [includeGiftInformation, setIncludeGiftInformation] = useState(detailedGiftNotification);

  const [updateUserSettings] = useWtFetcher({
    onError: (response) => {
      alert(response.error);
      setIncludeGiftInformation(!includeGiftInformation);
    },
  });

  function handleChange(included: boolean) {
    setIncludeGiftInformation(included);
    updateUserSettings.submit(
      {
        detailedGiftNotification: included.toString(),
      },
      { method: "patch", action: Routes.actions.settings.updateUserSettings() },
    );
  }

  return (
    <MenuItem sx={{ py: 1 }} onClick={() => handleChange(!includeGiftInformation)}>
      <ListItemText sx={{ whiteSpace: "normal" }}>
        Include gift information in notification emails
      </ListItemText>
      <Switch
        checked={includeGiftInformation}
        onChange={(event) => handleChange(event.target.checked)}
      />
    </MenuItem>
  );
}
