import { Box, Stack, Typography } from "@mui/material";

import { InfoCircleFilledIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";

import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.WAVING_VERIFICATION_FEE>;
}) {
  return (
    <NotificationContentContainer to={Routes.wisher.activeWishlist()} notification={notification}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "blue.0",
          borderRadius: 6,
          height: 40,
          minWidth: 40,
          color: "blue.4",
        }}
      >
        <InfoCircleFilledIcon sx={{ fontSize: 28 }} />
      </Box>
      <Stack>
        <Typography color="neutral.7" variant="h5">
          We’re waiving the age verification fee
        </Typography>
        <Typography color="neutral.7" variant="b5">
          To set up and retrieve the remaining balance in your account, reach out to us using the
          support chat to manually verify your identity with us.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
