import { Box, Stack, Typography } from "@mui/material";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { pluralize } from "~/utils/helpers";

import type { Banner, BannerType } from "../types";

export function BannerContent({
  banner,
}: {
  banner: Banner<BannerType.UNDER_CHECKOUT_MAINTENANCE>;
}) {
  const remainingSecond = dayjs(banner.data.endAt).diff(new Date(), "seconds");
  const [seconds, setSeconds] = useState(remainingSecond);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((pre) => pre - 1);
      }
    }, 1000); // every second

    return () => clearInterval(interval);
  });

  if (seconds < 1) {
    return <></>;
  }

  function transformContent() {
    if (banner.data.showTimestamp) {
      return `WishTender’s Checkout is currently down for maintenance. Check back after ${getRemainingTimeLabel(
        { seconds },
      )}.`;
    }

    return "WishTender’s Checkout is currently down for maintenance.";
  }

  return (
    <Box sx={{ px: 2, py: 1.5, bgcolor: "blue.4" }}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 2 }}>
        <Stack sx={{ color: "yellow.4", justifyContent: "center" }}>
          <IconAlertTriangleFilled />
        </Stack>
        <Typography variant="h5" color="neutral.0">
          {transformContent()}
        </Typography>
      </Stack>
    </Box>
  );
}

function getRemainingTimeLabel({ seconds }: { seconds: number }) {
  const minute = Math.ceil(seconds / 60);
  if (minute < 60) {
    return `${minute} ${pluralize(minute, "minute", "minutes")}`;
  }

  const hour = Math.floor(minute / 60);
  const remainMinute = minute % 60;
  return `${hour} ${pluralize(hour, "hour", "hours")} ${remainMinute} ${pluralize(
    remainMinute,
    "minute",
    "minutes",
  )}`;
}
