import { Badge, Box, IconButton, Popover, Tooltip } from "@mui/material";
import { Link as RLink, useLocation } from "@remix-run/react";
import { useRef } from "react";

import { BellIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";

import { useNotifications } from "../context/notification-context";
import { NotificationHub } from "./notification-hub";

export function NotificationButtonLargeScreen({
  screenSize,
}: {
  screenSize: "desktop" | "tablet";
}) {
  const { toggleNotificationHub, totalNewNotifications, isNotificationHubOpen } =
    useNotifications();
  const iconButtonReference = useRef(null);

  return (
    <>
      <Tooltip title="Notifications" aria-label="notifications">
        <IconButton
          ref={iconButtonReference}
          aria-describedby={"notifications-popover"}
          onClick={() => toggleNotificationHub(true)}
          sx={{
            bgcolor: isNotificationHubOpen ? "teal.0" : "transparent",
            px: 1,
            py: 1,
            borderRadius: 2,
            "&:hover": { bgcolor: "gray.2", textDecoration: "none" },
            display: {
              xs: "none",
              sm: screenSize === "tablet" ? "inline-flex" : "none",
              lg: screenSize === "desktop" ? "inline-flex" : "none",
            },
          }}
        >
          <Badge
            badgeContent={totalNewNotifications}
            sx={{
              "& .MuiBadge-badge": {
                color: "neutral.0",
                bgcolor: "teal.4",
              },
              color: isNotificationHubOpen ? "teal.4" : "gray.5",
            }}
          >
            <BellIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        id="notifications-popover"
        open={isNotificationHubOpen}
        anchorEl={iconButtonReference.current}
        onClose={() => toggleNotificationHub(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            borderRadius: 2,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "neutral.2",
          },
        }}
        sx={{
          mt: 1,
          maxHeight: "90vh",
          display: {
            xs: "none",
            sm: screenSize === "tablet" ? "inline-flex" : "none",
            lg: screenSize === "desktop" ? "inline-flex" : "none",
          },
        }}
      >
        <Box
          sx={{
            width: 454,
            boxShadow:
              "0px 4px 24px 0px rgba(23, 35, 64, 0.15), 0px 0px 1px 0px rgba(25, 42, 83, 0.05)",
          }}
        >
          <NotificationHub />
        </Box>
      </Popover>
    </>
  );
}

export function NotificationButtonMobile() {
  const location = useLocation();
  const { totalNewNotifications } = useNotifications();
  const isOnNotificationsPage = location.pathname === Routes.notifications.main({});

  return (
    <IconButton
      LinkComponent={RLink}
      {...{
        to: Routes.notifications.main({}),
      }}
      sx={{
        bgcolor: isOnNotificationsPage ? "teal.0 !important" : "transparent",
        mx: 1,
      }}
    >
      <Badge
        badgeContent={totalNewNotifications}
        sx={{
          "& .MuiBadge-badge": {
            color: "neutral.0",
            bgcolor: "teal.4",
          },
          color: isOnNotificationsPage ? "teal.4" : "gray.5",
        }}
      >
        <BellIcon />
      </Badge>
    </IconButton>
  );
}

export function NotificationButtonDesktop() {
  return <NotificationButtonLargeScreen screenSize="desktop" />;
}

export function NotificationButtonTablet() {
  return <NotificationButtonLargeScreen screenSize="tablet" />;
}
