import { Box, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useRouteLoaderData } from "@remix-run/react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { Button } from "~/components/button";
import { Dialog } from "~/components/dialog";
import { DefaultStatementDescriptorIcon } from "~/components/icons";
import { RouteIds, Routes } from "~/constants/routes";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import type { loader } from "~/routes/gifter.settings";

import { DEFAULT_STATEMENT_DESCRIPTOR } from "../../constants";
import type { ChangeStatementDescriptorSchema } from "../../schemas";
import { ChangeStatementDescriptorSuccessNote } from "./success-note";

type ChangeStatementDescriptorDialogProps = {
  open: boolean;
  toggleOpen: () => void;
};

export function ChangeStatementDescriptorDialog({
  open,
  toggleOpen,
}: ChangeStatementDescriptorDialogProps) {
  const data = useRouteLoaderData<typeof loader>(RouteIds.gifter.settings());
  const form = useForm<z.infer<typeof ChangeStatementDescriptorSchema>>({
    defaultValues: {
      statementDescriptorOption:
        data?.statementDescriptorPreferences?.statementDescriptorOption ??
        DEFAULT_STATEMENT_DESCRIPTOR,
    },
  });

  const [changeStatementDescriptor, changeStatementDescriptorState] = useWtFetcher({
    onError: (response) => {
      alert(response.error);
    },
  });

  const handleSubmit = form.handleSubmit((values) => {
    changeStatementDescriptor.submit(
      {
        body: JSON.stringify({
          statementDescriptorOption: values.statementDescriptorOption,
        }),
      },
      {
        action: Routes.actions.gifter.statementDescriptor.update(),
        method: "PUT",
      },
    );
    form.reset({
      statementDescriptorOption: values.statementDescriptorOption,
    });
  });

  const selectedStatementDescriptorId = form.watch("statementDescriptorOption");
  const selectedStatementDescriptorContent = data?.statementDescriptors?.find(
    (item) => item.id === selectedStatementDescriptorId,
  )?.content;

  return (
    <Dialog.Root
      fullWidth
      variant="alert"
      open={open}
      onClose={toggleOpen}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: 520,
        },
      }}
    >
      <Dialog.Title sx={{ pb: 2 }}></Dialog.Title>
      <Dialog.Content>
        <Stack sx={{ alignItems: "center", gap: 3, textAlign: "center" }} component="form">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "blue.5",
              width: 48,
              height: 48,
              borderRadius: 24,
              backgroundColor: "blue.1",
            }}
          >
            <DefaultStatementDescriptorIcon sx={{ fontSize: 32 }} />
          </Box>
          <Typography variant="h8" color="neutral.7">
            Change Statement Descriptor
          </Typography>
          <Typography color="neutral.5" variant="b5">
            For your discretion, we now support the ability to change the default WishTender bank
            statement descriptor to alternatives.
          </Typography>
          <Controller
            control={form.control}
            name="statementDescriptorOption"
            render={({ field }) => (
              <RadioGroup {...field} sx={{ width: "100%" }}>
                <FormControlLabel
                  value={DEFAULT_STATEMENT_DESCRIPTOR}
                  control={<Radio />}
                  label="Keep default WishTender Descriptor"
                />
                {data?.statementDescriptors?.map((statementDescriptor) => (
                  <FormControlLabel
                    key={statementDescriptor.id}
                    value={statementDescriptor.id}
                    control={<Radio />}
                    label={statementDescriptor.content}
                  />
                ))}
              </RadioGroup>
            )}
          />
          {changeStatementDescriptorState.isSuccess && !form.formState.isDirty && (
            <ChangeStatementDescriptorSuccessNote
              statementDescriptorContent={selectedStatementDescriptorContent}
            />
          )}
        </Stack>
      </Dialog.Content>
      <Dialog.Actions sx={{ px: 4 }}>
        <Button
          onClick={handleSubmit}
          fullWidth
          loading={changeStatementDescriptorState.isFetching}
          sx={{ ml: 0 }}
        >
          <Typography component="span" variant="h5" color="neutral.0">
            Save
          </Typography>
        </Button>
      </Dialog.Actions>
    </Dialog.Root>
  );
}
