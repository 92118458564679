import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { InfoCircleFilledIcon } from "~/components/icons";
import { NotificationContentContainer } from "~/modules/notifications/components/notification-content-container";
import type { NotificationType, NotificationV2 } from "~/modules/notifications/types";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.PAYOUT_FROZEN_DUE_TO_CARD_TESTING>;
}) {
  return (
    <NotificationContentContainer
      notification={notification}
      sx={{ bgcolor: notification.seen ? "neutral.0" : "yellow.1" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "yellow.0",
          borderRadius: 10,
          height: 40,
          minWidth: 40,
        }}
      >
        <InfoCircleFilledIcon sx={{ fontSize: 22, color: "yellow.4" }} />
      </Box>
      <Stack sx={{ color: "neutral.7" }}>
        <Typography variant="h5">
          Please note that we have reversed some funds that were sent to you.
        </Typography>
        <Typography variant="b5">
          Find the explanation for this in an email sent to your inbox and reach out to customer
          service.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
