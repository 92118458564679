import SearchIcon from "@mui/icons-material/Search";
import { Badge, BottomNavigation, BottomNavigationAction, Typography } from "@mui/material";
import { Link, useMatches } from "@remix-run/react";
import { useState } from "react";

import { RouteIds, Routes } from "~/constants/routes";
import { useCartItemCount } from "~/hooks/use-cart-item-count";
import { useNotifications } from "~/modules/notifications/context/notification-context";
import { SearchDialogMobile } from "~/modules/search/components/search-bar-mobile";
import type { Auth } from "~/types";

import { BellIcon, ShoppingIcon, ShoppingListIcon } from "./icons";

const GIFTER_NAVIGATION_STATES = {
  ORDERS: "Orders",
  SEARCH: "Search",
  NOTIFICATIONS: "Notifications",
  CART: "Cart",
};

function useGifterNavigationState(): string {
  const matches = useMatches();

  /* Orders */
  if (matches.some((match) => match.id === RouteIds.gifter.order.list()))
    return GIFTER_NAVIGATION_STATES.ORDERS;

  /* Notifications */
  if (matches.some((match) => match.id === RouteIds.notifications.list()))
    return GIFTER_NAVIGATION_STATES.NOTIFICATIONS;

  /* Cart */
  if (matches.some((match) => match.id === RouteIds.cart.layout()))
    return GIFTER_NAVIGATION_STATES.CART;

  return "";
}

export function GifterMobileNavigation({ user }: { user: Auth["user"] }) {
  const { totalNewNotifications } = useNotifications();
  const cartItemCount = useCartItemCount();
  const defaultNavigationValue = useGifterNavigationState();
  const [value, setValue] = useState(defaultNavigationValue);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);

  return (
    <>
      <BottomNavigation
        showLabels={true}
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          width: "100%",
          borderTop: 1,
          borderColor: "gray.2",
          "& .MuiBottomNavigationAction-root": {
            px: 0,
            color: "neutral.5",
            "&.Mui-selected": { color: "teal.4" },
          },
        }}
      >
        <BottomNavigationAction
          value={GIFTER_NAVIGATION_STATES.ORDERS}
          component={Link}
          to={Routes.gifter.orderList()}
          label={<Typography variant="b3">Orders</Typography>}
          icon={<ShoppingListIcon />}
        />
        {user?.featureFlags.includes("search") && (
          <BottomNavigationAction
            value={GIFTER_NAVIGATION_STATES.SEARCH}
            label={<Typography variant="b3">Search</Typography>}
            icon={<SearchIcon />}
            onClick={() => setOpenSearchDialog(true)}
          />
        )}
        <BottomNavigationAction
          value={GIFTER_NAVIGATION_STATES.NOTIFICATIONS}
          component={Link}
          to={Routes.notifications.main({})}
          label={<Typography variant="b3">Notifications</Typography>}
          icon={
            <Badge
              badgeContent={totalNewNotifications}
              sx={{
                "& .MuiBadge-badge": {
                  top: 4,
                  color: "neutral.0",
                  fontSize: 10,
                  bgcolor: "teal.4",
                },
              }}
            >
              <BellIcon />
            </Badge>
          }
        />
        <BottomNavigationAction
          value={GIFTER_NAVIGATION_STATES.CART}
          component={Link}
          to={Routes.cart.default()}
          label={<Typography variant="b3">Cart</Typography>}
          icon={
            <Badge
              badgeContent={cartItemCount}
              sx={{
                "& .MuiBadge-badge": {
                  top: 4,
                  color: "neutral.0",
                  fontSize: 10,
                  bgcolor: "red.4",
                },
              }}
            >
              <ShoppingIcon />
            </Badge>
          }
        />
      </BottomNavigation>
      <SearchDialogMobile open={openSearchDialog} setOpen={setOpenSearchDialog} />
    </>
  );
}
