import { Box, Container, ThemeProvider, Typography } from "@mui/material";

import { GeneralLayout } from "~/components/general-layout";
import { themeV2 } from "~/utils/theme";

import { NotificationHub } from "../components/notification-hub";

export function NotificationsPage() {
  return (
    <GeneralLayout>
      <ThemeProvider theme={themeV2}>
        <Box
          component="main"
          sx={{
            minHeight: "calc(100vh - 72px)",
            py: "7vw",
            bgcolor: {
              xs: "neutral.0",
              sm: "initial",
            },
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              p: 0,
            }}
          >
            <Typography
              component="span"
              variant="h8"
              sx={{
                mx: {
                  xs: 2.5,
                  sm: 0,
                },
              }}
            >
              Notifications
            </Typography>

            <Box mt={2} />

            <NotificationHub />
          </Container>
        </Box>
      </ThemeProvider>
    </GeneralLayout>
  );
}
