import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useSearchParams } from "@remix-run/react";
import { Controller, useForm } from "react-hook-form";
import type { z } from "zod";

import { TextField } from "~/components/form/text-field";
import { HandleProgressBar } from "~/components/handle-progress-bar";
import { SUPPORTED_PAYOUT_COUNTRIES } from "~/constants/countries";
import { Routes } from "~/constants/routes";
import { useLocale } from "~/hooks/use-locale";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import { useValidateHandleInput } from "~/modules/auth";
import type { Auth } from "~/types";
import { themeV2 } from "~/utils/theme";

import { WisherSetupSchema } from "../schemas";

const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

export function WisherOnboard({ auth }: { auth: Auth }) {
  const [searchParams] = useSearchParams();
  const { countryCode } = useLocale();
  const countryList = SUPPORTED_PAYOUT_COUNTRIES.map((countryCode) => ({
    value: countryCode,
    label: regionNames?.of(countryCode) || countryCode,
  })).sort((a, b) => (a.label < b.label ? -1 : 1));

  const form = useForm<z.infer<typeof WisherSetupSchema>>({
    resolver: zodResolver(WisherSetupSchema),
    defaultValues: {
      handle: "",
      aliasName: "",
      country: countryCode,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [submit, submitState] = useWtFetcher({ onError: (response) => alert(response.error) });

  const { getHandleStatus, validateState } = useValidateHandleInput(form, undefined, true);

  const handleStatus = getHandleStatus();

  async function handleSubmit(data: z.infer<typeof WisherSetupSchema>) {
    const redirectUrl = searchParams.get("redirect");
    const urlSearchParams = new URLSearchParams();
    if (redirectUrl) {
      urlSearchParams.set("redirect", redirectUrl);
    }

    submit.submit(
      { body: JSON.stringify(data) },
      {
        method: "POST",
        action: `${Routes.actions.wisher.profile.setup()}?${urlSearchParams.toString()}`,
      },
    );
  }

  function getIsFormSubmittable() {
    if (!form.formState.isValid) return false;
    if (validateState.isFetching) return false;
    if (validateState.isError) return false;
    if (submitState.isSubmitting) return false;

    return handleStatus === "available";
  }

  return (
    <ThemeProvider theme={themeV2}>
      <Box
        component={submit.Form}
        onSubmit={form.handleSubmit(handleSubmit)}
        display="flex"
        flexDirection="column"
        height="100%"
        alignItems="center"
        justifyContent="center"
        sx={{
          minHeight: "calc(100vh - 72px)",
          background: "url('/images/background_graphic_gradient_optimized_for_web.png')",
          backgroundSize: "cover",
        }}
      >
        <Container
          component={Paper}
          sx={{
            py: 4,
            px: 3,
            maxWidth: { xs: "100%", sm: 444 },
            borderRadius: { xs: 0, sm: 2 },
            height: { xs: "100%", sm: "auto" },
          }}
        >
          {auth && auth.user && (
            <Typography
              color={themeV2.palette.neutral[5]}
              sx={{
                position: "relative",
                top: "-2rem",
                display: { sm: "none" },
              }}
            >
              Logged in as: <span style={{ fontWeight: "bold" }}>{auth.user?.email}</span>
            </Typography>
          )}
          <Typography variant="h7" component="h2">
            Setting up your profile
          </Typography>
          <Box mt={2} />
          <Typography component="p">
            You can change your <b>wishlist url</b> and <b>display name</b> at any time.
          </Typography>

          <Box mt={2} />

          <Controller
            name="handle"
            control={form.control}
            render={({ field }) => (
              <TextField.Root fullWidth>
                <TextField.Label>Wishlist URL</TextField.Label>
                <TextField.Input
                  autoComplete="off"
                  spellCheck="false"
                  placeholder="yourname"
                  startAdornment={
                    <InputAdornment position="start">
                      <span>wishtender.com/</span>
                    </InputAdornment>
                  }
                  {...field}
                />

                <HandleProgressBar
                  handle={field.value}
                  handleStatus={handleStatus}
                  errors={form.formState.errors}
                />
              </TextField.Root>
            )}
          />

          <Box mt={2} />

          <Controller
            name="aliasName"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField.Root fullWidth>
                <TextField.Label>Display Name</TextField.Label>
                <TextField.Input placeholder="Your display name" {...field} />
                {fieldState.error && (
                  <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
                )}
              </TextField.Root>
            )}
          />

          <Box mt={2} />

          <Controller
            name="country"
            control={form.control}
            render={({ field, fieldState }) => {
              return (
                <FormControl error={!!fieldState.error} fullWidth>
                  <FormLabel id="wisher-country-label">Country of bank account</FormLabel>
                  <Select labelId="wisher-country-label" type="text" variant="outlined" {...field}>
                    {countryList.map((country) => {
                      return (
                        <MenuItem key={country.value} value={country.value}>
                          {country.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                </FormControl>
              );
            }}
          />

          <Box mt={4} />

          <Button fullWidth type="submit" variant="contained" disabled={!getIsFormSubmittable()}>
            {submitState.isFetching ? <span>Submitting...</span> : <span>Submit</span>}
          </Button>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
