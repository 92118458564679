import { zodResolver } from "@hookform/resolvers/zod";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { IconBarcode } from "@tabler/icons-react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { BounceInScale } from "~/components/animations/bounce-in-scale";
import { Button } from "~/components/button";
import { Dialog } from "~/components/dialog";
import { TextField } from "~/components/form/text-field";
import { Routes } from "~/constants/routes";
import { useSearchParamsNoReload } from "~/hooks/use-search-params-no-reload";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import { RefLink } from "~/modules/referrals/components/referral-code-card";
import { ReferralCodeSchema } from "~/modules/referrals/schemas";

export function CreateReferralCodeDialog() {
  const [params, setParams] = useSearchParamsNoReload();

  const open = params.get("create") === "code";

  function handleClose() {
    const newParams = new URLSearchParams(params);
    newParams.delete("create");
    setParams(newParams);
  }

  return (
    <Dialog.Root maxWidth="sm" variant="normal" open={open} fullWidth onClose={handleClose}>
      <Dialog.Title></Dialog.Title>
      <Dialog.Content>
        <FormCreateReferralCode onClose={handleClose} />
      </Dialog.Content>
    </Dialog.Root>
  );
}

const Schema = z.object({ code: ReferralCodeSchema });

function FormCreateReferralCode({ onClose }: { onClose: () => void }) {
  const [submit, submitState] = useWtFetcher({
    onError: (response) => alert(response.error),
  });

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema),
    defaultValues: {
      code: "",
    },
  });

  const code = form.watch("code");
  const wisherInviteLink = `${origin}${Routes.public.invite({ code })}`;

  function handleSubmit(values: z.infer<typeof Schema>) {
    submit.submit(values, {
      action: Routes.actions.wisher.referral.createCode(),
      method: "POST",
    });
  }

  return (
    <submit.Form
      onSubmit={form.handleSubmit(handleSubmit)}
      action={Routes.actions.wisher.referral.createCode()}
    >
      <Stack spacing={3}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {submitState.isSuccess ? (
            <BounceInScale>
              <Box
                component="span"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 48,
                  height: 48,
                  fontSize: 48,
                }}
              >
                🎉
              </Box>
            </BounceInScale>
          ) : (
            <Avatar sx={{ bgcolor: "blue.0", color: "neutral.7", width: 48, height: 48 }}>
              <IconBarcode size={32} />
            </Avatar>
          )}
        </Box>
        <Typography variant="h8" color="neutral.7" textAlign="center">
          {submitState.isSuccess ? (
            <span>You’ve got a new referral code!</span>
          ) : (
            <span>Create a new Referral Code</span>
          )}
        </Typography>
        <Controller
          control={form.control}
          name="code"
          render={({ field, fieldState }) => (
            <TextField.Root>
              {submitState.isSuccess && <TextField.Label>Your code</TextField.Label>}
              <TextField.Input
                disabled={submitState.isSuccess}
                placeholder="Input your custom code"
                {...field}
              />
              {fieldState.error && (
                <TextField.Helper error>{fieldState.error.message}</TextField.Helper>
              )}
              <Box sx={{ mt: { xs: 2, sm: 3 } }} />
            </TextField.Root>
          )}
        />
        {submitState.isSuccess ? (
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="sh5" color="neutral.7" sx={{ flexShrink: 0 }}>
              Ref Link
            </Typography>
            <Box sx={{ flexGrow: 1, overflow: "hidden", p: 1, m: -1 }}>
              <RefLink link={wisherInviteLink} />
            </Box>
          </Stack>
        ) : (
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            justifyContent="flex-end"
            width="100%"
            spacing={1}
          >
            <Button variant="subtle" onClick={onClose} sx={{ minWidth: 115 }}>
              Cancel
            </Button>
            <Button fullWidth type="submit" loading={submitState.isFetching}>
              Create
            </Button>
          </Stack>
        )}
      </Stack>
    </submit.Form>
  );
}
