import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export function OnlyFansIcon(props: SvgIconProps) {
  return (
    <SvgIcon x="0px" y="0px" viewBox="0 0 92.4 92.4" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.1,0C20.6,0,0,20.7,0,46.3s20.7,46.2,46.3,46.1c25.5,0,46.1-20.7,46.1-46.2C92.4,20.7,71.7,0,46.1,0
	C46.2,0,46.2,0,46.1,0z M46.1,87.6C23.2,87.5,4.7,68.9,4.8,46.1S23.4,4.8,46.3,4.8c22.8,0.1,41.3,18.6,41.3,41.4
	c0,22.9-18.5,41.4-41.4,41.4C46.2,87.6,46.1,87.6,46.1,87.6L46.1,87.6z"
      />
      <path
        fill="currentColor"
        d="M65,42.4v-3.8c0-4.5-1.8-8.8-5.1-12c-3.2-3.2-7.6-5-12.2-5h-3.1c-4.6,0-8.9,1.8-12.2,5c-3.2,3.1-5.1,7.5-5.1,12
	v3.8l-2.1,3.8v5.6c0,5,2,9.8,5.6,13.3c3.6,3.6,8.5,5.5,13.6,5.5h3.5c5.1,0,9.9-2,13.5-5.5c3.6-3.5,5.6-8.3,5.6-13.3v-5.6L65,42.4z
	 M48.2,59.5V64l0,0c0,0.7-0.3,1.3-0.9,1.6h-2c-0.6-0.4-0.9-1-0.9-1.7v-4.5c-2.7-1.1-4.1-4.1-3-6.9c0.8-2,2.8-3.4,4.9-3.4h0.3
	c2.9,0,5.3,2.3,5.3,5.3c0,2.2-1.3,4.2-3.4,5H48.2z M57.3,42.6H35.1v-3.9c0-2.5,1-4.9,2.8-6.7c1.8-1.8,4.2-2.8,6.7-2.8h3.1
	c2.5,0,4.9,1,6.7,2.8c1.8,1.7,2.8,4.1,2.8,6.6L57.3,42.6z"
      />
    </SvgIcon>
  );
}
