import { Box, Stack, Typography } from "@mui/material";

import { DeletedStatementDescriptorIcon } from "~/components/icons";
import { Routes } from "~/constants/routes";

import type { NotificationType, NotificationV2 } from "../../types";
import { NotificationContentContainer } from "../notification-content-container";

export function NotificationContent({
  notification,
}: {
  notification: NotificationV2<NotificationType.STATEMENT_DESCRIPTOR_DELETED>;
}) {
  return (
    <NotificationContentContainer
      to={Routes.gifter.settings.main()}
      notification={notification}
      sx={{
        "& .MuiLink-root": {
          ml: "0 !important",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "yellow.0",
          borderRadius: 6,
          height: 40,
          minWidth: 40,
          color: "yellow.4",
        }}
      >
        <DeletedStatementDescriptorIcon />
      </Box>
      <Stack sx={{ color: "neutral.7" }}>
        <Typography variant="h5" color="neutral.7">
          Changes to your chosen statement descriptor
        </Typography>
        <Typography color="neutral.7" variant="b5">
          The descriptor “{notification.data.content}” is no longer available so we’ve reset your
          descriptor back to the WishTender default.
        </Typography>
      </Stack>
    </NotificationContentContainer>
  );
}
