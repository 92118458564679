import confetti from "canvas-confetti";

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function confettiFireWorks() {
  var defaults = { startVelocity: 24, spread: 360, ticks: 60, zIndex: 1301 };

  return setInterval(async function () {
    var particleCount = 30;
    // since particles fall down, start a bit higher than random
    await Promise.all([
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        }),
      ),
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        }),
      ),
    ]);
  }, 400);
}
